import React, { useRef } from 'react';
import { FaAlignJustify } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import LOGO from "../../Image/Header/Be My Wordsmith.webp";
import "../CSS/Navbar.css";

const Navbar = () => {
    let showAndHideNavMenu = useRef("");
    const toggleNavMenu = () => {
        showAndHideNavMenu.current.classList.toggle("show-nav-un-ordered-list");
    }
    const hideNavMenu = () => {
        showAndHideNavMenu.current.classList.remove("show-nav-un-ordered-list");
    }

    return (
        <>
            <header className='sticky-top'>
            <nav className='main-nav-menu'>
                <div className="nav-toggle-button">
                    <span><i onClick={toggleNavMenu}><FaAlignJustify /></i></span>
                </div>
                <div className='nav-image-div'>
                    <NavLink to={"/"} className="href"><img src={LOGO} alt="Best SEO Friendly Content Writing Services Agency in India | Be My Wordsmith" className='img-fluid' onClick={hideNavMenu} /></NavLink>
                </div>
                <div className="nav-menu">
                    <ul className="nav-un-ordered-list" ref={showAndHideNavMenu}>
                        <li onClick={hideNavMenu}><NavLink to={"/about-us"} className="href">About us</NavLink></li>
                        <li onClick={hideNavMenu}><NavLink to={"/categories"} className="href">Categories</NavLink>
                            <ul className='sub-menu'>
                                <li onClick={hideNavMenu}><NavLink to={"/categories/best-website-content-writing-services-in-bangalore"} className="href">Website content</NavLink></li>
                                <li onClick={hideNavMenu}><NavLink to={"/categories/best-blog-&-article-writing-services-in-india"} className="href">Blogs &amp; articles</NavLink></li>
                                <li onClick={hideNavMenu}><NavLink to={"/categories/product-description-content-writing-services-in-india"} className="href">Product description</NavLink></li>
                            </ul>
                        </li>
                        <li onClick={hideNavMenu}><NavLink to={"/blogs"} className="href">Blogs</NavLink></li>
                        <li onClick={hideNavMenu}><NavLink to={"/our-creators"} className="href">Our Creators</NavLink></li>
                        {/* <li className='contactUs' onClick={hideNavMenu}><NavLink to={"/contact-us"} className="href">Contact us</NavLink></li> */}
                        <li className='contactUs'><NavLink to={"/contact-us"} className="href"><button onClick={hideNavMenu}>Contact us</button></NavLink></li>
                    </ul>
                </div>
            </nav>
            </header>
        </>
    )
}

export default Navbar;