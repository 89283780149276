import React from 'react'
import { Helmet } from 'react-helmet'
import ContactUsBtn from '../Blog_Page_Component/ContactUsBtn'
import MarketingLatestBlogs from '../Blog_Page_Component/MarketingLatestBlogs'
import MoreMarketingBlogs from '../Blog_Page_Component/MoreMarketingBlogs'
import "../Main_File/BlogsPage.css"

const MarketingBlogs = () => {
  return (
    <>
      <Helmet>
        <title>Articles | Be My Wordsmith</title>
        <meta name="description" content="Explore insightful seo-friendly articles on our blog covering industry trends, expert tips, and actionable strategies to boost your business success. Stay informed, stay ahead." />
        <link rel="canonical" href="https://www.bemywordsmith.com/articles" />
      </Helmet>
      {/* Latest Blogs */}
      <MarketingLatestBlogs />

      {/* More Blogs */}
      <MoreMarketingBlogs />

      {/* Contact Us Button Div */}
      <ContactUsBtn />
    </>
  )
}

export default MarketingBlogs;