import React from 'react';
import { ChooseIndustryData } from '../../../../Data/Blogs/ChooseIndustryData';
import "../Main_File/BlogsPage.css";

const ChooseIndustries = () => {
    return (
        <>
            <section className="secondSectionOfBlogPageStartHere">
                <div className="container secondSectionOfBlogPageFirstInnerDiv">
                    <div className="row justify-content-evenly secondSectionOfBlogPageSecondInnerDiv">
                        <div className="col-md-12 industryHeadingDiv">
                            <p>Choose</p>
                            <h3>Industries</h3>
                        </div>
                        {
                            ChooseIndustryData.map((data) => (
                                <div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 chooseIndustryCard" key={data.id}>
                                    {data.cardImage}
                                    <h5>{data.title}</h5>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChooseIndustries;