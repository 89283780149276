

export const ProductDescriptionData = [
    {
        id : 1,
        cardImage : require('../../Image/Categories_Page/Product_Description/Reflect Your Brand Voice.png'),
        title : "Reflect Your Brand Voice",
        desc : "Embracing a conversational tone and a touch of expertise, our brand voice resonates with authenticity and knowledge. Engage with our content - where insight meets approachability, reflecting our unique identity",
        altTag: "Product Description Writing Services in Bangalore"
    },
    {
        id : 2,
        cardImage : require('../../Image/Categories_Page/Product_Description/Provide Value to the Customer.png'),
        title : "Provide Value to the Customer",
        desc : "Empower your journey with us. Our commitment is to provide unparalleled value – whether through informative content, quality products, or exceptional service. Your satisfaction is our priority",
        altTag: "Product Description Writing Services in Bangalore"
    },
    {
        id : 3,
        cardImage : require('../../Image/Categories_Page/Product_Description/Cater to Buyer Personas.png'),
        title : "Cater to Buyer Personas",
        desc : "Indulge in products designed just for you. Our descriptions go beyond features, addressing your specific desires and needs. Elevate your experience with personalized choices curated for your individual preferences",
        altTag: "Product Description Writing Services in Bangalore"
    },
    {
        id : 4,
        cardImage : require('../../Image/Categories_Page/Product_Description/Technically Proficient Writing.png'),
        title : "Technically Proficient Writing",
        desc : "Precision meets innovation in our product descriptions. With technical expertise, we articulate intricate details, ensuring you make informed choices. Unravel the power of our products through technically proficient descriptions",
        altTag: "Product Description Writing Services in Bangalore"
    }
];