const TestimonialsData = [
    {
        id: 1,
        testimonial: "Excellent article and blog writing! For all my content needs, I have found a trustworthy partner. Be My Wordsmith's writers are skilled at producing interesting, thoroughly researched articles.",
        name: "Coach on Couch"
    },
    {
        id: 2,
        testimonial: "BeMyWordsmith has considerably enhanced our web presence. Their staff regularly produces excellent, interesting content that is suited to our requirements. We appreciate you promoting our brand so well.",
        name: "Nexus"
    },
    {
        id: 3,
        testimonial: "Reliability and excellence define our partnership with BMWS. Their consistent strength and effectiveness, coupled with reliable cost management, showcase a commitment to quality service provided.",
        name: "Solve My Class"
    },
    {
        id: 4,
        testimonial: "These people are like saviors outta nowhere! I wanted blogs for my company, and they delivered in no time, 100% original! They even have a digital marketer to provide ideas and have your keywords stuffed in, more like a one-stop pit crew!!! I'd give ★★★★/Five.",
        name: "Solve My Online Class"
    },
    {
        id: 5,
        testimonial: "The website has got various topics which people are looking for nowadays. And I am personally got benifitted, because what I was looking for I got here! I hope more people will get benifitted like me.",
        name: "Holistic Learning"
    },
    {
        id: 6,
        testimonial: "I wanted high-quality content that can help me to rank my website and can engage with my target audience. Be my Wordsmith has done extraordinary work in taking up the challenge and providing quality work.",
        name: "Write My Dissertation For Me"
    },
    {
        id: 7,
        testimonial: "I'm satisfied with the excellent services provided by Be My Wordsmith! Their team goes above and beyond by carefully and creatively crafting my ideas. The information produced through the smooth collaborative approach was far above my expectations. ",
        name: ""
    },
    {
        id: 8,
        testimonial: "I recently used Be My Wordsmith's services, and I'm genuinely impressed. They not only delivered on time but also exceeded my expectations. Reliable and highly recommended!",
        name: ""
    },
    {
        id: 9,
        testimonial: "The quality of the content is outstanding. Be My Wordsmith is our go-to source for affiliate content that ranks and converts.",
        name: ""
    },
    {
        id: 10,
        testimonial: "For the past few months, I've been utilizing BMWS website content writing services and have been consistently pleased. Their professionalism and punctuality are commendable. I wholeheartedly recommend BMWS to anyone seeking website Content.",
        name: ""
    },
    {
        id: 11,
        testimonial: "I'd rate them a 5/5, but I'm stopping at 3.5 out of 5 as deadline was delayed by a day. However they provide regular update and were responsive all the time",
        name: ""
    },
    {
        id: 12,
        testimonial: "The team has done an impressive job here but I feel it can be enhanced more with various topics. They need to focus a little more to comete with the other competitors out there, providing the same service. ",
        name: ""
    },
    {
        id: 13,
        testimonial: "The team is amazing for providing quality work under tight deadlines and by providing exceptional content on time. Looking forward for future collaborations! ",
        name: ""
    },
    {
        id: 14,
        testimonial: "My experience with Be My Wordsmith was good. They did what I expected, delivering content that suited my needs. I highly recommend Be My Wordsmith for its professional and top-quality writing services",
        name: ""
    },

];

export default TestimonialsData;