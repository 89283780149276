
import { BsChevronDown, BsChevronUp, BsDashCircle, BsPlusCircle } from "react-icons/bs";

const Icon = ({ color }) => {
    return <BsDashCircle fill={color} />;
};

const DownIcon = ({ color }) => {
    return <BsChevronUp fill={color} />;
}

const OnWebsiteContentPage = [
    {
        id: 1,
        question: "Why should I invest in Website Content Writing Services?",
        answer: <span>Investing in <b>Website Content Writing Services</b> ensures compelling, <b>SEO-friendly content</b> that engages your targeted audience, boosts online visibility, and establishes credibility to your website. Professional writers craft tailored content, saving you time and enhancing your brand's online presence for long-term objectives.</span>,
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 2,
        question: "Why is it imporatnt to tailor website content for my audience? ",
        answer: "In order to connect with your audience, you must customize the material on your website. It builds a good relationship, improves user experience, and raises engagement level. Understanding the requirements, tastes, and language of your audience helps you produce content that converts well and propels your organization forward.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 3,
        question: "How will I effectively be able to showcase my product and content through my content?",
        answer: "Effectively showcasing product and content by crafting engrossing narratives. Highlighting key features, addressing customer pain points, and use engaging visuals. Tailor the content to resonate with your audience, emphasizing benefits and online presence.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 4,
        question: "How will social media integration ampliy my website content?",
        answer: <span>Social media integration amplifies website content by expanding its reach. Sharing engaging content on platforms like Facebook, Twitter, and Instagram increases visibility, drives traffic, and fosters audience interaction. It's a powerful strategy for <b>building brand awareness</b> and fostering online community engagement.</span>,
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    // {
    //     id: 5,
    //     question: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, vero totam.",
    //     answer: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad suscipit iusto reprehenderit possimus voluptatum eum dolor consectetur omnis distinctio eos beatae esse odio, libero, dolorum nisi aliquam ducimus. Necessitatibus velit, quae reprehenderit autem in sed hic dicta mollitia inventore aliquid. Repudiandae deleniti inventore doloribus natus! Illo nam reiciendis neque eligendi.",
    //     plusDashCircle: <span><BsPlusCircle /></span>,
    //     minusDashCircle: <span><Icon color="#E1A213" /></span>,
    //     angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
    //     angleDownIcon: <span><BsChevronDown /></span> 
    // }
];

export default OnWebsiteContentPage;