import React from 'react';
import "../CSS/CompanyPage.css";

const AboutCompanyContent = () => {
    return (
        <>
            <section className="firstSectionOfCompanyPageStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 companyPageHeadingDiv">
                            <p>About</p>
                            <h6>Company</h6>
                        </div>
                        <div className="col-md-12 companyPageMainContentDiv">
                            <p>Crafting Dreams into Digital Reality, Where Words Meets an imagination!</p>
                            <p>Be My Wordsmith; creativity meets precision, and words come to life! Our <b>best content writing agency in bangalore</b> is an immersive experience tailored to elevate your brand's narrative through content curating experience. Allow us to weave the tapestry of your story with finesse style. </p>
                            <h3>What we do</h3>
                            <p>We curate experiences that connect with our audience. Create words that fascinate, inspire, and engage. Our <b>content writing team</b> of specialists has the ideal combination of creativity and technological expertise to make your life easier. From the first word to the last dot, we ensure every element aligns seamlessly to tell a unique story. Our commitment to quality goes beyond words that resonate, and leave a lasting impact on the audience. </p>
                            <h3>How we work</h3>
                            <p>We blend with creativity, collaboration, and competence; your ideas drive your work. We start with understand your brand, vision, and goals. We connect you with our meticulous content writer of subject matter expert. Our team of <b>expert content writers</b> are committed to your vision, ensuring every word is meticulously chosen to tell your story with authenticity and allure. Your satisfaction is our priority. Infuse your narrative with a personal touch, making every word resonate with your brand identity.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutCompanyContent;