import { NavLink } from 'react-router-dom';
import HRIcon from "../../Image/Blogs/Blog_Page/Industries/HR.png";
import BusinessIcon from "../../Image/Blogs/Blog_Page/Industries/business.png";
import DigitalMarketingIcon from "../../Image/Blogs/Blog_Page/Industries/digital_marketing.png";
import FestivalIcon from "../../Image/Blogs/Blog_Page/Industries/festival.png";
import FinanceIcon from "../../Image/Blogs/Blog_Page/Industries/finance.png";
import LifestyleIcon from "../../Image/Blogs/Blog_Page/Industries/lifestyle.png";
import BMWSIcon from "../../Image/Blogs/Blog_Page/Industries/literature.png";
import BooksReviewIcon from "../../Image/Landing_Page/Industries/education.webp";

export const IndustriesData = [
    {
        id : 1,
        cardImage : <NavLink to={"/blogs/content-writing"}><img src={BMWSIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/content-writing"} className="href">Content Writing</NavLink></span>
    },
    {
        id : 2,
        cardImage : <NavLink to={"/blogs/human-resource"}><img src={HRIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/human-resource"} className="href">Human Resource</NavLink></span>
    },
    {
        id : 3,
        cardImage : <NavLink to={"/blogs/digital-marketing"}><img src={DigitalMarketingIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/digital-marketing"} className="href">Digital Marketing</NavLink></span>
    },
    {
        id : 4,
        cardImage : <NavLink to={"/blogs/festival"}><img src={FestivalIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/festival"} className="href">Festival</NavLink></span>
    },
    {
        id : 5,
        cardImage : <NavLink to={"/blogs/lifestyle"}><img src={LifestyleIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/lifestyle"} className="href">Lifestyle</NavLink></span>
    },
    {
        id : 6,
        cardImage : <NavLink to={"/blogs/finance"}><img src={FinanceIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/finance"} className="href">Finance</NavLink></span>
    },
    {
        id : 7,
        cardImage : <NavLink to={"/blogs/books-review"}><img src={BooksReviewIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/books-review"} className="href">Books Review</NavLink></span>
    },
    {
        id : 8,
        cardImage : <NavLink to={"/blogs"}><img src={BusinessIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs"} className="href">Business</NavLink></span>
    },
];