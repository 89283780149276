import React from 'react'
import { Helmet } from 'react-helmet'
import ChooseIndustries from '../Blog_Page_Component/ChooseIndustries'
import ContactUsBtn from '../Blog_Page_Component/ContactUsBtn'
import LatestBlogs from '../Blog_Page_Component/LatestBlogs'
import MoreBlogs from '../Blog_Page_Component/MoreBlogs'
import "../Main_File/BlogsPage.css"

const BlogsPage = () => {
  return (
    <>
      <Helmet>
        <title>Blogs | Be My Wordsmith</title>
        <meta name="description" content="Explore insightful and engaging seo friendly content on our blog page. From industry trends to expert tips, our blog covers a variety of topics to keep you informed and inspired." />
        <link rel="canonical" href="https://www.bemywordsmith.com/blogs" />
      </Helmet>
      {/* Latest Blogs */}
      <LatestBlogs />

      {/* Choose Industries */}
      <ChooseIndustries />

      {/* More Blogs */}
      <MoreBlogs />

      {/* Contact Us Button Div */}
      <ContactUsBtn />
    </>
  )
}

export default BlogsPage;