import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useWordCountTime from '../../../Hooks/useWordCountTime';
import BlogImg1 from "../../../Image/Blogs/Blog_Page/Blogs/Blog_110/blog_110-1.webp";
import "../Blog_Page_Component/CSS/BlogHeadingAndSMIcon.css";
import BlogHeadingAndSMIcon from '../Blog_Page_Component/JS/BlogHeadingAndSMIcon';
import PopularBlogs from '../Blog_Page_Component/JS/PopularBlogs';
import "../CSS/MainBlogPageCSS.css";
import BlogFormRendering from '../Main_Blog_Page/Blog_Page_Component/BlogFormRendering';

const ElecticVehicleEV110 = () => {
    const [readingTime, findWord] = useWordCountTime();
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.bemywordsmith.com/blogs/is-electric-vehicle-the-future"
                        },
                        "headline": "Is EV (Electric vehicle) the future?",
                        "description": "Explore the future of transportation with EVs. Discover why electric vehicles are key to sustainable mobility and innovation in the automotive industry.",
                        "image": "https://www.bemywordsmith.com/static/media/blog_110-1.6ac0e32cbb5b57a762d7.webp",  
                        "author": {
                            "@type": "Organization",
                            "name": ""
                        },  
                        "publisher": {
                            "@type": "Organization",
                            "name": "",
                            "logo": {
                            "@type": "ImageObject",
                            "url": ""
                            }
                        },
                        "datePublished": "2024-06-28",
                        "dateModified": "2024-07-02"
                        }`}
                </script>
                <title>Blog | Is EV (Electric vehicle) the future?</title>
                <meta name="description" content="Explore the future of transportation with EVs. Discover why electric vehicles are key to sustainable mobility and innovation in the automotive industry." />
                <link rel="canonical" href="https://www.bemywordsmith.com/blogs//blogs/is-electric-vehicle-the-future" />
            </Helmet>
            {/* Heading And Social Media Icon */}
            <section className="individualBlogPagesHeadingAndSocialMediaIcon">
                <div className="container">
                    <div className="row justify-content-between individualBlogPagesHeadingAndSocialMediaIconSecondInnerDiv">
                        <div className="col-md-8 headingOfTheBlog">
                            <h1>Is EV (Electric vehicle) the future?</h1>
                            <p><span>Renu S | </span><span>28-06-2024 | </span><span>{readingTime} min read</span></p>
                        </div>
                        <BlogHeadingAndSMIcon />
                    </div>
                </div>
            </section>
            <section className="secondSecondOfIndividualBlogPageStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12" ref={findWord}>
                            <div className="blogContent">
                                <div className="blogImageDiv1">
                                    <img src={BlogImg1} alt="Is EV (Electric vehicle) the future?" className='img-fluid' />
                                </div>
                                <p className='mt-3'>Looking at the present scenario, it can be said that EV is the future. Some of the common reasons to establish EVs is reducing oil consumption and minimizing pollution in nature (all sorts of pollution). We have witnessed the market for EV is rising.</p>
                                <p>Gas-powered cars and electric vehicles differ primarily in that the former use gas to power their engines, while the latter uses electricity to charge their batteries. Fuel is needed for both gasoline-powered and electric cars; the former needs gasoline refills, while the latter needs EV battery recharges.</p>
                                <h3>Pros of owing an EV</h3>
                                <ol>
                                    <li>
                                        <b>Environment Friendly</b>
                                        <ul>
                                            <li>First and foremost, electric vehicles are environmentally friendly. Because electric vehicles (EVs) don't emit exhaust like gas vehicles do, driving an EV has a considerably smaller environmental impact than driving a gas vehicle.</li>
                                            <li>Driving an electric vehicle (EV) can have a variable carbon footprint depending on the energy source used to generate the electricity. </li>
                                            <li>Operating an electric vehicle has a negligible carbon footprint if you charge it at home using solar electricity. This also holds true for utilities whose main supply of electricity comes from renewable sources.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Saves money in fuel</b>
                                        <ul>
                                            <li>Compared to fuel, charging an electric car is far less expensive.</li>
                                            <li>Filling up the tank with gas is significantly more expensive than charging an electric vehicle (EV) at home or elsewhere, even though it will still cost the owners money. </li>
                                            <li>According to the National Renewable Energy Laboratory of the U.S. Department of Energy, charging an electric automobile is often less expensive than filling up a gas vehicle throughout the duration of a car's 15-year lifespan. Solar-powered homes can significantly reduce their EV charging costs.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Maintenance</b>
                                        <ul>
                                            <li>when it comes to maintenance, EV cars have certain advantages.</li>
                                            <li>After a certain number of miles, there is a need for an oil change in gas cars, but when it comes to EVs, it is not required, which in turn aids in making fewer trips to mechanics.</li>
                                            <li>Even the parts used in EV cars have longer durability and no engine to maintain when compared to an internal combustion car.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Varied Option</b>
                                        <ul>
                                            <li>When it comes to EVs, India offers a wide range of options, including two-wheelers, three-wheelers, four-wheelers, and even electric buses.</li>
                                            <li>In the United States, electric vehicles have been on sale since the start of the decade. As of January 2021, the USA was home to EVs produced by Tesla, Hyundai, Chevrolet, Nissan, Kia, BMW, Ford, and other automakers.</li>
                                            <li>Cars come in a variety of sizes, from trucks to SUVs and little cars, which makes them more suited for distinct uses.</li>
                                            <li>Currently, a lot of automakers intend to quickly expand the number of electric vehicles in their fleet. Toyota, for instance, has promised to produce thirty electric vehicle types by 2030.</li>
                                        </ul>
                                    </li>
                                </ol>
                                <h3>Cons of owing an EV</h3>
                                <ol>
                                    <li>
                                        <b>Charging Time</b>
                                        <ul>
                                            <li>When it comes to charging the battery of an EV car, it requires more time compared to filling fuel in fuel gas vehicles.</li>
                                            <li>Using a 7kW charger—the power of an average at-home charger—a standard EV's 60 kWh battery will take approximately 8 hours to fully charge.</li>
                                            <li>On the other hand, a quick charger can charge an electric car up to 100 miles in around 35 minutes.</li>
                                            <li>Mostly, all owners schedule their charging prior to avoid last-minute mishaps.</li>
                                            <li>They usually charge their car every night when they arrive home, during the workday at the workplace, or while errands at the store to shorten the charging time and maintain the battery's charge.</li>
                                            <li>It may be necessary to plan ahead for longer excursions in order to find speedy chargers that you can use continuously.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Availability of charging station</b>
                                        <ul>
                                            <li>Not everywhere; charging stations have been installed. But when it comes to gasoline, oil pumps are available at convenient locations; individuals can easily.</li>
                                            <li>An extensive network of charging stations is unavoidably required, given the rising number of electric vehicles in the country.</li>
                                            <li>Many EV experts contend that these charging stations' accessibility and convenience are still lacking. The majority of electric vehicles claim that even with charging networks in place, it is still insufficient.</li>
                                            <li>Many people are hesitant to purchase because they are mostly concerned "about charger uptime."</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <b>Maintenance</b>
                                        <ul>
                                            <li>It is indeed true that car services are expensive, no matter which car the individual has purchased.</li>
                                            <li>Furthermore, when car owners buy expensive cars, they need to go to a dealer for servicing as the parts are unique and are not available at a local service center.</li>
                                            <li>Since auto repairs are generally expensive, regardless of the vehicle one drives, it's hard to say whether or not electric vehicles will be more or less expensive when it comes to maintenance.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            <div className="blogContent">
                                <h3>Conclusion</h3>
                                <p>The future in vehicles is EV cars. At present, there may be enormous questions, but with time, all issues and queries will be resolved, and alternatives will be found.</p>
                                <p>Additionally, they have regenerative braking systems, which collect and store the kinetic energy that would otherwise be lost during braking. EVs emit no exhaust emissions and are significantly more efficient than traditional cars.</p>
                                <p>For top-notch <Link to={"/content-writing-services-in-bangalore"}>content writing services</Link> designed to maximize conversion, check out <b>Be My Wordsmith</b>.</p>
                            </div>
                        </div>
                        <BlogFormRendering />
                    </div>
                </div>
            </section>
            {/* Popular Blogs Section */}
            <PopularBlogs />
        </>
    )
}

export default ElecticVehicleEV110;