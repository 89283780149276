import React, { useState } from 'react';
import "../../../Blog_Page/CSS/MainBlogPageCSS.css";
// export const PostCommentContext = createContext();

const BlogPostComment = ({addContact}) => {
    // const addContact = useContext(PostReplyContext);
    const [contactData, setContactData] = useState({comment: "", email: "", name: ""});
    const handleSubmit = (e) => {
        e.preventDefault();
        if (contactData.name === "" || contactData.email === "" || contactData.name === "") {
            alert("Please fill the field");
            return;
        }
        // console.log(setPost([...post, {
        //     id: post.length,
        //     comment: contactData.comment,
        //     email: contactData.email,
        //     name: contactData.name
        // }]));
        addContact(contactData);
        setContactData({comment: "", email: "", name: ""})
    }
    const handleChange = (e) => {
        if (e.target.name === "comment") {
            setContactData({...contactData, comment: e.target.value})
        } else if (e.target.name === "email") {
            setContactData({...contactData, email: e.target.value})
        } else {
            setContactData({...contactData, name: e.target.value})
        }
    }
    return (
        <>
            <div className="col-md-7 commnet-section-form-div">
                <div className="comment-section-form-first-inner-div">
                    <h3>Submit a Comment</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <textarea className="form-control" id="comment" name='comment' rows="3" placeholder='Comment*' value={contactData.comment} onChange={handleChange} ></textarea>
                        </div>
                        <div className="mb-3">
                            <input type="email" className="form-control" id="email" name='email' aria-describedby="emailHelp" placeholder='Email' value={contactData.email} onChange={handleChange} />
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" id="name" name='name' aria-describedby="emailHelp" placeholder='Name' value={contactData.name} onChange={handleChange} />
                        </div>
                        <button type="submit" className="btn post-comment-btn">Post Comment</button>
                    </form>
                </div>
            </div>
            <div className="col-md-12 comment-reply-form-div">
                {/* <PostCommentContext.Provider value={post}>
                    <BlogCommentsReply />
                </PostCommentContext.Provider> */}
                {
                    
                }

                {/* <div className="comment-reply-first-inner-div mb-3">
                    <div className="user-icon-div">
                        <img src={CommentIcon} alt="ICON" />
                    </div>
                    <div className="user-comments-div ms-3">
                        <p className='users-name'>Anonymous</p>
                        <p className='users-comment'>JavaScript often abbreviated as JS, is a programming language that is one of the core technologies of the World Wide Web, alongside HTML and CSS. As of 2023, 98.7% of websites use JavaScript on the client side for webpage behavior, often incorporating third-party libraries. All major web browsers have a dedicated JavaScript engine to execute the code on users' devices.</p>
                    </div>
                </div> */}
                {/* <div className="comment-reply-first-inner-div mb-3">
                    <div className="user-icon-div">
                        <img src={CommentIcon} alt="ICON" />
                    </div>
                    <div className="user-comments-div ms-3">
                        <p className='users-name'>Anonymous</p>
                        <p className='users-comment'>JavaScript often abbreviated as JS, is a programming language that is one of the core technologies of the World Wide Web, alongside HTML and CSS. As of 2023, 98.7% of websites use JavaScript on the client side for webpage behavior, often incorporating third-party libraries. All major web browsers have a dedicated JavaScript engine to execute the code on users' devices.</p>
                    </div>
                </div> */}
            </div>
            {/* <div className="col-md-7 commnet-section-form-div">
                <div className="comment-section-form-first-inner-div">
                    <h3>Submit a Comment</h3>
                    <form name='comment-section-form' method="post" enctype="multipart/form-data" netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true" onSubmit="submit">
                        <input type="hidden" name='form-name' value="comment-section-form" />
                        <div className="mb-3">
                            <textarea className="form-control" id="comment" name='comment' rows="3" placeholder='Comment*'></textarea>
                        </div>
                        <div className="mb-3">
                            <input type="email" className="form-control" id="email" name='email' aria-describedby="emailHelp" placeholder='Email' />
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" id="name" name='name' aria-describedby="emailHelp" placeholder='Name' />
                        </div>
                        <button type="submit" className="btn post-comment-btn">Post Comment</button>
                    </form>
                </div>
            </div> */}
        </>
    )
}

export default BlogPostComment