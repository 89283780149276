import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, NavLink } from 'react-router-dom'
import "../../Component/CSS/FAQOnAboutUs.css"
import MainTestimonial from '../../Component/Testimonial/MainTestimonial'
import { WhyChooseUsData } from '../../Data/Landing_Page/WhyChooseUsData'
import OkIcon from "../../Image/Landing_Page/Vector.png"
import BannerImg from "../../Image/Landing_Page/landing_page_banner.webp"
import ContentThatMakesYouContent from "../../Image/Landing_Page/landing_page_content.webp"
import StandOutImg from "../../Image/Landing_Page/landing_page_withus.webp"
import "../CSS/LandingPage.css"
import ContentDeliveryProcess from '../Landing_Page_Component/ContentDeliveryProcess'
import FAQONLandingPage from '../Landing_Page_Component/FAQONLandingPage'
import IndustriesWeCaterTo from '../Landing_Page_Component/IndustriesWeCaterTo'

const LandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Best SEO Friendly Content Writing Services Agency in India | Be My Wordsmith</title>
                <meta name="description" content="Revamp your brand with best seo friendly content writing services agency in india that facilitate expansion and resonating with your audience and positioning your brand for success." />
                <link rel="canonical" href="https://www.bemywordsmith.com/" />
                <script type="application/ld+json">
                    {`{
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [{
                        "@type": "Question",
                        "name": "What makes the best content writing service in Bangalore?",
                        "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "- Our Bangalore content writing company is skilled in creating attention-grabbing, convincing content that motivates readers to take action.
                        - Our delivery of 100% original content makes us proud. Every work is written by our writers from scratch, guaranteeing originality and genuineness.
                        - In order to ensure that the material we provide is precise, dependable, and useful, our SEO writing staff undertakes thorough research to obtain pertinent information and insights.
                        - We take great care in our grammar and proofreading, making sure that every piece of writing is polished, and of the greatest caliber."
                        }
                    },{
                        "@type": "Question",
                        "name": "Is your content SEO-friendly?",
                        "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Make sure your articles and blog are optimized for search engines (SERPs) so that they appear on the first and second pages of Google search results. In order to increase your content's visibility on Search Engine Result Pages, you should focus on specific keywords."
                        }
                    },{
                        "@type": "Question",
                        "name": "What is the turnaround time for a Project?",
                        "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "We can complete tasks quickly because we have a group of skilled writers who are available both full-time and on a project basis."
                        }
                    },{
                        "@type": "Question",
                        "name": "Will I be able to own copyright to the content you supply?",
                        "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "Yes, Defenitely! Once the entrie Payment is completed."
                        }
                    },{
                        "@type": "Question",
                        "name": "Where do I get Started?",
                        "acceptedAnswer": {
                        "@type": "Answer",
                        "text": "We need keywords, two to three competitor links (to deepen our expertise of your company), and the specifics of your target audience's buyer persona in order to begin crafting captivating contents that will boost your brand. This data will open the door for well-written content that elevates your brand to new levels of achievement."
                        }
                    }]
                    }`}
                </script>
            </Helmet>
            <div className='landingPageMainDiv'>
                <section className="firstSectionOfLandingPageStartHere">
                    <div className="container firstSectionOfLandingPageInnerDivStartHere">
                        <div className="row justify-content-evenly">
                            <div className="col-md-6 bannerSectionHeadingParaAndButton">
                                <h1>Accelerate your business through contents that speaks for itself</h1>
                                <p>Content crafted specifically to meet your business requirements</p>
                                <NavLink to={"/contact-us"} className="href"><button>Get Started</button></NavLink>
                            </div>
                            <div className="col-md-6 bannerImgSection">
                                <img src={BannerImg} alt="Best SEO Friendly Content Writing Services Agency in India" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="secondSectionOfLandingPageStartHere" id='usingOurContents'>
                    <div className="container-fluid">
                        <div className="row justify-content-around">
                            <div className="col-lg-5 secondSectionImageDiv">
                                <img src={StandOutImg} alt="Best SEO Friendly Content Writing Services Agency in India" className='img-fluid' />
                            </div>
                            <div className="col-lg-6 secondSectionContentAndButtonDiv">
                                <p className='sectionSectionFirstHeading'>Who</p>
                                <h6>Standout from competition using our outstanding content</h6>
                                <p className='secondSectionPara'>Fresh, high-quality, manually written (not AI generated), SEO friendly content results in more leads and conversions, and improved search engine rankings. Not an easy task to deliver a well cooked content, unless it is our in-house master chefs!</p>
                            </div>
                            <div className="col-lg-11 secondSectionContentAndButtonDiv">
                                <p>Elevate your brand above the competition with our <b>best seo friendly content writing services agency in india</b>. Our team of in-house master chefs crafts fresh, high-quality content, manually written (non-AI-generated) content. Our SEO-friendly approach ensures increased leads, conversions, and enhanced search engine rankings. Delivering well-cooked content is no simple feat, but with our experts at <b>www.bemywordsmith.com</b>, excellence is guaranteed. Trust us to tailor content that not only stands out but also drives your success in the digital landscape. Choose the power of genuine craftsmanship for the <b>seo friendly content</b> that captivates, converts, and catapults your brand to new heights.</p>
                                <div className="iconAndIdeas">
                                    <p><span className='uniqueIconImg'><img src={OkIcon} alt="" /> </span><span className='ideas'>Unique</span></p>
                                    <p><span className='iconImg'><img src={OkIcon} alt="" /></span> <span className='ideas'>Original</span></p>
                                    <p><span className='iconImg'><img src={OkIcon} alt="" /></span> <span className='ideas'>Timely</span></p>
                                    <p><span className='iconImg'><img src={OkIcon} alt="" /></span> <span className='ideas'>Vetted</span></p>
                                    <p><span className='iconImg'><img src={OkIcon} alt="" /></span> <span className='ideas'>Humane</span></p>
                                </div>
                                <NavLink to={"/categories"} className="href"><button>Learn More</button></NavLink>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="first-question-section-start">
                    <div className="container first-question-first-inner-div">
                        <div className="row justify-content-center">
                            <div className="col-md-12 first-question-heading-div">
                                <p>How to choose </p>
                                <h6>the best content writing service in Bangalore?</h6>
                            </div>
                            <div className="col-md-12 first-question-para-div">
                                <p>When choosing the <b>best blog writing service</b>, keep the following important criteria in mind:</p>
                                <ul>
                                    <li>Clearly state your objectives and standards.</li>
                                    <li>Analyze your preferred writing service timeframe and budget.</li>
                                    <li>Recognize your target market and adjust the service to meet their needs.</li>
                                    <li>Make sure the writing voice and tone are consistent with your brand.</li>
                                    <li>Make good communication your first priority, and during the writing process, give feedback some thought.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="thirdSectionOfLandingPageStartHere">
                <div className="container thirdSectionOfLandingPageInnerDivStartHere">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 bestContentsGetFromUsHeadingDiv">
                            <p>What</p>
                            <h6>Content that you can get from us</h6>
                        </div>
                        <div className="col-lg-5 bestContentsGetFromUsLeftDiv mb-4">
                            <div className="bestContentsGetFromUsLeftInnerDiv">
                                <h6>Website Content</h6>
                                <p>Your website has a great design, a very good skeleton, how about adding some muscles to it to get that desired movement</p>
                                <h3>100+ Websites</h3>
                                <NavLink to={"/categories/websiteContentWriting"} className="href"><button>Learn More</button></NavLink>
                            </div>
                        </div>
                        <div className="col-lg-5 bestContentsGetFromUsRightDiv mb-4">
                            <div className="bestContentsGetFromUsRightFirstInnerDiv">
                                <h6>Blogs/Articles</h6>
                                <p>Agreed you have a great muscular body and a good skeleton support, how about your daily dietary needs to keep the body going forward everyday</p>
                                <NavLink to={"/categories/blogsAndArticles"} className="href"><button>Learn More</button></NavLink>
                            </div>
                            <div className="bestContentsGetFromUsRightSecondInnerDiv mt-4">
                                <h6>Product Description</h6>
                                <p>Gorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                <NavLink to={"/categories/productDescription"} className="href"><button>Learn More</button></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
                <section className="thirdSectionOfLandingPageStartHere">
                    <div className="container thirdSectionOfLandingPageInnerDivStartHere">
                        <div className="row justify-content-evenly">
                            <div className="col-md-12 bestContentsGetFromUsHeadingDiv">
                                <p>What</p>
                                <h6>Content that you can get from us</h6>
                            </div>
                            <div className="col-lg-4 bestContentsGetFromUsDiv mb-4">
                                <div className="bestContentsGetFromUsFirstInnerDiv">
                                    <h6>Website Content</h6>
                                    <p>Your website has a great design, a very good skeleton, how about adding some muscles to it to get that desired movement.</p>
                                    <h3>100+ Websites</h3>
                                    <NavLink to={"/categories/best-website-content-writing-services-in-bangalore"} className="href"><button className='websiteContentBtn'>Learn More</button></NavLink>
                                </div>
                            </div>
                            <div className="col-lg-4 bestContentsGetFromUsDiv mb-4">
                                <div className="bestContentsGetFromUsFirstInnerDiv">
                                    <h6>Blogs/Articles</h6>
                                    <p>Agreed you have a great muscular body and a good skeleton support, how about your daily dietary needs to keep the body going forward everyday.</p>
                                    <NavLink to={"/categories/best-blog-&-article-writing-services-in-india"} className="href"><button className='blogArticlesBtn'>Learn More</button></NavLink>
                                </div>
                            </div>
                            <div className="col-lg-4 bestContentsGetFromUsDiv mb-4">
                                <div className="bestContentsGetFromUsFirstInnerDiv">
                                    <h6>Product Description</h6>
                                    <p>Agreed you have a great muscular body and a good skeleton support, how about your daily dietary needs to keep the body going forward everyday.</p>
                                    <NavLink to={"/categories/product-description-content-writing-services-in-india"} className="href"><button className='productDescriptionBtn'>Learn More</button></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="second-question-section-start">
                    <div className="container second-question-first-inner-div">
                        <div className="row justify-content-center">
                            <div className="col-md-12 second-question-heading-div">
                                <p>Can I get the </p>
                                <h6>content within a days time in any urgent cases?</h6>
                            </div>
                            <div className="col-md-12 second-question-para-div">
                                <p>Absolutely! BMWS understands the urgency of your needs. If you require a blog on the same day you place your order, we've got you covered. Just share your requirements, and we'll promptly create quality content for you. We prioritize your deadlines, ensuring that you receive excellent blog content without compromising on speed or quality.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='fourthSectionOfLandingPageStartHere'>
                    <div className="container fourthSectionOfLandingPageInnerDivStartHere">
                        <div className="row justify-content-evenly">
                            <div className="col-md-12 whyChooseUsHeading">
                                <p>Why</p>
                                <h6>Choose us</h6>
                            </div>
                            {
                                WhyChooseUsData.map((data) => (
                                    <div className="col-sm-6 col-md-4 col-lg-3 whyChooseUsCardDiv" key={data.id}>
                                        <div className="whyChooseUsCardFirstInnerDiv">
                                            <img src={data.cardImage} alt={data.altTag} className='img-fluid' />
                                            <h6>{data.title}</h6>
                                            <p>{data.description}</p>
                                            <p className="why-choose-us-author-name">{data.author}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
                <section className="third-question-section-start">
                    <div className="container third-question-first-inner-div">
                        <div className="row justify-content-center">
                            <div className="col-md-12 third-question-heading-div">
                                <p>How are </p>
                                <h6>blogs different from usual website?</h6>
                            </div>
                            <div className="col-md-12 third-question-para-div">
                                <p>Although there are some parallels, a <Link to={"/categories/best-blog-&-article-writing-services-in-india"}>blog</Link> differs from a standard website in several important ways. A conversational tone and frequent updates encourage interaction on blogs. On the other hand, formal, static content that informs customers about goods or services is a common component of websites. A blog's dynamic, interactive approach promotes continuous communication with its readers, whereas websites place more emphasis on offering thorough, organized content.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <ContentDeliveryProcess />
                <section className="fourth-question-section-start">
                    <div className="container fourth-question-first-inner-div">
                        <div className="row justify-content-center">
                            <div className="col-md-12 fourth-question-heading-div">
                                <p>What are the roles of the website and</p>
                                <h6>blog writers for to fulfuill in a content writing agency?</h6>
                            </div>
                            <div className="col-md-12 fourth-question-para-div">
                                <p><Link to={"/our-creators"}>Blog writers</Link> at <b>content writing agencies</b> perform diverse roles based on client requirements. Their tasks involve generating and proposing ideas for client websites, brainstorming to enhance content appeal, conducting thorough research on novel aspects of a topic, guiding the content direction, and incorporating relevant examples. Additionally, they ensure that the language is sensitive and avoids political controversy, among other responsibilities. The varied nature of their role caters to delivering high-quality, tailored content for clients.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <IndustriesWeCaterTo />
                <section className="seventhSectionOfLandingPageStartHere">
                    <div className="container seventhSectionOfLandingPageInnerDivStartHere">
                        <div className="row justify-content-evenly">
                            <div className="col-md-6 contentThatMakesYouContentHeadingAndPara">
                                <p className='seventhSectionFirstHeading'>Content</p>
                                <h6 className='seventhSectionSecondHeading'>That makes you Content</h6>
                                <p className='seventhSectionFirstPara'>Be My Wordsmith does not just make words out of letters. We smith out content that matters, content that positively impacts your business and help you deliver the message you intend to. Be it blog writing or website content or <Link to={"/categories/product-description-content-writing-services-in-india"}>product description</Link>, the content craved by the experts of Be My Wordsmith ensures that you are contentful.</p>
                                <p className='seventhSectionImgIconAndParaInOneLine'><span className='seventhSectionImgOkIcon'><img src={OkIcon} alt="OK ICON" /></span> <span className='seventhSectionSecondParaInOneLine'>100% contentment guaranteed</span></p>
                            </div>
                            <div className="col-md-6 contentThatMakesYouContentImgDiv">
                                <img src={ContentThatMakesYouContent} alt="Best SEO Friendly Content Writing Services Agency in India" />
                            </div>
                            <div className="col-md-12 contentThatMakesYouContentHeadingAndPara">
                                <p>Discover true contentment with <b>Be My Wordsmith</b>. We don't merely string letters together; we craft meaningful content that resonates. Our adept team molds content that goes beyond words, creating a positive impact on your business and effectively conveying your intended message. Whether it's <b>blog writing, <Link to={"/categories/best-website-content-writing-services-in-bangalore"}>website content</Link>, or product descriptions</b>, the content curated by our team of experts ensures your satisfaction. Embrace our commitment to excellence with a 100% contentment guarantee. Choose us, and experience the assurance of content that not only meets but exceeds your expectations. Your journey to meaningful and impactful communication starts here.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Eighth Section */}
                <MainTestimonial />
                <section className="faq-on-about-us-page-section-start-here">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-md-12 faq-comp-heading-and-para-div">
                                <h2>Frequently asked questions</h2>
                                <p>Everything you need to know about the product and billing.</p>
                            </div>
                            <div className="col-md-9 faq-comp-main-div">
                                <FAQONLandingPage />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default LandingPage;