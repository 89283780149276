import React from 'react'
import "../CSS/CategoriesPage.css"
import { HowDoWeData } from '../../Data/Categories/HowDoWeData';

const HowDoWe = () => {
    return (
        <>
            <section className="fourthSectionOfCategoriePageStartHere">
                <div className="container">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 howDoWeHeadingDiv">
                            <p>How do we</p>
                            <h6>Create magic with words</h6>
                        </div>
                        {
                            HowDoWeData.map((data) => (
                                <div className="col-md-6 col-lg-4 howDoWeCardDiv" key={data.id}>
                                    <div className="howDoWeCardInnerDiv">
                                        <img src={data.cardImage} alt={data.altTag} className='img-fluid' />
                                        <h6>{data.title}</h6>
                                        <p>{data.desc}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default HowDoWe;