import React, { useState } from 'react'
import { ourBlogs } from '../../../../Data/Blogs/OurBlogsData'
import Pagination from '../../Blog_Page_Component/JS/Pagination'
import "../Main_File/BlogsPage.css"

const MoreBlogs = () => {
  let randomBlogDetailsOnBlogPageNumOne = Math.floor(Math.random() * ourBlogs.length);
  let randomBlogDetailsOnBlogPageNumTwo = Math.floor(Math.random() * ourBlogs.length);
  let randomBlogDetailsOnBlogPageNumThree = Math.floor(Math.random() * ourBlogs.length);
  let randomBlogDetailsOnBlogPageNumFour = Math.floor(Math.random() * ourBlogs.length);
  const [showPerPage, setShowPerPage] = useState(6);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };
  let blogsArray = [];
  for (let index = ourBlogs.length - 1; index >= 0; index--) {
    blogsArray.push(ourBlogs[index])
  }
  return (
    <>
      <section className="thirdSectionOfBlogPageStartHere">
        <div className="container thirdSectionOfBlogPageFirstInnerDiv">
          <div className="row justify-content-evenly">
            <div className="col-md-12 moreBlogsHeading">
              <p>More</p>
              <h3>Blogs</h3>
            </div>
            <div className="col-lg-8 moreBlogsCardMainDiv">
              <div className="container-fluid">
                <div className="row justify-flex-start">
                  {
                    blogsArray.slice(pagination.start, pagination.end).map((blogsData, key) => {
                      return(
                        <div className="col-sm-6 col-md-6 moreBlogsIndividualCardDiv" key={key}>
                          <div className='moreBlogsIndividualCardImageDiv moreBlogsCardForGivingTopMargin'>
                            <img src={blogsData.blogImage} alt={blogsData.altTag} className='img-fluid' />
                            <div className='moreBlogsIndividualCardHeadingAndParaDiv'>
                              <h3>{blogsData.title}</h3>
                              <p className='moreBlogsPara'>{blogsData.description.slice(0, 111)}...</p>
                              <p className="readMoreBlogButton">{blogsData.newPage}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
                <Pagination
                  showPerPage={showPerPage}
                  onPaginationChange={onPaginationChange}
                  total={ourBlogs.length}
                />
              </div>
            </div>
            <div className="col-lg-4 moreBlogsAlsoReadDiv">
              <div className='alsoReadHeading'>
                <h3>Also Read</h3>
              </div>
              <div className="firstTwoAlsoReadCard">
                <div className='alsoReadBlogsImageAndParaDiv'>
                  <img src={ourBlogs[randomBlogDetailsOnBlogPageNumOne].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumOne].altTag} className='img-fluid' />
                  <div className="also-read-para-and-read-more-btn-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumOne].title}</p>
                  </div>
                </div>
                <div className='alsoReadBlogsImageAndParaDiv'>
                  <img src={ourBlogs[randomBlogDetailsOnBlogPageNumTwo].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumTwo].altTag} className='img-fluid' />
                  <div className="also-read-para-and-read-more-btn-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumTwo].title}</p>
                  </div>
                </div>
              </div>
              <div className="firstTwoAlsoReadCard">
                <div className='alsoReadBlogsImageAndParaDiv'>
                  <img src={ourBlogs[randomBlogDetailsOnBlogPageNumThree].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumThree].altTag} className='img-fluid' />
                  <div className="also-read-para-and-read-more-btn-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumThree].title}</p>
                  </div>
                </div>
                <div className='alsoReadBlogsImageAndParaDiv'>
                  <img src={ourBlogs[randomBlogDetailsOnBlogPageNumFour].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumFour].altTag} className='img-fluid' />
                  <div className="also-read-para-and-read-more-btn-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumFour].title}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MoreBlogs;