import React from 'react'
import "./Testimonial.css"
import Image1 from "../../Image/Landing_Page/Testimonials/semi-colon.png"
import TestimonialComponent from './TestimonialComponent'

const MainTestimonial = () => {
    return (
        <>
            <section className="eighthSectionOfLandingPageHeadingDiv">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 testimonialsHeadingDiv">
                            <h2>Testimonials</h2>
                        </div>
                    </div>
                </div>
            </section>
            <section className="eighthSectionOfLandingPageStartHere">
                <div className="container-fluid eighthSectionOfLandingPageFirstInnerDiv">
                    <div className="row justify-content-evenly">
                        <div className="col-lg-4 testimonialHeadingAndPara">
                            <img src={Image1} alt="Colon Icon" className='img-fluid' />
                            <h4>Listen to our trusted customers.</h4>
                            <p>Don't just listen to us. Hear from our most trusted customer.</p>
                        </div>
                        <div className="col-lg-7">
                            <TestimonialComponent />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MainTestimonial;