import React, { useEffect, useState } from 'react';
import BlogCommentsReply from './BlogCommentsReply';
import BlogPostComment from './BlogPostComment';
// export const PostReplyContext = createContext();
import uuid4 from 'uuid4';

const BlogFormRendering = () => {
    const localStorageKey = "post";
    const [post, setPost] = useState(() => {
        return JSON.parse(localStorage.getItem(localStorageKey)) || []
    });
    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(post))
    }, [post])
    const addContact = (data) => {
        setPost([...post, {uuid4, data}])
    }
  return (
    <>
    {/* <PostReplyContext.Provider value={addContact}> */}
    <BlogPostComment addContact={addContact} />
    <BlogCommentsReply post={post} />
    {/* </PostReplyContext.Provider> */}
    </>
  )
}

export default BlogFormRendering