

export const WebsiteContentData = [
    {
        id : 1,
        cardImage : require('../../Image/Categories_Page/Website_Content_Writing/Content.png'),
        title : "Content relevancy",
        desc : "Infuse your unique vision into tailor-made content that resonates. Our website content services ensure relevance, aligning with your distinct perspective. Take a step ahead of your online presence with content that truly reflects your vision.",
        altTag: "Content relevancy"
    },
    {
        id : 2,
        cardImage : require('../../Image/Categories_Page/Website_Content_Writing/Manager.png'),
        title : "Dedicated SEO Manager",
        desc : "Integrate your vision seamlessly with our dedicated SEO manager. Tailoring customized content, we align with your unique perspective to enhance online presence. Elevate your brand with a personalized touch that ensures both relevance and optimization.",
        altTag: "Dedicated SEO Manager"
    },
    {
        id : 3,
        cardImage : require('../../Image/Categories_Page/Website_Content_Writing/Website.png'),
        title : "Website Analytics",
        desc : "Immerse your vision into tailored content with our Website Analytics. Crafting customized material, we synchronize with your unique perspective to refine your online presence. Elevate your brand through personalized, analytically-driven content.",
        altTag: "Website Analytics"
    },
    {
        id : 4,
        cardImage : require('../../Image/Categories_Page/Website_Content_Writing/Keyword.png'),
        title : "Keyword targeting",
        desc : "Blend your vision seamlessly into specialized content with our Keyword Targeting services. Tailoring personalized material, we synchronize with your unique perspective to enhance your online presence.",
        altTag: "Keyword targeting"
    },
    {
        id : 5,
        cardImage : require('../../Image/Categories_Page/Website_Content_Writing/Link_Building.png'),
        title : "Link building",
        desc : "Integrate your vision into custom content through our Link Building services. Designed exclusively for you, we harmonize with your unique perspective to strengthen your online presence. Enhance your brand with content crafted for successful link-building strategies.",
        altTag: "Link building"
    },
    {
        id : 6,
        cardImage : require('../../Image/Categories_Page/Website_Content_Writing/SEO_Optimized.png'),
        title : "SEO optimized",
        desc : "Immerse your vision in personalized content through our SEO-Optimized services. Uniquely crafted for you, we synchronize with your perspective to boost online visibility.",
        altTag: "SEO optimized"
    }
];