import React from 'react'
import "../CSS/CategoriesPage.css"
// import BannerImg from "../../Image/Categories_Page/category_banner.png"
import Video from "../../Image/Categories_Page/Categorie_Video.webp"
import EngageCustomers from "../../Image/Categories_Page/category_engage.png"
import OKIcon from "../../Image/Landing_Page/Vector.png"
import HowDoWe from '../Categories_Pages_Component/HowDoWe'
import SelectServicesThatYouNeed from '../Categories_Pages_Component/SelectServicesThatYouNeed'
// import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import "../../Component/CSS/FAQOnAboutUs.css"
import SubscribeForm from '../../Component/JS/SubscribeForm'
import FAQONCategoriesPage from '../Categories/Categories_Components/JS/FAQONCategoriesPage'

const CategoriesPage = () => {
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What is Your Content Revision Policy and do you charge additionaly for revisons?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "At Be My Wordsmith, if you don't like the content we give you, just contact the person you've been talking to about the project. We'll quickly make changes accordingly as per our terms and conditions. We provide one free round of revisions with each piece of writing. But if you want changes after that, there will be a charge for the revisions."
                            }
                        },{
                            "@type": "Question",
                            "name": "What is the Cost of Hiring BMWS Writer For Our Content Requirements?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Our prices are flexible and dependent on a number of variables, like the deadline, volume, and service. To get a free quote, please use the Get Quote form in the Contact Us page"
                            }
                        },{
                            "@type": "Question",
                            "name": "What Process does Be My Wordsmith follow?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Our rigorous content creation methodology places a strong emphasis on in-depth research and a comprehensive understanding of your company. We then meticulously clarify and polish the content. We consider ourselves to be your communication agency in addition to being a content writing firm."
                            }
                        },{
                            "@type": "Question",
                            "name": "I have a huge writing project. Before entrusting the entire task to you, I'd like to evaluate your services through a test.",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Indeed. We would be delighted to start collaborating with you on a portion of your project. You are welcome to assign us the full job if you are satisfied with the quality."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do You Focus on SEO Content Writing?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "For your articles to appear on the first and top pages of Google search results, it's crucial to make them SEO-friendly. This means focusing on specific keywords to help your content rank higher on Search Engine Result Pages."
                            }
                        },{
                            "@type": "Question",
                            "name": "How does Fresh Content help New websites?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Creating current and pertinent content is crucial for connecting with your customer base. We prioritize delivering content that is not only up to date but also resonates with your audience. Additionally, this approach aids search engines in establishing the credibility of your website, influencing its placement on the Search Engine Results Page (SERP)."
                            }
                        }]
                        }`}
                </script>
                <title>Categories | Be My Wordsmith</title>
                <meta name="description" content="Explore a world of compelling content with our best seo writing services. From engaging product descriptions to insightful blog and article writing, discover expertly crafted content for every category on our site." />
                <link rel="canonical" href="https://www.bemywordsmith.com/categories" />
            </Helmet>
            {/* Second Section Start Here */}
            <SelectServicesThatYouNeed />

            <section className="thirdSectionOfCategoriePageStartHere">
                <div className="container thirdSectionOfCategoriePageFirstInnerDiv">
                    <div className="row justify-content-evenly">
                        <div className="col-md-6 engageYourCustomersImgDiv">
                            <img src={EngageCustomers} alt="best seo writing services" className='img-fluid' />
                        </div>
                        <div className="col-md-6 engageYourCustomersHeadingAndPara">
                            <p className='headingFirstPara'>Ways to</p>
                            <h6 className='headingSecondPara'>Engage your customers</h6>
                            <p className='iconAndContentPara'><span><img src={OKIcon} alt="" className='img-fluid' /></span> <span className='contentSpan'>High- quality content</span></p>
                            <p className='iconAndContentPara'><span><img src={OKIcon} alt="" className='img-fluid' /></span> <span className='contentSpan'>More leads & conversions</span></p>
                            <p className='iconAndContentPara'><span><img src={OKIcon} alt="" className='img-fluid' /></span> <span className='contentSpan'>Improved search engine rankings</span></p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Fourth Section Start Here */}
            <HowDoWe />

            <section className="fifthSectionOfCategoriePageStartHere">
                <div className="container fifthSectionOfCategoriesPageFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12 videoDivInCategoriePage">
                            <img src={Video} alt="Video" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq-on-about-us-page-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 faq-comp-heading-and-para-div">
                            <h2>Frequently asked questions</h2>
                            <p>Everything you need to know about the product and billing.</p>
                        </div>
                        <div className="col-md-9 faq-comp-main-div">
                            <FAQONCategoriesPage />
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="sixthSectionOfCategoriePageStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 getInTouchFormInputAndBtnDiv">
                            <h2>Get in touch with a Wordsmith of our team</h2>
                            <SubscribeForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CategoriesPage;