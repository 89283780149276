import React, { useState } from 'react';
import "../../Component/CSS/FAQOnAboutUs.css";
import OnLandingPage from '../../Data/FAQ/OnLandingPage';

const FAQONLandingPage = () => {
    const [showAnswer, setShowAnswer] = useState(1);
    const showAnswerDiv = (e) => {
        setShowAnswer(e);
    }
    return (
        <>
            <div className="faq-comp-first-inner-div">
                {
                    OnLandingPage.map((items) => {
                        return (
                            <div className="faq-div-start-here first-faq" key={items.id}>
                                <div className="faq-question" onClick={() => showAnswerDiv(items.id)}>
                                    <h4>{items.question}</h4>
                                    <h4 className='ms-3'>{showAnswer === items.id ? items.minusDashCircle : items.plusDashCircle}</h4>
                                </div>
                                <p className={showAnswer === items.id ? "faq-answer show" : "faq-answer"}>{items.answer}</p>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default FAQONLandingPage;