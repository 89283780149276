import React, { useState } from 'react';
import { FaFacebook, FaLink, FaLinkedin, FaTwitter } from "react-icons/fa";
import "../CSS/BlogHeadingAndSMIcon.css";
// import { BsLink45Deg } from "react-icons/bs";

const BlogHeadingAndSMIcon = () => {
    const [isCopied, setIsCopied] = useState(false);
    const url = window.location.href;

    function copyToClipboard () {
        let copyUrl = navigator.clipboard.writeText(window.location.href);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false)
        }, 2000);
        // console.log(copyUrl)
    }
    // function ShareWebAPI() {
    //     if (navigator.share) {
    //       navigator.share({
    //         //   title: description,
    //           url: url
    //           })
    //           .catch(err => alert("Error Sharing: " + err))
    //     }
    //   }
    return (
        <>
            <div className="col-md-3 socialMediaIconOnTop">
                <ul>
                    {/* <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target='_blank' rel='noopener noreferrer'><FaFacebook /></a></li> */}
                    <li onClick={() => window.open(`https://www.facebook.com/login`)}><i><FaFacebook /></i></li>
                    <li onClick={() => window.open(`https://twitter.com/intent/tweet?url=${url}&via=bemywordsmith`)}><i><FaTwitter /></i></li>
                    <li onClick={() => window.open(`https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin`)}><i><FaLinkedin /></i></li>
                    {/* <li onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}`)}><i><FaLinkedin /></i></li> */}
                    {/* <li onClick={() => window.open('https://www.instagram.com/')}><i><AiFillInstagram /></i></li> */}
                    <li onClick={copyToClipboard}><i><FaLink /></i></li>
                    {/* <li><a href="/" className="href"><BsLink45Deg /></a></li> */}
                </ul>
            </div>
            {
                isCopied ? 
                <>
                 <div className="showCopiedContent">
                    <p>Link Copied</p>
                </div>
                </> : ""
            }
        </>
    )
}

export default BlogHeadingAndSMIcon;