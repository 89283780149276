import React from 'react';
// import { PostCommentContext } from './BlogPostComment';
import CommentIcon from "../../../../Image/Blogs/Blog_Comment_Div/Comment_Icon.png";

const BlogCommentsReply = ({post}) => {
    // const post = useContext(PostCommentContext);
    const postReply = post.map((user, key) => {
        // console.log(user)
        return(
            <div className="comment-reply-first-inner-div mb-3" key={key}>
                    <div className="user-icon-div">
                        <img src={CommentIcon} alt="ICON" />
                    </div>
                    <div className="user-comments-div ms-3">
                        <p className='users-name'>{user.data.name}</p>
                        <p className='users-comment'>{user.data.comment}</p>
                    </div>
                </div>
        )
    })
  return (
    <>
    {postReply}
    </>
  )
}

export default BlogCommentsReply