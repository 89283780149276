import React from 'react';
import { ourBlogs } from '../../../../Data/Blogs/OurBlogsData';
import "../Main_File/BlogsPage.css";

const PopularArticles = () => {
    const randomBlogDetailsOnBlogPageNumFifteen = Math.floor(Math.random() * ourBlogs.length);
    const randomBlogDetailsOnBlogPageNumSixteen = Math.floor(Math.random() * ourBlogs.length);
    const randomBlogDetailsOnBlogPageNumSeventeen = Math.floor(Math.random() * ourBlogs.length);
    const randomBlogDetailsOnBlogPageNumEighteen = Math.floor(Math.random() * ourBlogs.length);
    return (
        <>
            <div className='popularArticlesHeading'>
                <h3>Popular Articles</h3>
            </div>
            <div className="firstTwoAlsoReadCard">
                <div className='alsoReadBlogsImageAndParaDiv'>
                    <img src={ourBlogs[randomBlogDetailsOnBlogPageNumFifteen].blogImage} alt="Read More Blogs" className='img-fluid' />
                    <div className="also-read-para-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumFifteen].title}</p>
                    </div>
                </div>
                <div className='alsoReadBlogsImageAndParaDiv'>
                    <img src={ourBlogs[randomBlogDetailsOnBlogPageNumSixteen].blogImage} alt="Read More Blogs" className='img-fluid' />
                    <div className="also-read-para-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumSixteen].title}</p>
                    </div>
                </div>
            </div>
            <div className="firstTwoAlsoReadCard">
                <div className='alsoReadBlogsImageAndParaDiv'>
                    <img src={ourBlogs[randomBlogDetailsOnBlogPageNumSeventeen].blogImage} alt="Read More Blogs" className='img-fluid' />
                    <div className="also-read-para-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumSeventeen].title}</p>
                    </div>
                </div>
                <div className='alsoReadBlogsImageAndParaDiv'>
                    <img src={ourBlogs[randomBlogDetailsOnBlogPageNumEighteen].blogImage} alt="Read More Blogs" className='img-fluid' />
                    <div className="also-read-para-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumEighteen].title}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopularArticles;