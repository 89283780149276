import React from 'react';
import { FiArrowLeftCircle } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Error404 from "../../Image/Page_Not_Found/Page_404.png";
import "../CSS/PageNotFound404.css";

const PageNotFound404 = () => {
  return (
    <>
    <section className="page-not-found-section-start-here">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 page-not-found-image-div">
                    <img src={Error404} alt="Content Writing Services in India | Be My Wordsmith" className='img-fluid' />
                </div>
                <div className="col-md-12 page-not-found-button-div">
                    <Link to={"/"}><button type='button'><span><FiArrowLeftCircle /></span> Back to home</button></Link>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default PageNotFound404