import React from 'react';
import { ourBlogs } from '../../Data/Blogs/OurBlogsData';
import "../CSS/ReadOurPopularBlogs.css";

const ReadOurPopularBlogs = () => {
    const ReadRandomBlogsOne = Math.floor(Math.random() * ourBlogs.length);
    const ReadRandomBlogsTwo = Math.floor(Math.random() * ourBlogs.length);
    const ReadRandomBlogsThree = Math.floor(Math.random() * ourBlogs.length);
    return (
        <>
            <section className="sixthSectionOfBlogsAndArticlesPageStartHere">
                <div className="container sixthSectionOfBlogsAndArticlesFirstInnerDiv">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 readOurPopularBlogsHeading">
                            <p>Read our</p>
                            <h6>Popular blogs</h6>
                        </div>
                        <div className="col-md-4 mb-4 col-sm-6 readOurBlogsCardDiv">
                            <div className="cardImgHeadingParaAndButtonBlogInnerDiv">
                                <img src={ourBlogs[ReadRandomBlogsOne].blogImage} alt="Blog" className='img-fluid' />
                                <div className="blogsCardParaAndHeading">
                                    <h6>{ourBlogs[ReadRandomBlogsOne].title}</h6>
                                    <p className='readOurBlogsPara'>{ourBlogs[ReadRandomBlogsOne].description.slice(0, 95)}...</p>
                                    <p className="buttonPara">{ourBlogs[ReadRandomBlogsOne].newPage}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 col-sm-6 readOurBlogsCardDiv">
                            <div className="cardImgHeadingParaAndButtonBlogInnerDiv">
                                <img src={ourBlogs[ReadRandomBlogsTwo].blogImage} alt="Blog" className='img-fluid' />
                                <div className="blogsCardParaAndHeading">
                                    <h6>{ourBlogs[ReadRandomBlogsTwo].title}</h6>
                                    <p className='readOurBlogsPara'>{ourBlogs[ReadRandomBlogsTwo].description.slice(0, 95)}...</p>
                                    <p className="buttonPara">{ourBlogs[ReadRandomBlogsTwo].newPage}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 col-sm-6 readOurBlogsCardDiv">
                            <div className="cardImgHeadingParaAndButtonBlogInnerDiv">
                                <img src={ourBlogs[ReadRandomBlogsThree].blogImage} alt="Blog" className='img-fluid' />
                                <div className="blogsCardParaAndHeading">
                                    <h6>{ourBlogs[ReadRandomBlogsThree].title}</h6>
                                    <p className='readOurBlogsPara'>{ourBlogs[ReadRandomBlogsThree].description.slice(0, 95)}...</p>
                                    <p className="buttonPara">{ourBlogs[ReadRandomBlogsThree].newPage}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReadOurPopularBlogs;