


export const HowDoWeData = [
    {
        id : 1,
        cardImage : require('../../Image/Categories_Page/How_Do_We/high_quality.png'),
        title : "High Quality",
        desc : "Your success, our commitment. High-quality content solutions—crafted to captivate, inform, and elevate your brand",
        altTag: "High Quality"
    },
    {
        id : 2,
        cardImage : require('../../Image/Categories_Page/How_Do_We/plagiarism.png'),
        title : "Plagiarism free",
        desc : "Experience worry-free supremacy. Our content is meticulously crafted, ensuring it's plagiarism-free and tailored to enhance your brand with integrity",
        altTag: "Plagiarism free"
    },
    {
        id : 3,
        cardImage : require('../../Image/Categories_Page/How_Do_We/original.png'),
        title : "100% original",
        desc : "Soak your brand in authenticity. Our content is 100% original—crafted uniquely amplify your message",
        altTag: "100% original"
    },
    {
        id : 4,
        cardImage : require('../../Image/Categories_Page/How_Do_We/experienced.png'),
        title : "Experienced writers",
        desc : "Content is taken to next level with seasoned expertise. Our experienced writers bring creativity and proficiency to showcase your brand's distinct narrative flawlessly.",
        altTag: "Experienced writers"
    },
    {
        id : 5,
        cardImage : require('../../Image/Categories_Page/How_Do_We/delivery.png'),
        title : "On-time delivery",
        desc : "Reliable content, every time. Count on us for on-time delivery of creatively crafted content that aligns perfectly with your schedule and needs ",
        altTag: "On-time delivery"
    },
    {
        id : 6,
        cardImage : require('../../Image/Categories_Page/How_Do_We/ideation.png'),
        title : "Ideation",
        desc : "Fueling innovation through strategic ideation. Our content creators infuse brilliance into every piece, shaping narratives that resonate and attractive ",
        altTag: "Ideation"
    }
];