import React from 'react';
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import TestimonialsData from '../../Data/TestimonialData/TestimonialsData';
import "./Testimonial.css";

const TestimonialComponent = () => {
    return (
        <>
            <div className="carouselContainerDiv">
                <Swiper
                    cssMode={true}
                    navigation={true}
                    mousewheel={true}
                    keyboard={true}
                    slidesPerView={2}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        767: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        924: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        925: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        992: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper"
                >
                    {
                        TestimonialsData.map((items) => {
                            return (
                                <SwiperSlide>
                                    <div className="carouselItem" key={items.id}>
                                        {/* <img src={Image1} alt="Image 2" className='img-fluid' /> */}
                                        <div className="carousel-heading-and-para">
                                            <p className="carouselTestimonial">
                                                {items.testimonial}
                                            </p>
                                            <h4 className="testimonialName">{items.name}</h4>
                                            {/* <p className='testimonialCompany'>{items.name}</p> */}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                    {/* <SwiperSlide>
                        <div className="carouselItem">
                            <img src={Image1} alt="Image 2" className='img-fluid' />
                            <div className="carousel-heading-and-para">
                                <p className="carouselTestimonial">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                                <h4 className="testimonialName">John Smith</h4>
                                <p className='testimonialCompany'>Tiffany enterprises</p>
                            </div>
                        </div>
                    </SwiperSlide> */}
                </Swiper>
            </div>
        </>
    )
}

export default TestimonialComponent;