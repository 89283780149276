import React from 'react'
import { Helmet } from 'react-helmet'
import { ourBlogs } from '../../../../Data/Blogs/OurBlogsData'
import PopularBlogs from "../../Blog_Page_Component/JS/PopularBlogs"
import AlsoRead from '../Blog_Page_Component/AlsoRead'
import PopularArticles from '../Blog_Page_Component/PopularArticles'
import "../Industry_Blogs/ChooseIndustry.css"

const TravelIndustry = () => {
    let newBlogArray = [];
    for (let i = ourBlogs.length - 1; i >= 0; i--) {
        if (ourBlogs[i].category === "Travel") {
            newBlogArray.push(ourBlogs[i])
        }
    }
    // console.log(newBlogArray)
    return (
        <>
            <Helmet>
                <title>Blogs | Travel | Be My Wordsmith</title>
                <meta name="description" content="Dive into the festive spirit with BeMyWordsmith's captivating blogs. Explore insights, traditions, and celebrations around the world. Immerse yourself in the joy of festivals with our engaging and informative content, sharing stories that highlight the cultural richness and diversity of celebrations globally." />
                <link rel="canonical" href="https://www.bemywordsmith.com/blogs/travel" />
            </Helmet>
            <section className="categoryBlogsSectionStartHere">
                <div className="container-fluid categoryBlogsSectionFirstInnerDiv">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 categoryBlogsHeadingDiv">
                            <h2>Festival Blogs</h2>
                        </div>
                        <div className="col-lg-7 categoryBlogsDiv">
                            {
                                newBlogArray.map((items) => {
                                    return (
                                        <div key={items.id}>
                                            <div className="categoryBlogRightCardInnerDiv categoryBlogRightCardForBelowLine">
                                                <div className="cartegoryBlogImgDiv">
                                                    <img src={items.blogImage} alt="" className='img-fluid' />
                                                </div>
                                                <div className="categoryBlogCardHeadingAndPara ms-4">
                                                    <p className='categoryBlogCardAuthorNameAndDate'>{items.author} | {items.date}</p>
                                                    <h3>{items.title}</h3>
                                                    <p className='categoryBlogCardPara'>{items.description.slice(0, 111)}...</p>
                                                    <p className='categoryBlogReadMoreBtn'>{items.newPage}</p>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-lg-4 categoryBlogAlsoReadAndPopularArticlesDiv">
                            <div className="alsoReadBlogDiv">
                                <AlsoRead />
                            </div>
                            <div className="popularArticlesBlogDiv">
                                <PopularArticles />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PopularBlogs />
        </>
    )
}

export default TravelIndustry;