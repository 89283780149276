import React from 'react';
import { Helmet } from 'react-helmet';
import { BsCheckSquare } from "react-icons/bs";
import { Link } from 'react-router-dom';
import CategoriesServicesForm from '../../Component/JS/CategoriesServicesForm';
import ReadOurPopularBlogs from '../../Component/JS/ReadOurPopularBlogs';
import MainTestimonial from '../../Component/Testimonial/MainTestimonial';
import BenefitsWeProvide from "../../Image/BMW_Landing_Page/Benefits-of-Content-Writing-Agency-in-Bangalore.webp";
import BlogsAndArticles from "../../Image/BMW_Landing_Page/Blog-&-Article-Writing.webp";
import BusinessesImage from "../../Image/BMW_Landing_Page/Content-Writing-Services-For-Businesses-in-Bangalore.webp";
import ClickICON from "../../Image/BMW_Landing_Page/ICONS/Check.png";
import MailICON from "../../Image/BMW_Landing_Page/ICONS/Email.png";
import PhoneICON from "../../Image/BMW_Landing_Page/ICONS/Phone.png";
import ProductDescription from "../../Image/BMW_Landing_Page/Product-Description-Writing.webp";
import ServicesDoWeProvide from "../../Image/BMW_Landing_Page/SEO-Content-Writers.webp";
import WebsiteContentWriting from "../../Image/BMW_Landing_Page/Website-Content-Writing.webp";
import Price from "../../Image/BMW_Landing_Page/Why-Choose-Us/Affordable-Price.png";
import Writers from "../../Image/BMW_Landing_Page/Why-Choose-Us/Expert-Writers.svg";
import Delivery from "../../Image/BMW_Landing_Page/Why-Choose-Us/On-Time-Delivery.svg";
import Original from "../../Image/BMW_Landing_Page/Why-Choose-Us/Original-Content.png";
import SEO from "../../Image/BMW_Landing_Page/Why-Choose-Us/SEO-Optimized-Content.svg";
import Researched from "../../Image/BMW_Landing_Page/Why-Choose-Us/Well-Researched.svg";
import "../CSS/BMWLandingPage.css";
import "../CSS/LandingPage.css";
import CarouselSlider from '../Categories/Categories_Components/JS/CarouselSlider';
import ContentDeliveryProcess from '../Landing_Page_Component/ContentDeliveryProcess';
import FAQOnMainLandingPage from '../Landing_Page_Component/FAQOnMainLandingPage';

const BMWLandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Content Writing Services in Bangalore</title>
                <meta name="description" content="Seeking to optimize your website content for increased conversion rates? Our exceptional website content writing services in Bangalore are designed to breathe new life into your existing pages or craft compelling new ones from scratch." />
                <link rel="canonical" href="https://www.bemywordsmith.com/content-writing-services-in-bangalore" />
            </Helmet>
            <section className="banner-section-content-and-form-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-evenly">
                        <div className="col-lg-7 banner-section-heading-and-para-div">
                            <h1>Content Writing Services in Bangalore</h1>
                            <p>Be My Wordsmith provides the best content writing services in Bangalore. Elevate your brand with our expert writers, who craft compelling content to boost your online presence and drive growth.</p>
                            <Link to={"/contact-us"}><button className='call-to-action-btn'>Contact Us Now</button></Link>
                        </div>
                        <div className="col-lg-5 banner-section-form-div">
                            <CategoriesServicesForm />
                        </div>
                    </div>
                </div>
            </section>
            <section className="social-statistics-section-start-here">
                <div className="container social-statistics-section-first-inner-div">
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-sm-4 social-statistics-card text-center">
                            <h2>200+</h2>
                            <p>Professional Writers</p>
                        </div>
                        <div className="col-md-4 col-sm-4 social-statistics-card text-center">
                            <h2>2 Million+</h2>
                            <p>Words Written</p>
                        </div>
                        <div className="col-md-4 col-sm-4 social-statistics-card text-center">
                            <h2>99%</h2>
                            <p>Success Rate</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="work-samples-section-start-here">
                <CarouselSlider />
            </section>
            <section className="first-image-and-content-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 first-image-and-content-heading-div">
                            <p>Content Writing Services</p>
                            <h6>For Businesses in Bangalore</h6>
                        </div>
                        <div className="col-lg-5 first-image-div">
                            <img src={BusinessesImage} alt="Content Writing Services For Businesses in Bangalore" className='img-fluid' />
                        </div>
                        <div className="col-lg-7 first-content-div">
                            <p>Unlock the potential of your business with our tailored <b>Content Writing Services in Bangalore</b>. At Be My Wordsmith, we understand the pivotal role of content in shaping brand identity and driving growth. Our team of seasoned writers specializes in crafting compelling content across various platforms, including websites, blogs, and <Link to={"/categories/product-description-content-writing-services-in-india"}>product descriptions</Link>.</p>
                            <p>In today's competitive landscape, standing out is essential. Whether you're a startup looking to establish a strong online presence or an established enterprise aiming to expand your reach, our Bangalore based Content Writing Company is designed to meet your unique needs and propel your business forward.</p>
                            <p>Partner with us to unleash the power of words and elevate your brand to new heights. We deliver content that not only resonates with your audience but also drives tangible results for your business. Experience the difference with our <Link to={"/about-us"}>Content Writing Agency</Link> and embark on a journey of growth and success.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content-email-phone-and-call-to-action-button-section-start-here">
                <div className="container content-email-phone-and-call-to-action-button-first-inner-div">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 call-to-action-content-icon-div">
                            <p>Elevate your business with Be My Wordsmith's <b>expert content writers</b>. Contact us now to get the best content writing services in Bangalore from us.</p>
                            <p className='icon-and-para'><span className='png-icon'><img src={PhoneICON} alt="" className='img-fluid' /></span><span>+91 99721 31405</span></p>
                            <p className='icon-and-para'><span className='png-icon'><img src={MailICON} alt="" className='img-fluid' /></span><span>bemywordsmith@gmail.com</span></p>
                        </div>
                        <div className="col-lg-4 call-to-action-button-div">
                            <Link to={"/contact-us"}><button className='call-to-action-btn'>Order Now</button></Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-services-section-start-here">
                <div className="container our-services-section-first-inner-div">
                    <div className="row justify-content-center">
                        <div className="col-md-12 our-services-heading-div">
                            <p>What Content Writing Services</p>
                            <h6>Do We Provide?</h6>
                        </div>
                        <div className="col-md-6 our-services-heading-and-content-div">
                            <h3>Website Content</h3>
                            <p>Elevate your online presence with our effective <b>website content writing solutions in Bangalore</b>. Tailored to showcase your brand's uniqueness, our writing captivates and engages your audience effectively.</p>
                        </div>
                        <div className="col-md-6 our-services-image-div">
                            <img src={WebsiteContentWriting} alt="Website Content Writing Services" className='img-fluid' />
                        </div>
                        <div className="col-md-6 our-services-image-div">
                            <img src={BlogsAndArticles} alt="Blogs & Articles Writing Services" className='img-fluid' />
                        </div>
                        <div className="col-md-6 our-services-heading-and-content-div">
                            <h3>Blogs & Articles</h3>
                            <p>Fuel your digital presence with dynamic storytelling. With a combination of creativity and experience, our <b>blog and article writing services in Bangalore</b> give your brand more authority while increasing online visibility and driving engagement.</p>
                        </div>
                        <div className="col-md-6 our-services-heading-and-content-div">
                            <h3>Product Description</h3>
                            <p>Transform products into experiences. Our <Link to={"/categories"}>writing services</Link> for product descriptions go beyond features to create an engaging story that entices customers. Elevate your e-commerce game with captivating and informative product content.</p>
                        </div>
                        <div className="col-md-6 our-services-image-div">
                            <img src={ProductDescription} alt="Product Description Writing Services" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="second-image-and-content-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 second-image-and-content-heading-div">
                            <p>How can the Professional SEO</p>
                            <h6>Content Writers at Be My Wordsmith help you grow your business?</h6>
                        </div>
                        <div className="col-lg-5 second-image-div">
                            <img src={ServicesDoWeProvide} alt="Content Writing Services in Bangalore" className='img-fluid' />
                        </div>
                        <div className="col-lg-7 second-content-div">
                            <p>At Be My Wordsmith, our <b>professional SEO content writers</b> are your key partners in fostering business growth through compelling and optimized content. We craft content that not only engages your audience but also ranks high in search results. Our expert writers tailor each piece to align with your brand voice, ensuring a seamless blend of creativity and SEO strategy.</p>
                            <p>In the digital landscape, engaging website content is paramount. We specialize in creating SEO-rich blogs & articles, <Link to={"/categories/best-website-content-writing-services-in-bangalore"}>website content</Link>, and marketing collateral that not only attracts organic traffic but also converts leads into loyal customers. With a focus on staying ahead of industry trends, our dedicated writing team empowers your business to navigate the dynamic digital landscape successfully.</p>
                            <p>Experience the impact of strategic content with the best <b>content writing services in Bangalore</b> – where every word is crafted to elevate your brand, amplify your message, and drive sustained business growth.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="industries-we-serve-section-start-here">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 industries-we-serve-div">
                            <p>Industries</p>
                            <h6>We cater to</h6>
                        </div>
                        <div className="col-lg-4 col-sm-6 industry-name-div">
                            <p><span className='click-icon'><img src={ClickICON} alt="" className='img-fluid' /></span><span><Link to={"/blogs/human-resource"}>Human Resource</Link></span></p>
                        </div>
                        <div className="col-lg-4 col-sm-6 industry-name-div">
                            <p><span className='click-icon'><img src={ClickICON} alt="" className='img-fluid' /></span><span><Link to={"/blogs/digital-marketing"}>Digital Marketing</Link></span></p>
                        </div>
                        <div className="col-lg-4 col-sm-6 industry-name-div">
                            <p><span className='click-icon'><img src={ClickICON} alt="" className='img-fluid' /></span><span><Link to={"/blogs/lifestyle"}>Lifestyle</Link></span></p>
                        </div>
                        <div className="col-lg-4 col-sm-6 industry-name-div">
                            <p><span className='click-icon'><img src={ClickICON} alt="" className='img-fluid' /></span><span><Link to={"/blogs/finance"}>Finance</Link></span></p>
                        </div>
                        <div className="col-lg-4 col-sm-6 industry-name-div">
                            <p><span className='click-icon'><img src={ClickICON} alt="" className='img-fluid' /></span><span><Link to={"/blogs/travel"}>Travel</Link></span></p>
                        </div>
                        <div className="col-lg-4 col-sm-6 industry-name-div">
                            <p><span className='click-icon'><img src={ClickICON} alt="" className='img-fluid' /></span><span><Link to={"/"}>IT & Software</Link></span></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-choose-us-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 why-choose-us-heading-div">
                            <p>Why Choose Be My Wordsmith's</p>
                            <h6>Content Writing Services in Bangalore?</h6>
                        </div>
                        <div className="col-lg-4 col-sm-6 why-choose-us-image-heading-and-para-div">
                            <div className="why-choose-us-image-heading-and-para-first-inner-div">
                                <img src={Writers} alt="" className='img-fluid' />
                                <h3>Expert Writers</h3>
                                <p>With their extensive industry experience, Be My Wordsmith's seasoned content writers can ensure the best services to elevate your brand.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 why-choose-us-image-heading-and-para-div">
                            <div className="why-choose-us-image-heading-and-para-first-inner-div">
                                <img src={SEO} alt="" className='img-fluid' />
                                <h3>SEO Optimized Content</h3>
                                <p>Increase exposure and interaction with our SEO-optimized content that is designed to rank well in search results.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 why-choose-us-image-heading-and-para-div">
                            <div className="why-choose-us-image-heading-and-para-first-inner-div">
                                <img src={Researched} alt="" className='img-fluid' />
                                <h3>Well Researched</h3>
                                <p>Immerse your audience in compelling narratives - our content writing firm provides depth through thorough research.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 why-choose-us-image-heading-and-para-div">
                            <div className="why-choose-us-image-heading-and-para-first-inner-div">
                                <img src={Original} alt="" className='img-fluid' />
                                <h3>100% Original Content</h3>
                                <p>Experience the uniqueness - our Bangalore based content writing services deliver content that is specially created for you.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 why-choose-us-image-heading-and-para-div">
                            <div className="why-choose-us-image-heading-and-para-first-inner-div">
                                <img src={Price} alt="" className='img-fluid' />
                                <h3>Affordable Price</h3>
                                <p>Quality meets affordability. Experience the <Link to={"/contact-us"}>best writing solutions</Link> at a very reasonable cost.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 why-choose-us-image-heading-and-para-div">
                            <div className="why-choose-us-image-heading-and-para-first-inner-div">
                                <img src={Delivery} alt="" className='img-fluid' />
                                <h3>On-Time Delivery</h3>
                                <p>We always place a high priority on timely delivery. Being late is something no one enjoys, so at Be My Wordsmith, we make sure to deliver the best writing services on time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="benfits-of-agency-image-and-content-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 benfits-of-agency-image-and-content-heading-div">
                            <p>Benefits of</p>
                            <h6>Content Writing Agency in Bangalore</h6>
                        </div>
                        <div className="col-lg-7 benfits-of-agency-content-div">
                            <ul>
                                <li><span><BsCheckSquare /></span><b>Professional Content</b>: Delivering polished and professional content that aligns with your brand's tone and values.</li>
                                <li><span><BsCheckSquare /></span><b>Time-Efficient Solutions</b>: Free up your time by outsourcing content creation, allowing you to focus on core business activities.</li>
                                <li><span><BsCheckSquare /></span><b>SEO Optimization</b>: Enhance online visibility with SEO-optimized content, driving organic traffic to your platforms.</li>
                                <li><span><BsCheckSquare /></span><b>Cost-Effective Services</b>: Enjoy cost-effective content creation compared to the expenses of an in-house team.</li>
                                <li><span><BsCheckSquare /></span><b>Versatile Content</b>: Explore a variety of content types, from blogs to social media posts, showcasing flexibility.</li>
                                <li><span><BsCheckSquare /></span><b>Industry Expertise</b>: Leverage the expertise of professionals with experience across diverse industries.</li>
                                <li><span><BsCheckSquare /></span><b>Consistent Brand Voice</b>: Maintain a consistent brand voice and messaging strategy throughout your content.</li>
                                <li><span><BsCheckSquare /></span><b>Tailored Solutions</b>: Receive personalized content solutions catering to your specific business needs and target audience.</li>
                            </ul>
                        </div>
                        <div className="col-lg-5 benfits-of-agency-image-div">
                            <img src={BenefitsWeProvide} alt="Content Writing Services in Bangalore" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonial-section-start-here">
                <MainTestimonial />
            </section>
            <section className='how-we-work-section-start-here'>
                <ContentDeliveryProcess />
            </section>
            <section className="our-sample-work-section-start-here">
                <ReadOurPopularBlogs />
            </section>
            <section className="faq-on-main-landing-page-page-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 faq-comp-heading-and-para-div">
                            <h2>Frequently asked questions</h2>
                            <p>Everything you need to know about the product and billing.</p>
                        </div>
                        <div className="col-md-9 faq-comp-main-div">
                            <FAQOnMainLandingPage />
                        </div>
                    </div>
                </div>
            </section>
            <section className="last-heading-para-and-button-section-start-here">
                <div className="container last-heading-para-and-button-section-first-inner-div">
                    <div className="row justify-content-center">
                        <div className="col-md-12 last-heading-para-and-button-div">
                            <h2>Want to elevate your business with</h2>
                            <h2>Be My Wordsmith's <span>content writing services?</span></h2>
                            <p>Order your first content in 2 minutes</p>
                            <Link to={"/contact-us"}><button>Order Now</button></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BMWLandingPage;