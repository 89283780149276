import React from 'react';
import { ourBlogs } from '../../../../Data/Blogs/OurBlogsData';
import "../Main_File/BlogsPage.css";

const AlsoRead = () => {
    const randomBlogDetailsOnBlogPageNumEleven = Math.floor(Math.random() * ourBlogs.length);
    const randomBlogDetailsOnBlogPageNumTwelve = Math.floor(Math.random() * ourBlogs.length);
    const randomBlogDetailsOnBlogPageNumThirteen = Math.floor(Math.random() * ourBlogs.length);
    const randomBlogDetailsOnBlogPageNumFourteen = Math.floor(Math.random() * ourBlogs.length);
    return (
        <>
            <div className='alsoReadHeading'>
                <h3>Also Read</h3>
            </div>
            <div className="firstTwoAlsoReadCard">
                <div className='alsoReadBlogsImageAndParaDiv'>
                    <img src={ourBlogs[randomBlogDetailsOnBlogPageNumEleven].blogImage} alt="Read More Blogs" className='img-fluid' />
                    <div className="also-read-para-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumEleven].title}</p>
                    </div>
                </div>
                <div className='alsoReadBlogsImageAndParaDiv'>
                    <img src={ourBlogs[randomBlogDetailsOnBlogPageNumTwelve].blogImage} alt="Read More Blogs" className='img-fluid' />
                    <div className="also-read-para-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumTwelve].title}</p>
                    </div>
                </div>
            </div>
            <div className="firstTwoAlsoReadCard">
                <div className='alsoReadBlogsImageAndParaDiv'>
                    <img src={ourBlogs[randomBlogDetailsOnBlogPageNumThirteen].blogImage} alt="Read More Blogs" className='img-fluid' />
                    <div className="also-read-para-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumThirteen].title}</p>
                    </div>
                </div>
                <div className='alsoReadBlogsImageAndParaDiv'>
                    <img src={ourBlogs[randomBlogDetailsOnBlogPageNumFourteen].blogImage} alt="Read More Blogs" className='img-fluid' />
                    <div className="also-read-para-div">
                    <p className='ms-3'>{ourBlogs[randomBlogDetailsOnBlogPageNumFourteen].title}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AlsoRead;