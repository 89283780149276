import React from 'react';
import BlogsIconImg from "../../../Image/Categories_Page/Product_Description/Cater to Buyer Personas.png";
import CreatorImg from "../../../Image/Company/2-8.webp";
import SocialIconImg from "../../../Image/Company/LinkedIn_Icon.png";
import "../CSS/OurCreatorIndividual.css";

const OurCreatorIpsita = () => {
    return (
        <>
            <section className="our-creator-individual-page-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-flex-start">
                        <div className="col-md-12 our-creator-individual-page-heading-div">
                            <p>Our</p>
                            <h6>Creator</h6>
                        </div>
                        <div className="col-lg-5 col-12 our-creator-about-me-div">
                            <div className="our-creator-about-me-first-inner-div">
                                <div className="image-div">
                                    <img src={CreatorImg} alt="" className='img-fluid' />
                                </div>
                                <div className="creator-name-div">
                                    <h3>Ipsita P</h3>
                                </div>
                                <div className="creator-desig-and-social-icon-div">
                                    <p>HR Executive</p>
                                    <img src={SocialIconImg} alt="" className='img-fluid' />
                                </div>
                                <div className="creator-para-div">
                                    <p>Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque.Curabitur tempus urna at turpis condimentum.Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur. Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque.Curabitur tempus urna at turpis condimentum.Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 our-creator-blogs-and-industries-div">
                            <div className="container-fluid">
                                <div className="row justify-flex-start">
                                    <div className="col-md-12 our-creator-blogs-heading-div">
                                        <h2>Blogs</h2>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 our-creator-blogs-icon-heading-and-para-div">
                                        <div className="our-creator-blogs-icon-heading-and-para-first-inner-div">
                                            <img src={BlogsIconImg} alt="" className='img-fluid' />
                                            <h4>Cater to Buyer Personas</h4>
                                            <p>e-commerce product Packaging content, Label content</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 our-creator-blogs-icon-heading-and-para-div">
                                        <div className="our-creator-blogs-icon-heading-and-para-first-inner-div">
                                            <img src={BlogsIconImg} alt="" className='img-fluid' />
                                            <h4>Cater to Buyer Personas</h4>
                                            <p>e-commerce product Packaging content, Label content</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 our-creator-blogs-icon-heading-and-para-div">
                                        <div className="our-creator-blogs-icon-heading-and-para-first-inner-div">
                                            <img src={BlogsIconImg} alt="" className='img-fluid' />
                                            <h4>Cater to Buyer Personas</h4>
                                            <p>e-commerce product Packaging content, Label content</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 our-creator-blogs-icon-heading-and-para-div">
                                        <div className="our-creator-blogs-icon-heading-and-para-first-inner-div">
                                            <img src={BlogsIconImg} alt="" className='img-fluid' />
                                            <h4>Cater to Buyer Personas</h4>
                                            <p>e-commerce product Packaging content, Label content</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row justify-flex-start">
                                    <div className="col-md-12 our-creator-blogs-heading-div">
                                        <h2>Categories</h2>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 our-creator-blogs-icon-heading-and-para-div">
                                        <div className="our-creator-blogs-icon-heading-and-para-first-inner-div">
                                            <img src={BlogsIconImg} alt="" className='img-fluid' />
                                            <h4>Cater to Buyer Personas</h4>
                                            <p>e-commerce product Packaging content, Label content</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 our-creator-blogs-icon-heading-and-para-div">
                                        <div className="our-creator-blogs-icon-heading-and-para-first-inner-div">
                                            <img src={BlogsIconImg} alt="" className='img-fluid' />
                                            <h4>Cater to Buyer Personas</h4>
                                            <p>e-commerce product Packaging content, Label content</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 our-creator-blogs-icon-heading-and-para-div">
                                        <div className="our-creator-blogs-icon-heading-and-para-first-inner-div">
                                            <img src={BlogsIconImg} alt="" className='img-fluid' />
                                            <h4>Cater to Buyer Personas</h4>
                                            <p>e-commerce product Packaging content, Label content</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12 our-creator-blogs-icon-heading-and-para-div">
                                        <div className="our-creator-blogs-icon-heading-and-para-first-inner-div">
                                            <img src={BlogsIconImg} alt="" className='img-fluid' />
                                            <h4>Cater to Buyer Personas</h4>
                                            <p>e-commerce product Packaging content, Label content</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurCreatorIpsita;