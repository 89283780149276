import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import FooterLogo from "../../Image/Footer/Footer_Logo.png";
import "../CSS/Footer.css";

const Footer = () => {
    return (
        <>
            <section className="footerSectionStartHere">
                <div className="container-fluid footerSectionFirstInnerDiv">
                    <div className="row justify-content-evenly footerSectionSecondInnerDiv">
                        <div className="col-md-4 footerLogoAndSocialMediaIcon">
                            <NavLink to={"/"} className="href"><img src={FooterLogo} alt="Be My Wordsmith" className='img-fluid footerLogoImg' /></NavLink>
                            <ul>
                                <li><a href="https://www.facebook.com/people/Be-My-Wordsmith/61551623284321/" className="href" target='_blank' rel='noopener noreferrer'><i className='facebookImg'><FaFacebookF /></i></a></li>
                                <li><a href="https://www.instagram.com/bemywordsmith/" className="href" target='_blank' rel='noopener noreferrer'><i><FaInstagram /></i></a></li>
                                <li><a href="https://twitter.com/bemywordsmith" className="href" target='_blank' rel='noopener noreferrer'><i><FaTwitter /></i></a></li>
                                <li><a href="https://www.linkedin.com/showcase/be-my-wordsmith/?viewAsMember=true" className="href" target='_blank' rel='noopener noreferrer'><i><FaLinkedinIn /></i></a></li>
                            </ul>
                        </div>
                        <div className="col-md-8 footerSectionCompanysServices">
                            <div className="services-company">
                            <div className="services">
                                <h5>Services</h5>
                                <p><NavLink to={"/categories/best-website-content-writing-services-in-bangalore"} className="href">Website Content</NavLink></p>
                                <p><NavLink to={"/categories/best-blog-&-article-writing-services-in-india"} className="href">Blogs &amp; Articles</NavLink></p>
                                <p><NavLink to={"/categories/product-description-content-writing-services-in-india"} className="href">Product description</NavLink></p>
                            </div>
                            <div className="company">
                                <h5>Company</h5>
                                <p><NavLink to={"/contact-us"} className="href">Contact us</NavLink></p>
                                <p><NavLink to={"/blogs"} className="href">Blogs</NavLink></p>
                                <p><NavLink to={"/about-us"} className="href">About us</NavLink></p>
                            </div>
                            </div>
                            <div className="industries-legal">
                            <div className="industries">
                                <h5>Industries</h5>
                                <p><NavLink to={"/blogs/festival"} className="href">Festival</NavLink></p>
                                <p><NavLink to={"/blogs/lifestyle"} className="href">Lifestyle</NavLink></p>
                                <p><NavLink to={"/blogs/finance"} className="href">Finance</NavLink></p>
                            </div>
                            <div className="legal">
                                <h5>Blogs on</h5>
                                <p><NavLink to={"/blogs/be-my-wordsmith"} className="href">BMWS</NavLink></p>
                                <p><NavLink to={"/blogs/digital-marketing"} className="href">Digital Marketing</NavLink></p>
                                <p><NavLink to={"/blogs/human-resource"} className="href">Human Resource</NavLink></p>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6 footerSectionBottomContent">
                            <div className="privacy-and-terms-of-service">
                                <p className='legal-services'><span><NavLink to={"/faq"} className="href">FAQ</NavLink></span> | <span><NavLink to={"/sitemap"} className="href">Sitemap</NavLink></span></p>
                            </div>
                        </div>
                        <div className="col-md-6 footerSectionBottomContent">
                            <div className="sign-up-for-newsletter-div">
                            {/* <p>Sign Up for our Newsletter</p>
                            <NavLink to={"/contact-us"} className="href"><button>Subscribe</button></NavLink> */}
                            <form name='email-subscribe-form' method="post" enctype="multipart/form-data" netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true" onSubmit="submit">
                                <input type="hidden" name='form-name' value="email-subscribe-form" />
                                <input type="email" name="subscribe-email" id="subscribe-email" placeholder='Sign Up for our Newsletter' />
                                <button type="submit">Subscribe</button>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="second-part-of-footer-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-6 second-footer-inner-div">
                            <p className='footer-all-rights'>All rights reserved @2023-24</p>
                        </div>
                        <div className="col-md-6 second-footer-inner-div">
                            <p className='legal-services'><span><NavLink to={"/privacy-policy"} className="href">Privacy Policy</NavLink></span> | <span><NavLink to={"/terms-of-service"} className="href">Terms of Service</NavLink></span></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;