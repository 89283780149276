import React from 'react';
import SwiperCore, { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Image2 from "../../../../Image/Categories_Page/Carousel/Disssertation.webp";
import Image3 from "../../../../Image/Categories_Page/Carousel/Holistic.webp";
import Image1 from "../../../../Image/Categories_Page/Carousel/Nexus-1.webp";
import "../CSS/CarouselSlider.css";
SwiperCore.use([Autoplay]);

export default function CarouselSlider () {
    return (
        <>
            <section className="ourWorkSamplesHeadingSection">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 ourWorkSamplesHeadingDiv">
                            <p>Find</p>
                            <h6>Our work samples here</h6>
                        </div>
                    </div>
                </div>
            </section>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                        <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2,
                        // slideShadows: true,
                    }}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    loop={true}
                    autoplay={{ delay: 3000 }}
                    navigation={true}
                    modules={[EffectCoverflow, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide className="swiperSlide" onClick={() => window.open('https://www.nexuschatapp.com/')}>
                        <div className="swiper-slide-img">
                            <img src={Image1} alt="Nexus Chat App" className='nexus' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiperSlide" onClick={() => window.open('https://www.writemydissertationforme.com/')}>
                        <div className="swiper-slide-img">
                            <img src={Image2} alt="Write my dissertation for me" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiperSlide" onClick={() => window.open('https://www.holisitclearning.com/')}>
                        <div className="swiper-slide-img">
                            <img src={Image3} alt="Holistic Learning Bangalore" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiperSlide" onClick={() => window.open('https://www.nexuschatapp.com/')}>
                        <div className="swiper-slide-img">
                            <img src={Image1} alt="Nexus Chat App" className='nexus' />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiperSlide" onClick={() => window.open('https://www.writemydissertationforme.com/')}>
                        <div className="swiper-slide-img">
                            <img src={Image2} alt="Write my dissertation for me" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiperSlide" onClick={() => window.open('https://www.holisitclearning.com/')}>
                        <div className="swiper-slide-img">
                            <img src={Image3} alt="Holistic Learning Bangalore" />
                        </div>
                    </SwiperSlide>
                </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}