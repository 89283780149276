import React from 'react'
import { Helmet } from 'react-helmet'
import { ourBlogs } from '../../../../Data/Blogs/OurBlogsData'
import PopularBlogs from "../../Blog_Page_Component/JS/PopularBlogs"
import AlsoRead from '../Blog_Page_Component/AlsoRead'
import PopularArticles from '../Blog_Page_Component/PopularArticles'
import "../Industry_Blogs/ChooseIndustry.css"

const HumanResourceIndustry = () => {
    let newBlogArray = [];
    for (let i = ourBlogs.length - 1; i >= 0; i--) {
        if (ourBlogs[i].category === "HR") {
            newBlogArray.push(ourBlogs[i])
        }
    }
    // console.log(newBlogArray)
    return (
        <>
            <Helmet>
                <title>Blogs | Human Resource | Be My Wordsmith</title>
                <meta name="description" content="Explore insightful blogs related Human Resource Industry at BeMyWordsmith. Gain valuable perspectives on HR strategies, workplace dynamics, and industry trends. Stay informed for effective workforce management and organizational success." />
                <link rel="canonical" href="https://www.bemywordsmith.com/blogs/human-resource" />
            </Helmet>
            <section className="categoryBlogsSectionStartHere">
                <div className="container-fluid categoryBlogsSectionFirstInnerDiv">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 categoryBlogsHeadingDiv">
                            <h2>Human Resource Blogs</h2>
                        </div>
                        <div className="col-lg-7 categoryBlogsDiv">
                            {
                                newBlogArray.map((items) => {
                                    return (
                                        <div key={items.id}>
                                            <div className="categoryBlogRightCardInnerDiv categoryBlogRightCardForBelowLine">
                                                <div className="cartegoryBlogImgDiv">
                                                    <img src={items.blogImage} alt="" className='img-fluid' />
                                                </div>
                                                <div className="categoryBlogCardHeadingAndPara ms-4">
                                                    <p className='categoryBlogCardAuthorNameAndDate'>{items.author} | {items.date}</p>
                                                    <h3>{items.title}</h3>
                                                    <p className='categoryBlogCardPara'>{items.description.slice(0, 111)}...</p>
                                                    <p className='categoryBlogReadMoreBtn'>{items.newPage}</p>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-lg-4 categoryBlogAlsoReadAndPopularArticlesDiv">
                            <div className="alsoReadBlogDiv">
                                <AlsoRead />
                            </div>
                            <div className="popularArticlesBlogDiv">
                                <PopularArticles />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PopularBlogs />
        </>
    )
}

export default HumanResourceIndustry;