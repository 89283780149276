import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import "../../Component/CSS/FAQOnAboutUs.css";
import FAQLegalQuestions from '../../Component/JS/FAQLegalQuestions';
import FAQOnAboutUs from '../../Component/JS/FAQOnAboutUs';
import FAQ from "../../Image/FAQ/Frequently Asked Questions.png";
import "../CSS/FrequentlyAskedQuestions.css";
import FAQONBlogAndArticlePage from '../Categories/Categories_Components/JS/FAQONBlogAndArticlePage';
import FAQONCategoriesPage from '../Categories/Categories_Components/JS/FAQONCategoriesPage';
import FAQONProductDescriptionPage from '../Categories/Categories_Components/JS/FAQONProductDescriptionPage';
import FAQONWebsiteContentPages from '../Categories/Categories_Components/JS/FAQONWebsiteContentPages';
import FAQONContactUsPage from '../Contact_Us_Component/FAQONContactUsPage';
import FAQONLandingPage from '../Landing_Page_Component/FAQONLandingPage';

const FrequentlyAskedQuestions = () => {
    const [relatedFAQ, setRelatedFAQ] = useState(1);
    const showRelatedFAQ = (e) => {
        setRelatedFAQ(e);
    }
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What makes the best content writing service in Bangalore ?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "- Our Bangalore content writing company is skilled in creating attention-grabbing, convincing content that motivates readers to take action.\
                            - Our delivery of 100% original content makes us proud. Every work is written by our writers from scratch, guaranteeing originality and genuineness.\
                            - In order to ensure that the material we provide is precise, dependable, and useful, our SEO writing staff undertakes thorough research to obtain pertinent information and insights.\
                            - We take great care in our grammar and proofreading, making sure that every piece of writing is polished, and of the greatest caliber."
                            }
                        },{
                            "@type": "Question",
                            "name": "Is your content SEO-friendly?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Make sure your articles and blog are optimized for search engines (SERPs) so that they appear on the first and second pages of Google search results. In order to increase your content's visibility on Search Engine Result Pages, you should focus on specific keywords."
                            }
                        },{
                            "@type": "Question",
                            "name": "What is the turnaround time for a Project?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We can complete tasks quickly because we have a group of skilled writers who are available both full-time and on a project basis."
                            }
                        },{
                            "@type": "Question",
                            "name": "Will I be able to own copyright to the content you supply?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, Definitely! Once the entire Payment is completed."
                            }
                        },{
                            "@type": "Question",
                            "name": "Where do I get Started ?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We need keywords, two to three competitor links (to deepen our expertise of your company), and the specifics of your target audience's buyer persona in order to begin crafting captivating contents that will boost your brand. This data will open the door for well-written content that elevates your brand to new levels of achievement."
                            }
                        },{
                            "@type": "Question",
                            "name": "Why should I invest in Website Content Writing Services?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Investing in Website Content Writing Services ensures compelling, SEO-friendly content that engages your targeted audience, boosts online visibility, and establishes credibility to your website. Professional writers craft tailored content, saving you time and enhancing your brand's online presence for long-term objectives."
                            }
                        },{
                            "@type": "Question",
                            "name": "Why is it imporatnt to tailor website content for my audience?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In order to connect with your audience, you must customize the material on your website. It builds a good relationship, improves user experience, and raises engagement level. Understanding the requirements, tastes, and language of your audience helps you produce content that converts well and propels your organization forward."
                            }
                        },{
                            "@type": "Question",
                            "name": "How will I effectively be able to showcase my product and content through my content?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Effectively showcasing product and content by crafting engrossing narratives. Highlighting key features, addressing customer pain points, and use engaging visuals. Tailor the content to resonate with your audience, emphasizing benefits and online presence."
                            }
                        },{
                            "@type": "Question",
                            "name": "How will social media integration ampliy my website content?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Social media integration amplifies website content by expanding its reach. Sharing engaging content on platforms like Facebook, Twitter, and Instagram increases visibility, drives traffic, and fosters audience interaction. It's a powerful strategy for building brand awareness and fostering online community engagement."
                            }
                        },{
                            "@type": "Question",
                            "name": "Will Blogs and articles have SEO- optimised content and how does the kwywords play a role here?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Absolutely! Our blogs and articles are designed to be SEO-optimized. This means we carefully incorporate specific keywords and links enhance the likelihood of higher rankings in search engine results. By doing so, we aim to boost the visibility of your content, making it easily discoverable and engaging for online users. If you have particular keywords or topics you'd like to target for SEO purposes, we recommend sharing them with us, and we'll integrate them thoughtfully into your content."
                            }
                        },{
                            "@type": "Question",
                            "name": "Are your blog posts SEO friendly ?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Without a doubt, providing you with SEO content that works is our primary objective. We'll ask you for the keywords you wish to target and any further SEO instructions you may have when you place your order. Our writers will take the time to comprehend the purpose of the search and the keywords in order to create a piece for you that fulfills that purpose."
                            }
                        },{
                            "@type": "Question",
                            "name": "What are the features of a good blog post?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A good blog post should have a catchy title, a clear introduction, a well structured body and a strong conclusion. It should also have relevant keywords , images links and call to actions. It should also be informative, engaging, original and error free. We take care of all these steps so that your blogs are loved by search engine and target readers alike."
                            }
                        },{
                            "@type": "Question",
                            "name": "How does your professional content writing services work ?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In order to deliver a thorough service, our professional content creation method is structured around 5 essential components. We start with the client onboarding process and work our way through the assignment phase, content creation, careful proofreading, and timely submission. Throughout the content development process, our methodical approach meets the unique needs of our clients while ensuring a seamless and efficient workflow."
                            }
                        },{
                            "@type": "Question",
                            "name": "How do I know if my blogs will be completed on time?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We respect your time and deadlines. When you hire us to curate your material, you can be sure that your posts will be delivered on time. We prioritize your work and deliver the best content as soon as we can."
                            }
                        },{
                            "@type": "Question",
                            "name": "Why Product Descriptions Are Important?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A product description serves to demonstrate a product's value to potential customers visiting your website. When crafted effectively, it outlines the features and benefits, influencing potential customers to make a purchase. It's important for the product description to convey how the product can address a customer's problem. Often, customers are looking for solutions when they shop, making it an ideal opportunity to emphasize why your product is the best fit for them."
                            }
                        },{
                            "@type": "Question",
                            "name": "Why Product description also known As SEO eCommerce Writing?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In e-commerce, product descriptions are vital, yet they frequently don't receive the attention they merit. It's typical to either follow supplier requirements verbatim or incorporate boring details. Still, one should steer clear of this. Although it may need more time, we advise having carefully written product descriptions. They can greatly increase your company's revenue and sales."
                            }
                        },{
                            "@type": "Question",
                            "name": "How long should a Product Description be?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "In brief, aim for 300-500 words as it's the industry average. Yet, feel free to go longer if you need to provide a thorough description of your product."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you gather information about the Industry before creating the content?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, we delve into industry before crafting content. Understanding your sector allows us to tailor compelling narratives that resonate, inform, and engage your audience effectively, ensuring our content aligns seamlessly with your brand objectives."
                            }
                        },{
                            "@type": "Question",
                            "name": "Can you create Product Description from scratch?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Of course! Our writers will carry out the required research to produce the descriptions for you if you provide us with the product name and the URL of a product page with at least one image. Along with few keywords and the target audience."
                            }
                        },{
                            "@type": "Question",
                            "name": "How does your Product Description ensure SEO friendly Content?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Lastly, it's critical to incorporate pertinent keywords into your product descriptions for search engine optimization. But exercise caution so as not to abuse them. Because Google appreciates a variety of information, make sure to use these keywords in titles, photos, and product descriptions for thorough optimization."
                            }
                        },{
                            "@type": "Question",
                            "name": "Will the content writers have subject matter expertise?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We make sure to provide a writer with extensive subject-matter knowledge when you ask us to do an assignment. For instance, we choose a writer who specializes in finance if the topic is finance. For the project to succeed, this matching procedure is crucial. Our team puts a lot of effort into matching you with a writer who is qualified for the task at hand."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you at BMW use any content writing tools?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes. We use Grammarly, SEMRush and other premium tools."
                            }
                        },{
                            "@type": "Question",
                            "name": "How do you ensure the quality of your content ?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Since content is essential to converting prospects into real clients and generating natural traffic for websites, it is recognized as the bedrock of the digital world. in BMWS, best content writing agency in Bangalore, we're experts in providing our clients with timely, high-quality content."
                            }
                        },{
                            "@type": "Question",
                            "name": "How is it different from other content writing platforms?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A group of writers, dedicated project managers, proposal pitching, and quality assurance have been assembled by us. What distinguishes us? Our dedication to providing satisfaction to our consumers and our desire for revolutionizing the content sector."
                            }
                        },{
                            "@type": "Question",
                            "name": "Why haven’t you mentioned your writing rates on the website?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Sure, here's a simpler version: We like to give each customer a special experience at BMWS. Because every project is different, we talk to you to understand exactly what you need. Then, instead of showing you a page with fixed prices, we create a customized price just for you."
                            }
                        },{
                            "@type": "Question",
                            "name": "What is Your Content Revision Policy and do you charge additionaly for revisons?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "At BMWS, if you don't like the content we give you, just contact the person you've been talking to about the project. We'll quickly make changes accordoingly as per our terms and conditions. We provide one free round of revisions with each piece of writing. But if you want changes after that, there will be a charge for the revisions."
                            }
                        },{
                            "@type": "Question",
                            "name": "What is the Cost of Hiring BMWS Writer For Our Content Requirements?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Our prices are flexible and dependent on a number of variables like the deadline, volume, service. To get a free quote, please use the Get Quote form in the Contact Us page"
                            }
                        },{
                            "@type": "Question",
                            "name": "What Process does BMW follow?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Our rigorous content creation methodology places a strong emphasis on in-depth research and a comprehensive understanding of your company. We then meticulously clarify and polish the content. We consider ourselves to be your communication agency in addition to being a content writing firm."
                            }
                        },{
                            "@type": "Question",
                            "name": "I have a huge writing project. Before entrusting the entire task to you, I'd like to evaluate your services through a test.",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Indeed. We would be delighted to start collaborating with you on a portion of your project. You are welcome to assign us the full job if you are satisfied with the quality."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do You Focus on SEO Content Writing?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "For your articles to appear on the first and top pages of Google search results, it's crucial to make them SEO-friendly. This means focusing on specific keywords to help your content rank higher on Search Engine Result Pages."
                            }
                        },{
                            "@type": "Question",
                            "name": "How does Fresh Content help New websites?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Creating current and pertinent content is crucial for connecting with your customer base. We prioritize delivering content that is not only up to date but also resonates with your audience. Additionally, this approach aids search engines in establishing the credibility of your website, influencing its placement on the Search Engine Results Page (SERP)."
                            }
                        },{
                            "@type": "Question",
                            "name": "what is your privacy policy?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Your Privacy is not for sale. We do not and will not sell or rent your personal information to anyone, for any reason, at any time. BMWS uses and discloses your personal information only as follows: to analyze site usage and improve the Service; to deliver to you any administrative notices, money alerts and communications relevant to your use of the Service; to fulfill your requests for certain products and services;for market research, project planning, troubleshooting problems, detecting and protecting against error, fraud or other criminal activity; to third-party contractors that provide services to BMWS and are bound by these same privacy restrictions; to enforce BMWS Terms of Use; and as otherwise set forth in this Privacy and Security Policy."
                            }
                        },{
                            "@type": "Question",
                            "name": "contact us",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "If you have any questions about this Privacy Policy, You can contact us:
                            By email: bemywordsmith@gmail.com
                            By visiting this page on our website: www.bemywordsmith.com
                            By phone number: +91 9748 093320"
                            }
                        },{
                            "@type": "Question",
                            "name": "Is there any Disclaimer for your website ?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "BMWS Writing Services operates this online platform, offering professional content marketing services. All services featured on the website belong to BMWS. We urge visitors and clients to thoroughly review our terms and conditions before opting for any content CRAFTING, development services. Upon agreement with the terms outlined on our company's website, users can proceed to place an order.\
                            Customer rights\
                            Customers can show, approve, copy, and sell articles on this website in any format. Content cannot be held by clients until full payment has been received. The client is permitted to post our SEO content online upon receipt of complete payment.\
                            The website's goal\
                            Prospective customers can acquire high-quality articles, blogs, or web content writing services for a variety of purposes on this fantastic online platform. The publisher can request 100% original, one-of-a-kind, and error-free content, and we will do everything in our power to meet his needs.\
                            Rewrite directives\
                            We accept rewriting requests. We use every detail you have given us to create excellent rewritten articles. The length of the rewritten article writing service is determined by your needs. It is our duty to select a competent content writer for this assignment as well. Actually, we sincerely want to select a qualitative writer who can finish the assignment before the deadline.\
                            You should never in any manner infringe upon the rights of a third party when placing an order. This could apply to copyright, trademarks, service marks, moral rights, and similar protections."
                            }
                        },{
                            "@type": "Question",
                            "name": "Is your content confidential?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We take pride in our work principles, and maintaining secrecy is one of them. We value our clients' confidence enough to let them to share their personal information with us; the information you provide is secure and will only be shared with the task's lead writer. Therefore, if you want to have our assistance, you don't have to be concerned about secrecy. Moreover, we don't contract out any of our work. As a result, there is no chance that any third party will obtain your information."
                            }
                        },{
                            "@type": "Question",
                            "name": "What is refund policy",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "BMWS have a transparent refunding procedure. Since We value Our Customers' Satisfaction and Service, We carefully consider each and every refund request and work hard to meet their needs.\
                        Conditions for Refunds\
                        Under the following circumstances, you may seek a full or partial refund. That is, provided that You:\
                        - Determine that Our content is Unusable for Your specific campaign.\
                        - Discover that Our content has Major Flaws.\
                        - A refund request should be made within the first 14 days of the delivery date. Furthermore, you must specify the precise rationale for your request.\
                        - Please take note that the content that is still pending publication on your website cannot be returned.\
                        - We have a simple return policy that is mostly focused on making sure you are satisfied with the quality. We are willing to return all or a portion of the money you paid if the provided content does not come up to your expectations.\
                        - Cancellation requests for projects that you started before content delivery, however, come under a different category and need to be submitted within a day of placing the order.\ Please send an email to bemywordsmith@gmail.com if you would like to request a refund or cancel, and we will take care of your issue the next business day."
                            }
                        },{
                            "@type": "Question",
                            "name": "How is the conetnt writing review policy done at BMWS?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Once your writer has completed the job and sent the content back to you for review, you have three days to review it and request unlimited revisions.\
                        After the three days is up, we ask you to make a choice. If the content meets or exceeds your expectations then you can accept the content and take full ownership of it.\
                        If, for any reason, your writer did not deliver what you wanted after the revision process, you can simply reject the content and we will refund your account."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you have any plagarism check done before giving out the content ?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, our content undergoes rigorous plagiarism checks using advanced tools. We prioritize originality and ensure that the content delivered to you is free from any form of plagiarism, maintaining the highest standards of quality and authenticity."
                            }
                        },{
                            "@type": "Question",
                            "name": "What is the estimated time for a project?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Our wordsmiths deliver content keeping your deadline in mind. From a two-minute meal to a sous vide, we can deliver as per your wishes. Your deadline is what we strive to achieve."
                            }
                        },{
                            "@type": "Question",
                            "name": "How do I place an order?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Click on the Get Quote button on top of the page. Fill in the form. Provide as much detailed information as you can so that we can give a quote instantly. All the communication after the form is filled, happens through email. Hassle free, fast and smooth!"
                            }
                        }]
                        }`}
                </script>
                <title>FAQ | Be My Wordsmith</title>
                <meta name="description" content="Reach out today for expertly crafted, results-oriented best seo content writing solutions. Contact us, and we'll prioritize your needs in our response." />
                <link rel="canonical" href="https://www.bemywordsmith.com/faq" />
            </Helmet>
            <section className="faq-heading-and-banner-image-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-7 faq-heading-and-para-div">
                            <h1>FAQ s</h1>
                            <p>Here, everything you need to know about our products, services, policies, or support. If you don't see your question here, please feel free to <Link to={"/contact-us"}>contact us</Link>.</p>
                        </div>
                        <div className="col-md-5 faq-img-div">
                            <img src={FAQ} alt="" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-faq-section-start-here">
                <div className="container-fluid faq-heading-and-banner-first-inner-div">
                    <div className="row justify-flex-start faq-heading-and-banner-second-inner-div">
                        <div className="col-md-3 faq-questions-heading-div">
                            <p onClick={() => showRelatedFAQ(1)}><span>Legal</span></p>
                            <p onClick={() => showRelatedFAQ(2)}><span>General</span></p>
                            <p onClick={() => showRelatedFAQ(3)}><span>Categories</span></p>
                            <p onClick={() => showRelatedFAQ(4)}><span>Website Content</span></p>
                            <p onClick={() => showRelatedFAQ(5)}><span>Blogs & Articles</span></p>
                            <p onClick={() => showRelatedFAQ(6)}><span>Product Description</span></p>
                            <p onClick={() => showRelatedFAQ(7)}><span>About Us</span></p>
                            <p onClick={() => showRelatedFAQ(8)}><span>Payment Related</span></p>
                        </div>
                        <div className="col-md-9 faq-questions-and-answers faq-comp-main-div">
                            <h2>{(relatedFAQ === 1 && "Legal") || (relatedFAQ === 2 && "General") || (relatedFAQ === 3 && "Categories") || (relatedFAQ === 4 && "Website Content") || (relatedFAQ === 5 && "Blogs & Articles") || (relatedFAQ === 6 && "Product Description") || (relatedFAQ === 7 && "About Us") || (relatedFAQ === 8 && "Payment Related")}</h2>
                            {
                                (relatedFAQ === 1 && <FAQLegalQuestions />) || (relatedFAQ === 2 && <FAQONLandingPage />) || (relatedFAQ === 3 && <FAQONCategoriesPage />) || (relatedFAQ === 4 && <FAQONWebsiteContentPages />) || (relatedFAQ === 5 && <FAQONBlogAndArticlePage />) || (relatedFAQ === 6 && <FAQONProductDescriptionPage />) || (relatedFAQ === 7 && <FAQOnAboutUs />) || (relatedFAQ === 8 && <FAQONContactUsPage />)
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FrequentlyAskedQuestions;