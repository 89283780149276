import { useEffect, useRef, useState } from "react";

const useWordCountTime = () => {
    const [readingTime, setReadingTime] = useState();
    const findWord = useRef("");

    useEffect (() => {
        let Read_Per_Mins = 250;
        let wordCount = findWord.current.innerText.trim().split(" ").length;
        let time = Math.ceil(wordCount / Read_Per_Mins);
        if (time > 0 || time < 10) {
            time = "0" + time;
        }
        setReadingTime(time);
    }, [])

    return [readingTime, findWord];

}

export default useWordCountTime;