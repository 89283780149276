import {useState} from 'react'

const UseIncreaseCounter = (initialValue) => {
    const [counter, setCounter] = useState(initialValue);
    const increaseValue = (e) => {
        setCounter(parseInt(e.target.value))
    }
    const increase = () => {
            return setCounter(counter + 1);
    }
    const decrease = () => {
        // return setCounter(counter - 1);
        if (counter > initialValue) {
            return setCounter(counter - 1);
        } else {
            return setCounter(counter);
        }
    }

    return [counter, increaseValue, increase, decrease];
}

export default UseIncreaseCounter;