import React from 'react'
import { BsSearch } from "react-icons/bs"
import { ourBlogs } from '../../../../Data/Blogs/OurBlogsData'
import "../Main_File/BlogsPage.css"

const LatestBlogs = () => {
  let randomBlogDetailsOnBlogPageNumTwentyOne = Math.floor(Math.random() * ourBlogs.length);
  let randomBlogDetailsOnBlogPageNumTwentyTwo = Math.floor(Math.random() * ourBlogs.length);
  let randomBlogDetailsOnBlogPageNumTwentyThree = Math.floor(Math.random() * ourBlogs.length);
  let randomBlogDetailsOnBlogPageNumTwentyFour = Math.floor(Math.random() * ourBlogs.length);
  return (
    <>
    <section className="headingSectionOfBlogPageStartHere">
        <div className="container headingSectionOfBlogPageFirstInnerDiv">
          <div className="row justify-content-evenly">
            <div className="col-md-8 headingAndSearchInputDiv">
              <div className='mainHeadingDiv'>
                <p>Read our</p>
                <h3>Latest blogs</h3>
              </div>
              <div className='searchInputDiv'>
                <form action="">
                    <span className='searchIcon'><i><BsSearch /></i></span><input type="text" className="form-control" id="search-for-blogs" placeholder="Search for blogs" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="firstSectionOfBlogPageStartHere">
        <div className="container firstSectionOfBlogPageFirstInnerDiv">
          <div className="row justify-content-evenly firstSectionOfBlogPageSecondInnerDiv">
            <div className="col-lg-6 latestBlogLeftCardDiv">
              <div className='latestBlogLeftCardInnerDiv'>
                <div className="leftCardImage">
                <img src={ourBlogs[randomBlogDetailsOnBlogPageNumTwentyOne].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumTwentyOne].altTag} className='img-fluid' style={{width: "100%", height: "316px", objectFit: "cover", objectPosition: "center"}} />
                </div>
                <div className="leftBlogCardHeadingAndPara">
                  <p className='leftCardAuthorNameAndDate'>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyOne].author} | {ourBlogs[randomBlogDetailsOnBlogPageNumTwentyOne].date}</p>
                  <h3>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyOne].title}</h3>
                  <p className='leftCardPara'>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyOne].description.slice(0, 101)}...</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 latestBlogRightCardDiv">
              <div className="container-fluid latestBlogRightCardForBelowLine">
                <div className="row justify-flex-start">
                    <div className="col-sm-4">
                      <img src={ourBlogs[randomBlogDetailsOnBlogPageNumTwentyTwo].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumTwentyTwo].altTag} className='img-fluid' />
                    </div>
                    <div className="col-sm-8 rightCardHeadingAndPara">
                      <p className='rightCardAuthorNameAndDate'>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyTwo].author} | {ourBlogs[randomBlogDetailsOnBlogPageNumTwentyTwo].date}</p>
                      <h3>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyTwo].title}</h3>
                    </div>
                </div>
              </div>
              <div className="container-fluid latestBlogRightCardForBelowLine">
                <div className="row justify-flex-start">
                    <div className="col-sm-4">
                      <img src={ourBlogs[randomBlogDetailsOnBlogPageNumTwentyThree].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumTwentyThree].altTag} className='img-fluid' />
                    </div>
                    <div className="col-sm-8 rightCardHeadingAndPara">
                      <p className='rightCardAuthorNameAndDate'>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyThree].author} | {ourBlogs[randomBlogDetailsOnBlogPageNumTwentyThree].date}</p>
                      <h3>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyThree].title}</h3>
                    </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row justify-flex-start">
                    <div className="col-sm-4">
                      <img src={ourBlogs[randomBlogDetailsOnBlogPageNumTwentyFour].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumTwentyFour].altTag} className='img-fluid' />
                    </div>
                    <div className="col-sm-8 rightCardHeadingAndPara">
                      <p className='rightCardAuthorNameAndDate'>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyFour].author} | {ourBlogs[randomBlogDetailsOnBlogPageNumTwentyFour].date}</p>
                      <h3>{ourBlogs[randomBlogDetailsOnBlogPageNumTwentyFour].title}</h3>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LatestBlogs;