import {useState} from 'react'

const UseDecreaseCounter = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const decreaseValue = (e) => {
        setValue(parseInt(e.target.value))
    }
    const increment = () => {
            return setValue(value + 1);
    }
    const decrement = () => {
        if (value > initialValue) {
            return setValue(value - 1);
        } else {
            return setValue(value);
        }
    }

    return [value, decreaseValue, increment, decrement];
}

export default UseDecreaseCounter;