import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { ourBlogs } from '../../../../Data/Blogs/OurBlogsData'
import Pagination from '../../Blog_Page_Component/JS/Pagination'
import PopularBlogs from "../../Blog_Page_Component/JS/PopularBlogs"
import AlsoRead from '../Blog_Page_Component/AlsoRead'
import PopularArticles from '../Blog_Page_Component/PopularArticles'
import "../Industry_Blogs/ChooseIndustry.css"

const ContentWritingIndustry = () => {
    const [showPerPage, setShowPerPage] = useState(5);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    let newBlogArray = [];
    for (let i = ourBlogs.length - 1; i >= 0; i--) {
        if (ourBlogs[i].category === "Content Writing") {
            newBlogArray.push(ourBlogs[i])
        }
    }
    // console.log(newBlogArray)
    return (
        <>
            <Helmet>
                <title>Blogs | Content Writing</title>
                <meta name="description" content="Explore a tapestry of content on BeMyWordsmith Blogs—engaging articles, compelling blogs, product descriptions, and website insights. Dive into a world of related blogs for a comprehensive reading experience." />
                <link rel="canonical" href="https://www.bemywordsmith.com/blogs/content-writing" />
            </Helmet>
            <section className="categoryBlogsSectionStartHere">
                <div className="container-fluid categoryBlogsSectionFirstInnerDiv">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 categoryBlogsHeadingDiv">
                            <h2>Content Writing Blogs</h2>
                        </div>
                        <div className="col-lg-7 categoryBlogsDiv">
                            {
                                newBlogArray.slice(pagination.start, pagination.end).map((items) => {
                                    return (
                                        <div key={items.id}>
                                            <div className="categoryBlogRightCardInnerDiv categoryBlogRightCardForBelowLine">
                                                <div className="cartegoryBlogImgDiv">
                                                    <img src={items.blogImage} alt="" className='img-fluid' />
                                                </div>
                                                <div className="categoryBlogCardHeadingAndPara ms-4">
                                                    <p className='categoryBlogCardAuthorNameAndDate'>{items.author} | {items.date}</p>
                                                    <h3>{items.title}</h3>
                                                    <p className='categoryBlogCardPara'>{items.description.slice(0, 111)}...</p>
                                                    <p className='categoryBlogReadMoreBtn'>{items.newPage}</p>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-lg-4 categoryBlogAlsoReadAndPopularArticlesDiv">
                            <div className="alsoReadBlogDiv">
                                <AlsoRead />
                            </div>
                            <div className="popularArticlesBlogDiv">
                                <PopularArticles />
                            </div>
                        </div>
                    </div>
                    <Pagination
                        showPerPage={showPerPage}
                        onPaginationChange={onPaginationChange}
                        total={ourBlogs.length}
                    />
                </div>
            </section>
            <PopularBlogs />
        </>
    )
}

export default ContentWritingIndustry;