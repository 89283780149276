
import { BsChevronDown, BsChevronUp, BsDashCircle, BsPlusCircle } from "react-icons/bs";

const Icon = ({ color }) => {
    return <BsDashCircle fill={color} />;
};

const DownIcon = ({ color }) => {
    return <BsChevronUp fill={color} />;
}

const LegalQuestionsData = [
    {
        id: 1,
        question: "what is your privacy policy?",
        answer: "Your Privacy is not for sale. We do not and will not sell or rent your personal information to anyone, for any reason, at any time. BMWS uses and discloses your personal information only as follows: to analyze site usage and improve the Service; to deliver to you any administrative notices, money alerts and communications relevant to your use of the Service; to fulfill your requests for certain products and services;for market research, project planning, troubleshooting problems, detecting and protecting against error, fraud or other criminal activity; to third-party contractors that provide services to BMWS and are bound by these same privacy restrictions; to enforce BMWS Terms of Use; and as otherwise set forth in this Privacy and Security Policy.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 2,
        question: "If you want to talk to our team please feel free to contant us.",
        answer: <span>If you have any questions about this Privacy Policy, You can contact us:<br />By Email: bemywordsmith@gmail.com<br />By visiting this page on our website: www.bemywordsmith.com<br />By Phone number: +91 9748 093320</span>,
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 3,
        question: "Is there any Disclaimer for your website?",
        answer: <span>BMWS Writing Services operates this online platform, offering professional content marketing services. All services featured on the website belong to BMWS. We urge visitors and clients to thoroughly review our terms and conditions before opting for any content CRAFTING, development services. Upon agreement with the terms outlined on our company's website, users can proceed to place an order.<br />
        <b>Customer rights</b><br />
        Customers can show, approve, copy, and sell articles on this website in any format. Content cannot be held by clients until full payment has been received. The client is permitted to post our SEO content online upon receipt of complete payment.<br />
        <b>The website's goal</b>
        Prospective customers can acquire high-quality articles, blogs, or web content writing services for a variety of purposes on this fantastic online platform. The publisher can request 100% original, one-of-a-kind, and error-free content, and we will do everything in our power to meet his needs.<br />
        <b>Rewrite directives</b>
        We accept rewriting requests. We use every detail you have given us to create excellent rewritten articles. The length of the rewritten article writing service is determined by your needs. It is our duty to select a competent content writer for this assignment as well. Actually, we sincerely want to select a qualitative writer who can finish the assignment before the deadline.<br />
        You should never in any manner infringe upon the rights of a third party when placing an order. This could apply to copyright, trademarks, service marks, moral rights, and similar protections.</span>,
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 4,
        question: "Is your content confidential? ",
        answer: "We take pride in our work principles, and maintaining secrecy is one of them. We value our clients' confidence enough to let them to share their personal information with us; the information you provide is secure and will only be shared with the task's lead writer. Therefore, if you want to have our assistance, you don't have to be concerned about secrecy. Moreover, we don't contract out any of our work. As a result, there is no chance that any third party will obtain your information.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    }
];

export default LegalQuestionsData;