import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import "../CSS/CategoriesPage.css";

const SelectServicesThatYouNeed = () => {
    const [showServicesDiv, setShowServicesDiv] = useState(1);

    const clickAndToggleContent = (e) => {
        setShowServicesDiv(e);
    }
  return (
    <>
    <section className='secondSectionOfCategoriePageHeadingDivStartHere'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 selectServicesDivOfHeading">
                            <p>Select</p>
                            <h6>Services that you need</h6>
                        </div>
                    </div>
                </div>
            </section>
            <section className="secondSectionOfCategoriePageStartHere">
                <div className="container secondSectionOfCategoriePageFirstInnerDiv">
                    <div className="row justify-content-around">
                        <div className="col-md-5 selectServicesDivButton">
                            <h6 className={showServicesDiv === 1 ? 'selectServicesBtn selectServicesDesignBtn' : "selectServicesBtn"} id='website' onClick={() => clickAndToggleContent(1)}>Website content</h6>
                            <h6 className={showServicesDiv === 2 ? 'selectServicesBtn selectServicesDesignBtn' : "selectServicesBtn"} id='articles' onClick={() => clickAndToggleContent(2)}>Blogs & articles</h6>
                            <h6 className={showServicesDiv === 3 ? 'selectServicesBtn selectServicesDesignBtn' : "selectServicesBtn"} id='product' onClick={() => clickAndToggleContent(3)}>Product description</h6>
                        </div>
                        <div className={showServicesDiv === 1 ? "col-md-5 selectServicesDivContent" : "col-md-5 selectServicesDivContent selectServicesDivHideContent"} id='websiteContent'>
                            <h6>Website content</h6>
                            <p className='selectServicesContentPara'>Website content that makes your website visible on the internet. Our team delivers well-researched, industry-centric, and digital marketing-friendly content which helps in enhancing the ranking of your website. These are not merely just words but a reflection of your brand and we take this image creation task very seriously.</p>
                            <div className="learn-more-and-contact-us-btn">
                            <NavLink to={"/categories/best-website-content-writing-services-in-bangalore"} className="href"><p className='selectServicesLearnMoreBtn'>Learn More</p></NavLink>
                            <NavLink to={"/contact-us"} className="href"><button className='selectServicesContactUsBtn'>Order Now</button></NavLink>
                            </div>
                        </div>
                        <div className={showServicesDiv === 2 ? "col-md-5 selectServicesDivContent selectServicesDivShowContent" : "col-md-5 selectServicesDivContent selectServicesDivHideContent"} id='blogsAndArticlesContent'>
                            <h6>Blogs &amp; articles</h6>
                            <p className='selectServicesContentPara'>Blog is a medium to dissipate more information about your product and services. We believe that this is not just a random article that you post on the blog section of your website. Blogs written for you are meant to inform your prospective customers and uplift your digital marketing efforts.</p>
                            <div className="learn-more-and-contact-us-btn">
                            <NavLink to={"/categories/best-blog-&-article-writing-services-in-india"} className="href"><p className='selectServicesLearnMoreBtn'>Learn More</p></NavLink>
                            <NavLink to={"/contact-us"} className="href"><button className='selectServicesContactUsBtn'>Order Now</button></NavLink>
                            </div>
                        </div>
                        <div className={showServicesDiv === 3 ? "col-md-5 selectServicesDivContent selectServicesDivShowContent" : "col-md-5 selectServicesDivContent selectServicesDivHideContent"} id='productDescriptionContent'>
                            <h6>Product description</h6>
                            <p className='selectServicesContentPara'>Product description does fifty percent of the work to make your customer interested in buying your product. A crisp but detailed, specific but eye-catching product description lures the customer to make that one purchase decision that will make them loyal to your product and services for life.</p>
                            <div className="learn-more-and-contact-us-btn">
                            <NavLink to={"/categories/product-description-content-writing-services-in-india"} className="href"><p className='selectServicesLearnMoreBtn'>Learn More</p></NavLink>
                            <NavLink to={"/contact-us"} className="href"><button className='selectServicesContactUsBtn'>Order Now</button></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default SelectServicesThatYouNeed;