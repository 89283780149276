
import { BsChevronDown, BsChevronUp, BsDashCircle, BsPlusCircle } from "react-icons/bs";

const Icon = ({ color }) => {
    return <BsDashCircle fill={color} />;
};

const DownIcon = ({ color }) => {
    return <BsChevronUp fill={color} />;
}
const OnAboutUsPage = [
    {
        id: 1,
        question: "Will the content writers have subject matter expertise?",
        answer: "We make sure to provide a writer with extensive subject-matter knowledge when you ask us to do an assignment. For instance, we choose a writer who specializes in finance if the topic is finance. For the project to succeed, this matching procedure is crucial. Our team puts a lot of effort into matching you with a writer who is qualified for the task at hand.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 2,
        question: "Do you at Be My Wordsmith use any content writing tools?",
        answer: "Yes. We use Grammarly, SEMRush and other premium tools.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 3,
        question: "How do you ensure the quality of your content?",
        answer: "Since content is essential to converting prospects into real clients and generating natural traffic for websites, it is recognized as the bedrock of the digital world. in BMWS, best content writing agency in Bangalore, we're experts in providing our clients with timely, high-quality content.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 4,
        question: "How is it different from other content writing platforms?",
        answer: "A group of writers, dedicated project managers, proposal pitching, and quality assurance have been assembled by us. What distinguishes us? Our dedication to providing satisfaction to our consumers and our desire for revolutionizing the content sector.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 5,
        question: "Why haven’t you mentioned your writing rates on the website?",
        answer: "Sure, here's a simpler version: We like to give each customer a special experience at Be My Wordsmith. Because every project is different, we talk to you to understand exactly what you need. Then, instead of showing you a page with fixed prices, we create a customized price just for you.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
];

export default OnAboutUsPage;