import React from 'react'
import "../CSS/SubscribeForm.css"

const SubscribeForm = () => {
    return (
        <>
            <form name='subscribe-form' method="post" enctype="multipart/form-data" netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true" onSubmit="submit" className='subscribeFormDiv'>
                <input type="hidden" name='form-name' value="subscribe-form" />
                <input type="email" name="subscribe-email" id="subscribe-email" placeholder='Enter your mail id' />
                <button type='submit'>Send</button>
            </form>
        </>
    )
}

export default SubscribeForm;