import { NavLink } from "react-router-dom";
import IconImg from "../../Image/Company/Arrow_Icon.png";

const OurCreatorsData = [
    {
        id: 1,
        creatorsImg: require("../../Image/Company/Arihant.webp"),
        creatorsIcon: <NavLink to={"/our-creators/our-creator-ipsita"} className="href"><img src={IconImg} alt="" className='img-fluid' /></NavLink>,
        creatorName: "Arihant B",
        creator: "Content Writer"
    },
    {
        id: 2,
        creatorsImg: require("../../Image/Company/Renu.webp"),
        creatorsIcon: <NavLink to={"/our-creators/our-creator-ipsita"} className="href"><img src={IconImg} alt="" className='img-fluid' /></NavLink>,
        creatorName: "Renu S",
        creator: "Content Writer"
    },
    {
        id: 3,
        creatorsImg: require("../../Image/Company/Punith.webp"),
        creatorsIcon: <NavLink to={"/our-creators/our-creator-ipsita"} className="href"><img src={IconImg} alt="" className='img-fluid' /></NavLink>,
        creatorName: "Punith R",
        creator: "Content Writer"
    },
    {
        id: 4,
        creatorsImg: require("../../Image/Company/Gowthami.webp"),
        creatorsIcon: <NavLink to={"/our-creators/our-creator-ipsita"} className="href"><img src={IconImg} alt="" className='img-fluid' /></NavLink>,
        creatorName: "Gowthami N",
        creator: "Content Writer"
    },
    {
        id: 5,
        creatorsImg: require("../../Image/Company/Ankita.webp"),
        creatorsIcon: <NavLink to={"/our-creators/our-creator-ipsita"} className="href"><img src={IconImg} alt="" className='img-fluid' /></NavLink>,
        creatorName: "Ankita D",
        creator: "Content Writer"
    },
    {
        id: 6,
        creatorsImg: require("../../Image/Company/Swarnali.webp"),
        creatorsIcon: <NavLink to={"/our-creators/our-creator-ipsita"} className="href"><img src={IconImg} alt="" className='img-fluid' /></NavLink>,
        creatorName: "Swarnali B",
        creator: "Content Writer"
    },
    {
        id: 7,
        creatorsImg: require("../../Image/Company/Susmita.webp"),
        creatorsIcon: <NavLink to={"/our-creators/our-creator-ipsita"} className="href"><img src={IconImg} alt="" className='img-fluid' /></NavLink>,
        creatorName: "Susmita S",
        creator: "Content Writer"
    },
];

export default OurCreatorsData;