import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BsDashSquareFill, BsPlusSquareFill } from "react-icons/bs";
import "../../Component/CSS/FAQOnAboutUs.css";
import UseDecreaseCounter from '../../Hooks/UseDecreaseCounter';
import UseIncreaseCounter from '../../Hooks/UseIncreaseCounter';
import ContactUsImg from "../../Image/Contact_Us/Contact_Us_Banner.webp";
import "../CSS/ContactUsPage.css";
import FAQONContactUsPage from '../Contact_Us_Component/FAQONContactUsPage';

const ContactUsPage = () => {
    const [showServicesDiv, setShowServicesDiv] = useState("");
    const [showDifferentForms, setShowDifferentForms] = useState("");
    const [counter, increaseValue, increase, decrease] = UseIncreaseCounter(1);
    const [value, decreaseValue, increment, decrement] = UseDecreaseCounter(100);
    let showAndHideGetQuoteAndMailUsButtonsDiv = useRef("");

    // Show and Hide Form
    const showAndHideForm = (event) => {
        let divId = event.target.id;
        console.log(divId);
        setShowDifferentForms(divId);
        showAndHideGetQuoteAndMailUsButtonsDiv.current.style.display = "none";
    }

    // Select Service Type
    const selectServiceType = (event) => {
        let getServices = event.target.value;
        setShowServicesDiv(getServices);
    }
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "What is refund policy?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Be My Wordsmith have a transparent refunding procedure. Since We value Our Customers' Satisfaction and Service, We carefully consider each and every refund request and work hard to meet their needs.\
                            Conditions for Refunds\
                            Under the following circumstances, you may seek a full or partial refund. That is, provided that You:\
                            Determine that Our content is Unusable for Your specific campaign.\
                            Discover that Our content has Major Flaws.\
                            A refund request should be made within the first 14 days of the delivery date.\ Furthermore, you must specify the precise rationale for your request.\
                            Please take note that the content that is still pending publication on your website cannot be returned.\
                            We have a simple return policy that is mostly focused on making sure you are satisfied with the quality. We are willing to return all or a portion of the money you paid if the provided content does not come up to your expectations.\
                            Cancellation requests for projects that you started before content delivery, however, come under a different category and need to be submitted within a day of placing the order.\
                            Please send an email to bemywordsmith@gmail.com if you would like to request a refund or cancel, and we will take care of your issue the next business day."
                            }
                        },{
                            "@type": "Question",
                            "name": "What are the modes of payment accepted?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "For your benefit, our agency acknowledges all significant Master, Visa, and Rupay cards. You can also pay through a bank Wire Transfer also. Kindly email (bemywordsmith@gmail.com) for more payment information."
                            }
                        },{
                            "@type": "Question",
                            "name": "How is the content writing review policy done at BMWS?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Once your writer has completed the job and sent the content back to you for review, you have three days to review it and request unlimited revisions.\
                            After the three days is up, we ask you to make a choice. If the content meets or exceeds your expectations then you can accept the content and take full ownership of it.\
                            If, for any reason, your writer did not deliver what you wanted after the revision process, you can simply reject the content and we will refund your account."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you have any plagiarism check done before giving out the content?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, our content undergoes rigorous plagiarism checks using advanced tools. We prioritize originality and ensure that the content delivered to you is free from any form of plagiarism, maintaining the highest standards of quality and authenticity."
                            }
                        },{
                            "@type": "Question",
                            "name": "What is the estimated time for a project?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Our wordsmiths deliver content keeping your deadline in mind. From a two-minute meal to a 'sous vide', we can deliver as per your wishes. Your deadline is what we strive to achieve."
                            }
                        },{
                            "@type": "Question",
                            "name": "How do I place an order?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Click on the Get Quote button on top of the page. Fill in the form. Provide as much detailed information as you can so that we can give a quote instantly. All the communication after the form is filled, happens through email. Hassle free, fast and smooth!"
                            }
                        }]
                        }`}
                </script>
                <title>Contact Us | Be My Wordsmith</title>
                <meta name="description" content="Reach out today for expertly crafted, results-oriented best seo content writing solutions. Contact us, and we'll prioritize your needs in our response." />
                <link rel="canonical" href="https://www.bemywordsmith.com/contact-us" />
            </Helmet>
            <section className="firstSectionOfContactUsPageStartHere">
                <div className="container-fluid">
                    <div className="row justify-content-evenly">
                        <div className="col-md-7 contactUsBannerImgDiv">
                            <img src={ContactUsImg} alt="best seo content writing solutions in bangalore" className='img-fluid' />
                        </div>
                        <div className="col-md-4 contactUsFormDiv">
                            <div className="getQuoteAndMailUsButtonsDiv" ref={showAndHideGetQuoteAndMailUsButtonsDiv}>
                                <div className="getAQuoteDiv">
                                    <h4>Get quote for your requirements</h4>
                                    <button onClick={showAndHideForm} id="getQuoteBtn">Get quote</button>
                                </div>
                                <div className="mailUsDiv">
                                    <h4>Not sure of the requirements?</h4>
                                    <button onClick={showAndHideForm} id="mailUsBtn">Mail us</button>
                                </div>
                            </div>
                            {
                                (showDifferentForms === "getQuoteBtn" || showDifferentForms === "getAQuote") && (
                                    <div className="placeAnOrderForm" id='placeAnOrderForm'>
                                        <h2>Get in touch with us</h2>
                                        <form name='get-quote-form' method="post" enctype="multipart/form-data" netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true" onSubmit="submit">
                                            <input type="hidden" name='form-name' value="get-quote-form" />
                                            <div className="mb-3 formsInnerDiv">
                                                <label htmlFor="name" className="form-label">Name*</label>
                                                <input type="text" className="form-control" name='name' id="name" required />
                                            </div>
                                            <div className="mb-3 formsInnerDiv">
                                                <label htmlFor="email" className="form-label">Email*</label>
                                                <input type="email" className="form-control" name='email' id="email" required />
                                            </div>
                                            <div className="mb-3 formsInnerDiv">
                                                <label htmlFor="company" className="form-label">Company Name</label>
                                                <input type="text" className="form-control" name='company' id="company" />
                                            </div>
                                            <div className="mb-3 formsInnerDiv">
                                                <label htmlFor="service" className="form-label">Service</label>
                                                <select className="form-select" aria-label="Default select example" id
                                                    ="service" name='service' onChange={(e) => (selectServiceType(e))} required >
                                                    <option selected value="">Select Service Type</option>
                                                    <option value="1">Blogs &amp; articles</option>
                                                    <option value="2">Website Content</option>
                                                    <option value="3">Product description</option>
                                                </select>
                                            </div>
                                            {
                                                showServicesDiv === "1" && (
                                                    <div className="mb-3 formsInnerDiv formsBlogsArticlesAndWords" id="blogsAndWordsDiv">
                                                        <div className="formsBlogsArticlesAndWordsFirstInnerDiv">
                                                            <div className='formsNoOfBlogs'>
                                                                <label htmlFor="blogs" className="form-label mb-3">No of Blogs</label>
                                                                <br />
                                                                <span onClick={decrease}><i className='blogsDash'><BsDashSquareFill /></i></span>
                                                                <input type="text" className="form-control" id="blogs" name='blogs' value={counter} onChange={increaseValue} />
                                                                <span onClick={increase}><i className='blogsPlus'><BsPlusSquareFill /></i></span>
                                                            </div>
                                                            <div className='formsNoOfWords'>
                                                                <label htmlFor="words" className="form-label mb-3">No of Words</label>
                                                                <br />
                                                                <span onClick={decrement}><i className='wordsDash'><BsDashSquareFill /></i></span>
                                                                <input type="text" className="form-control" id="words" name='words' value={value} onChange={decreaseValue} />
                                                                <span onClick={increment}><i className='wordsPlus'><BsPlusSquareFill /></i></span>
                                                            </div>
                                                        </div>
                                                        {/* <br /><p><span>Pricing</span> <span>$50</span></p> */}
                                                    </div>
                                                )
                                            }
                                            {
                                                showServicesDiv === "2" && (
                                                    <div className="mb-3 formsInnerDiv" id='websiteAndProductUrlDiv'>
                                                        <label htmlFor="websiteURL" className="form-label">Enter URL</label>
                                                        <input type="url" className="form-control" id="websiteURL" name='websiteURL' />
                                                    </div>
                                                )
                                            }
                                            {
                                                showServicesDiv === "3" && (
                                                    <div className="mb-3 formsInnerDiv" id='websiteAndProductUrlDiv'>
                                                        <label htmlFor="productURL" className="form-label">Enter URL</label>
                                                        <input type="url" className="form-control" id="productURL" name='productURL' />
                                                    </div>
                                                )
                                            }
                                            <div className="mt-4 formsInnerDiv mb-3">
                                                <button type="submit">Get a Quote</button>
                                            </div>
                                        </form>
                                        <div className="moveToNewForm">
                                            <p>Not sure of the requirements? <span className='mailUs' id='mailUs' onClick={showAndHideForm}>Mail us</span></p>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                (showDifferentForms === "mailUsBtn" || showDifferentForms === "mailUs") && (
                                    <div className="mailUsForm" id='mailUsForm'>
                                        <h2>Get in touch with us</h2>
                                        <form name='mail-us-form' method="post" enctype="multipart/form-data" netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true" onSubmit="submit">
                                            <input type="hidden" name='form-name' value="mail-us-form" />
                                            <div className="mb-3 formsInnerDiv">
                                                <label htmlFor="mail_name" className="form-label">Name*</label>
                                                <input type="text" className="form-control" name='mail_name' id="mail_name" required />
                                            </div>
                                            <div className="mb-3 formsInnerDiv">
                                                <label htmlFor="mail_email" className="form-label">Email*</label>
                                                <input type="email" className="form-control" name='mail_email' id="mail_email" required />
                                            </div>
                                            <div className="mt-4 formsInnerDiv mb-3">
                                                <button type="submit">Send</button>
                                            </div>
                                        </form>
                                        <div className="moveToNewForm">
                                            <p>Know your requirements? <span className='mailUs' id='getAQuote' onClick={showAndHideForm}>Get Quote</span></p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq-on-about-us-page-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 faq-comp-heading-and-para-div">
                            <h2>Frequently asked questions</h2>
                            <p>Everything you need to know about the product and billing.</p>
                        </div>
                        <div className="col-md-9 faq-comp-main-div">
                            <FAQONContactUsPage />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUsPage;