import React from 'react';
import { ourBlogs } from '../../../../Data/Blogs/OurBlogsData';
import "../CSS/PopularBlogs.css";
let randomBlogDetailsOnBlogPageNumEight = Math.floor(Math.random() * ourBlogs.length);
let randomBlogDetailsOnBlogPageNumNine = Math.floor(Math.random() * ourBlogs.length);
let randomBlogDetailsOnBlogPageNumTen = Math.floor(Math.random() * ourBlogs.length);

const PopularBlogs = () => {
    return (
        <>
            <section className="popularBlogSectionStartHere">
                <div className="container">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 popularBlogsHeading">
                            <h2>Popular blogs</h2>
                        </div>
                        <div className="col-12 col-md-4 popularBlogsCardMainDiv">
                            <div className='popularBlogsCardFirstInnerDiv'>
                                <img src={ourBlogs[randomBlogDetailsOnBlogPageNumEight].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumEight].altTag} className='img-fluid' />
                                <div className="popularBlogsCardHeadingAndParaDiv">
                                    <h6>{ourBlogs[randomBlogDetailsOnBlogPageNumEight].title}</h6>
                                    <p className='popularBlogsPara'>{ourBlogs[randomBlogDetailsOnBlogPageNumEight].description.slice(0, 111)}...</p>
                                    <p className="readMoreBtn">{ourBlogs[randomBlogDetailsOnBlogPageNumEight].newPage}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 popularBlogsCardMainDiv">
                            <div className='popularBlogsCardFirstInnerDiv'>
                                <img src={ourBlogs[randomBlogDetailsOnBlogPageNumNine].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumNine].altTag} className='img-fluid' />
                                <div className="popularBlogsCardHeadingAndParaDiv">
                                    <h6>{ourBlogs[randomBlogDetailsOnBlogPageNumNine].title}</h6>
                                    <p className='popularBlogsPara'>{ourBlogs[randomBlogDetailsOnBlogPageNumNine].description.slice(0, 111)}...</p>
                                    <p className="readMoreBtn">{ourBlogs[randomBlogDetailsOnBlogPageNumNine].newPage}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 popularBlogsCardMainDiv">
                            <div className='popularBlogsCardFirstInnerDiv'>
                                <img src={ourBlogs[randomBlogDetailsOnBlogPageNumTen].blogImage} alt={ourBlogs[randomBlogDetailsOnBlogPageNumTen].altTag} className='img-fluid' />
                                <div className="popularBlogsCardHeadingAndParaDiv">
                                    <h6>{ourBlogs[randomBlogDetailsOnBlogPageNumTen].title}</h6>
                                    <p className='popularBlogsPara'>{ourBlogs[randomBlogDetailsOnBlogPageNumTen].description.slice(0, 111)}...</p>
                                    <p className="readMoreBtn">{ourBlogs[randomBlogDetailsOnBlogPageNumTen].newPage}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PopularBlogs;