
import { BsChevronDown, BsChevronUp, BsDashCircle, BsPlusCircle } from "react-icons/bs";

const Icon = ({ color }) => {
    return <BsDashCircle fill={color} />;
};

const DownIcon = ({ color }) => {
    return <BsChevronUp fill={color} />;
}

const OnLandingPage = [
    {
        id: 1,
        question: "What makes the best content writing service in Bangalore?",
        answer: <span>- Our Bangalore based <b>content writing company</b> is skilled in creating attention-grabbing, convincing content that motivates readers to take action.<br />
        - Our delivery of <b>100% original content</b> makes us proud. Every work is written by our writers from scratch, guaranteeing originality and genuineness.<br />
        - In order to ensure that the material we provide is precise, dependable, and useful, our <b>SEO writing</b> staff undertakes thorough research to obtain pertinent information and insights.<br />
        - We take great care in our grammar and proofreading, making sure that every piece of writing is polished, and of the greatest caliber.</span>,
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 2,
        question: "Is your content SEO-friendly?",
        answer: "Make sure your articles and blog are optimized for search engines (SERPs) so that they appear on the first and second pages of Google search results. In order to increase your content's visibility on Search Engine Result Pages, you should focus on specific keywords.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 3,
        question: "What is the turnaround time for a Project? ",
        answer: "We can complete tasks quickly because we have a group of skilled writers who are available both full-time and on a project basis.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 4,
        question: "Will I be able to own copyright to the content you supply?",
        answer: "Yes, Defenitely! Once the entrie Payment is completed. ",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 5,
        question: "Where do I get Started?",
        answer: "We need keywords, two to three competitor links (to deepen our expertise of your company), and the specifics of your target audience's buyer persona in order to begin crafting captivating contents that will boost your brand. This data will open the door for well-written content that elevates your brand to new levels of achievement.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
];

export default OnLandingPage;