import { NavLink } from 'react-router-dom';

export const ourBlogs = [
    {
        id : 1,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_1.webp'),
        title : <span><NavLink to={"/blogs/ganesh-chaturthi"} className="href" target="_blank">Ganesh Chaturthi</NavLink></span>,
        description : "Ganesh Chaturthi, also known as Vinayaka Chaturthi, is a Hindu festival that commemorates Lord Ganesha's birth. The elephant-headed deity is regarded as the god of beginnings and wisdom as well as the god of removing obstacles. The celebration regularly falls in the Hindu month of Bhadrapada.",
        newPage : <span><NavLink to={"/blogs/ganesh-chaturthi"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "GANESH CHATURTHI",
        author: "Ipsita P",
        category: "Festival",
        date: <span>20<sup>th</sup> Sep 2023</span>
    },
    {
        id : 2,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_2.webp'),
        title : <span><NavLink to={"/blogs/innovative-recruitment-strategies"} className="href" target="_blank">Innovative Recruitment Strategies</NavLink></span>,
        description : "Finding and attracting qualified candidates is a challenge that recruiters have struggled with for years. Even if traditional recruitment methods work, you need something new and creative to go beyond them.",
        newPage : <span><NavLink to={"/blogs/innovative-recruitment-strategies"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Innovative Recruitment Strategies: Beyond Traditional Job Boards",
        author: "Ipsita P",
        category: "HR",
        date: <span>12<sup>th</sup> Dec 2023</span>
    },
    {
        id : 3,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_3.webp'),
        title : <span><NavLink to={"/blogs/how-can-hr-drive-sustainability-initiatives-in-the-organization"} className="href" target="_blank">How Can HR Drive Sustainability Initiatives in the Organization?</NavLink></span>,
        description : "Sustainability is a significant concern in our collective consciousness, especially as we look to the future and seek to avoid the effects of inaction. This issue is being discussed in many different areas, but at this point, it's more than just a discussion.",
        newPage : <span><NavLink to={"/blogs/how-can-hr-drive-sustainability-initiatives-in-the-organization"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "How Can HR Drive Sustainability Initiatives in the Organization?",
        author: "Ipsita P",
        category: "HR",
        date: <span>14<sup>th</sup> Dec 2023</span>
    },
    {
        id : 4,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_4.webp'),
        title : <span><NavLink to={"/blogs/the-gig-economy-and-its-impact-on-hr-practices"} className="href" target="_blank">The Gig Economy and Its Impact on HR Practices</NavLink></span>,
        description : "Before analyzing the implications for HRM practices, it is essential to understand what the gig economy is. In general, the gig economy is a freelance or labor market system based on part-time or temporary positions provided by independent freelancers or contractors rather than full-time permanent employees.",
        newPage : <span><NavLink to={"/blogs/the-gig-economy-and-its-impact-on-hr-practices"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "The Gig Economy and Its Impact on HR Practices",
        author: "Ipsita P",
        category: "HR",
        date: <span>02<sup>nd</sup> Jan 2024</span>
    },
    {
        id : 5,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_5.webp'),
        title : <span><NavLink to={"/blogs/business-benefits-of-content-marketing"} className="href" target="_blank">Business Benefits of Content Marketing</NavLink></span>,
        description : "With its many commercial benefits and crucial position in SEO tactics, content marketing is becoming a powerful force in the ever-changing world of digital marketing. To keep the target audience interested, content marketing is a strategic technique that entails producing and disseminating worthwhile, timely, and consistent information.",
        newPage : <span><NavLink to={"/blogs/business-benefits-of-content-marketing"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Business Benefits of Content Marketing",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>03<sup>rd</sup> Jan 2024</span>
    },
    {
        id : 6,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_6.webp'),
        title : <span><NavLink to={"/blogs/ways-to-increase-lead-conversion-through-content-marketing"} className="href" target="_blank">Ways To Increase Lead Conversion Through Content Marketing</NavLink></span>,
        description : "For companies looking to improve their online visibility and increase lead conversions, the mutually beneficial interaction between SEO and content marketing has grown more and more important in the dynamic field of digital marketing.",
        newPage : <span><NavLink to={"/blogs/ways-to-increase-lead-conversion-through-content-marketing"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Ways To Increase Lead Conversion Through Content Marketing",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>04<sup>th</sup> Jan 2024</span>
    },
    {
        id : 7,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_7.webp'),
        title : <span><NavLink to={"/blogs/what-are-backlinks-and-what-are-their-purpose-in-seo"} className="href" target="_blank">What are Backlinks and what are their purpose in SEO?</NavLink></span>,
        description : "Backlinks serve the main purpose of increasing a website's online visibility by raising its search engine rating. A website is more likely to be regarded as an authority in its subject when it gains a sizable amount of high-quality backlinks.",
        newPage : <span><NavLink to={"/blogs/what-are-backlinks-and-what-are-their-purpose-in-seo"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "What are Backlinks and what are their purpose in SEO?",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>05<sup>th</sup> Jan 2024</span>
    },
    {
        id : 8,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_8.webp'),
        title : <span><NavLink to={"/blogs/financial-growth-advancement-using-digital-marketing-in-financial-industry"} className="href" target="_blank">Financial Growth Advancement Using Digital Marketing in Financial Industry</NavLink></span>,
        description : "Digital growth within the finance industry will call for the creation of transformative processes in content marketing. Financial organizations have to manage the changes within the digital space for engaging customers and stakeholders.",
        newPage : <span><NavLink to={"/blogs/financial-growth-advancement-using-digital-marketing-in-financial-industry"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Financial growth advancement using digital marketing in financial industry",
        author: "Arihant B",
        category: "Finance",
        date: <span>06<sup>th</sup> Jan 2024</span>
    },
    {
        id : 9,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_11.webp'),
        title : <span><NavLink to={"/blogs/tips-to-boost-organic-ranking-and-traffic"} className="href" target="_blank">Tips to boost organic Ranking and Traffic</NavLink></span>,
        description : "Gaining proficiency in techniques to raise organic ranks and increase targeted traffic is essential in the ever-changing world of digital marketing. Success starts with an understanding of the nuances of user behavior and search engine algorithms.",
        newPage : <span><NavLink to={"/blogs/tips-to-boost-organic-ranking-and-traffic"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Tips to boost organic Ranking and Traffic",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>10<sup>th</sup> Jan 2024</span>
    },
    {
        id : 10,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_12.webp'),
        title : <span><NavLink to={"/blogs/benefits-of-meditation-and-mindfulness"} className="href" target="_blank">Benefits of Meditation and Mindfulness</NavLink></span>,
        description : "Meditation and mindfulness become transforming threads in the busy tapestry of contemporary life, creating a distinct story of holistic well-being. Numerous scientific studies demonstrate that these techniques have a significant impact on human health beyond just relieving stress.",
        newPage : <span><NavLink to={"/blogs/benefits-of-meditation-and-mindfulness"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Benefits of Meditation and Mindfulness",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>11<sup>th</sup> Jan 2024</span>
    },
    {
        id : 11,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_13.webp'),
        title : <span><NavLink to={"/blogs/ways-traveling-changes-your-perspectives"} className="href" target="_blank">Ways Traveling Changes Your Perspectives</NavLink></span>,
        description : "Venturing beyond familiar landscapes is a transformative odyssey that transcends the ordinary, sculpting the contours of one's worldview into a masterpiece of enlightenment. As a nomad in the gallery of existence, travel becomes the brush, stroking vibrant hues of experience onto the canvas of life.",
        newPage : <span><NavLink to={"/blogs/ways-traveling-changes-your-perspectives"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Ways Traveling Changes Your Perspectives",
        author: "Jaishree D",
        category: "Travel",
        date: <span>12<sup>th</sup> Jan 2024</span>
    },
    {
        id : 12,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_18.webp'),
        title : <span><NavLink to={"/blogs/blog-writing-and-its-advantages"} className="href" target="_blank">Blog Writing and Its Advantages</NavLink></span>,
        description : "Writing blogs is an art where words are transcended to produce a one-of-a-kind mental symphony. Dive into unknown territory and craft compelling stories. Show off your own voice and add uniqueness to every post.",
        newPage : <span><NavLink to={"/blogs/blog-writing-and-its-advantages"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Blog Writing and Its Advantages",
        author: "Jaishree D",
        category: "Content Writing",
        date: <span>20<sup>th</sup> Jan 2024</span>
    },
    {
        id : 13,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_19.webp'),
        title : <span><NavLink to={"/blogs/digital-detox-reconnecting-with-the-outside-world"} className="href" target="_blank">Digital Detox - Reconnecting with the outside world</NavLink></span>,
        description : "A digital detox is the act of choosing to put down our electronic devices for a while. These might include social media, computers, and phones. We choose to ignore the incessant buzzing and beeping of our electronics. Stated differently, the goal is to take a break from the digital world.",
        newPage : <span><NavLink to={"/blogs/digital-detox-reconnecting-with-the-outside-world"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Digital Detox - Reconnecting with the outside world",
        author: "Jaishree D",
        category: "Travel",
        date: <span>22<sup>nd</sup> Jan 2024</span>
    },
    {
        id : 14,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_20.webp'),
        title : <span><NavLink to={"/blogs/the-impact-of-ux-design-on-digital-marketing-success"} className="href" target="_blank">The Impact of UX Design on Digital Marketing Success</NavLink></span>,
        description : "In digital marketing, user experience, refers to a person's entire product-use or consumption experience. It is the attitude that a user forms towards the product and the general impression that they have of it.",
        newPage : <span><NavLink to={"/blogs/the-impact-of-ux-design-on-digital-marketing-success"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "The Impact of UX Design on Digital Marketing Success",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>23<sup>rd</sup> Jan 2024</span>
    },
    {
        id : 15,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_23.webp'),
        title : <span><NavLink to={"/blogs/importance-of-strong-brand-identity"} className="href" target="_blank">Importance of Strong Brand Identity</NavLink></span>,
        description : "Your brand's identity is essential to the success of your company. We frequently consider branding decisions to be part of your visual marketing, specifically brand identification.",
        newPage : <span><NavLink to={"/blogs/importance-of-strong-brand-identity"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Importance of Strong Brand Identity",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>29<sup>th</sup> Jan 2024</span>
    },
    {
        id : 16,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_25.webp'),
        title : <span><NavLink to={"/blogs/crafting-compelling-calls-to-action-in-digital-marketing"} className="href" target="_blank">Crafting Compelling Calls-to-Action in Digital Marketing</NavLink></span>,
        description : "The ability to direct internet customers toward your principal offer and through your sales process will determine the effectiveness of your digital marketing campaign.The main important goals are: increasing website traffic, expanding email lists, and selling legal services. To encourage users to move on to the next stage of your sales process, you need to have a call-to-action that is both compelling and well-defined.",
        newPage : <span><NavLink to={"/blogs/crafting-compelling-calls-to-action-in-digital-marketing"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Crafting Compelling Calls-to-Action in Digital Marketing",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>31<sup>st</sup> Jan 2024</span>
    },
    {
        id : 17,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_27.webp'),
        title : <span><NavLink to={"/blogs/the-significance-of-data-driven-decision-in-digital-marketing"} className="href" target="_blank">The Significance of Data-Driven Decision in Digital Marketing</NavLink></span>,
        description : "Data-driven marketing means using the data received from customer actions to gain customer motivation and preferences. You may gain a deeper understanding of your customers by collecting and evaluating data about them.",
        newPage : <span><NavLink to={"/blogs/the-significance-of-data-driven-decision-in-digital-marketing"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "The Significance of Data-Driven Decision in Digital Marketing",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>02<sup>nd</sup> Feb 2024</span>
    },
    {
        id : 18,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_28.webp'),
        title : <span><NavLink to={"/blogs/the-significance-of-building-personal-branding-in-digital-marketing"} className="href" target="_blank">The significance of building Personal Branding in Digital Marketing</NavLink></span>,
        description : "Building your reputation, image, and online visibility on purpose is known as personal branding. It helps in showing your expertise, establishing yourself as a respected figure in your field, and sharing your distinct viewpoint with the online community. Creating a personal brand fosters partnerships, boosts credibility, and opens doors for career growth.",
        newPage : <span><NavLink to={"/blogs/the-significance-of-building-personal-branding-in-digital-marketing"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "The significance of building Personal Branding in Digital Marketing",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>03<sup>rd</sup> Feb 2024</span>
    },
    {
        id : 19,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_29.webp'),
        title : <span><NavLink to={"/blogs/how-social-media-affects-seo"} className="href" target="_blank">How Social Media Affects SEO</NavLink></span>,
        description : "When ranking the content on your website, Google does not take into account social signals such as followers, engagement rates, or shares. Pages with more social media shares typically rank higher in Google searches, according to several studies. Just keep in mind that there is a correlation here, not a cause.",
        newPage : <span><NavLink to={"/blogs/how-social-media-affects-seo"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "How Social Media Affects SEO",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>05<sup>th</sup> Feb 2024</span>
    },
    {
        id : 20,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_32.webp'),
        title : <span><NavLink to={"/blogs/top-10-benefits-of-good-habits"} className="href" target="_blank">Top 10 Benefits of Good Habits</NavLink></span>,
        description : "Habits are recurring patterns of behavior that people do without realizing it or trying. Most habits are involuntary and automatic, which means they happen without conscious thinking or action.",
        newPage : <span><NavLink to={"/blogs/top-10-benefits-of-good-habits"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Top 10 Benefits of Good Habits",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>08<sup>th</sup> Feb 2024</span>
    },
    {
        id : 21,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_33.webp'),
        title : <span><NavLink to={"/blogs/role-of-sleep-fitness-and-recovery"} className="href" target="_blank">Role of Sleep Fitness and Recovery</NavLink></span>,
        description : "Despite the fact that sleep is one of the most vital components of human performance, people frequently sacrifice it for other demands. Everything, from general health and well-being to mental and physical performance, is impacted by the quantity and quality of sleep",
        newPage : <span><NavLink to={"/blogs/role-of-sleep-fitness-and-recovery"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Role of Sleep Fitness and Recovery",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>09<sup>th</sup> Feb 2024</span>
    },
    {
        id : 22,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_37.webp'),
        title : <span><NavLink to={"/blogs/strategies-for-effective-team-building-and-leadership"} className="href" target="_blank">Strategies for Effective Team Building and Leadership</NavLink></span>,
        description : "The success of your organization depends on teamwork. Effective project completion requires teamwork; no one individual can accomplish everything alone. However, when it comes to enhancing collaboration within their company, a lot of people are unsure of where to begin and what effective team-building techniques are.",
        newPage : <span><NavLink to={"/blogs/strategies-for-effective-team-building-and-leadership"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Strategies for Effective Team Building and Leadership",
        author: "Jaishree D",
        category: "Content Writing",
        date: <span>16<sup>th</sup> Feb 2024</span>
    },
    {
        id : 23,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_38.webp'),
        title : <span><NavLink to={"/blogs/tips-for-productivity-and-time-management"} className="href" target="_blank">Tips for Productivity and Time Management</NavLink></span>,
        description : "It takes clever time management techniques to juggle job, school, and personal obligations. Gaining proficiency in these areas reduces stress, fosters a sense of accomplishment, and increases efficiency.",
        newPage : <span><NavLink to={"/blogs/tips-for-productivity-and-time-management"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Tips for Productivity and Time Management",
        author: "Jaishree D",
        category: "Content Writing",
        date: <span>17<sup>th</sup> Feb 2024</span>
    },
    {
        id : 24,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_39.webp'),
        title : <span><NavLink to={"/blogs/benefits-of-reading"} className="href" target="_blank">Benefits of Reading</NavLink></span>,
        description : "The foundation of both intellectual and emotional growth is reading. It develops critical thinking abilities, broadens the vocabulary, and cultivates knowledge. Reading encourages creativity, empathy, and a profound comprehension of many viewpoints, in addition to its cognitive advantages.",
        newPage : <span><NavLink to={"/blogs/benefits-of-reading"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Benefits of Reading",
        author: "Jaishree D",
        category: "Content Writing",
        date: <span>20<sup>th</sup> Feb 2024</span>
    },
    {
        id : 25,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_40.webp'),
        title : <span><NavLink to={"/blogs/7-benefits-of-cycling"} className="href" target="_blank">7 Benefits of Cycling</NavLink></span>,
        description : "Riding a bicycle is more than simply a physical activity; it's a complete experience that improves our health in many ways. From the viewpoint of the human form, cycling has the following benefits.",
        newPage : <span><NavLink to={"/blogs/7-benefits-of-cycling"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "7 Benefits of Cycling",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>20<sup>th</sup> Feb 2024</span>
    },
    {
        id : 26,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_41.webp'),
        title : <span><NavLink to={"/blogs/benefits-of-drinking-water"} className="href" target="_blank">Benefits of Drinking Water</NavLink></span>,
        description : "Few partners are as essential and transformational as water in the complex dance of life. Water, frequently called the elixir of life, is a nourishing ingredient that supports growth and well-being and keeps life thriving.",
        newPage : <span><NavLink to={"/blogs/benefits-of-drinking-water"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Benefits of Drinking Water",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>22<sup>nd</sup> Feb 2024</span>
    },
    {
        id : 27,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_44.webp'),
        title : <span><NavLink to={"/blogs/tips-for-having-a-growth-mindset"} className="href" target="_blank">Tips for Having a Growth Mindset</NavLink></span>,
        description : "Believing in yourself is the first step towards implementing a development mindset in your classroom. Consider your own attitudes toward learning for a while. Do you shun or detest certain topics or pastimes because you're not good at them?",
        newPage : <span><NavLink to={"/blogs/tips-for-having-a-growth-mindset"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Tips for Having a Growth Mindset",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>28<sup>th</sup> Feb 2024</span>
    },
    {
        id : 28,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_45.webp'),
        title : <span><NavLink to={"/blogs/meditation-and-creativity"} className="href" target="_blank">Meditation And Creativity</NavLink></span>,
        description : "By opening up this creative space within ourselves, we can enter the flow state, which is characterised by a quiet, undistracted mind that gives us a better understanding of the feelings and ideas that lay underlying our thoughts. and this is the environment where original thought flourishes.",
        newPage : <span><NavLink to={"/blogs/meditation-and-creativity"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Meditation And Creativity",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>29<sup>th</sup> Feb 2024</span>
    },
    {
        id : 29,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_46.webp'),
        title : <span><NavLink to={"/blogs/importance-of-content-marketing"} className="href" target="_blank">Importance Of Content Marketing</NavLink></span>,
        description : "Your high-level plan for producing and disseminating content to expand your audience and accomplish different business objectives is called a content marketing strategy.",
        newPage : <span><NavLink to={"/blogs/importance-of-content-marketing"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Importance Of Content Marketing",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>01<sup>st</sup> Mar 2024</span>
    },
    {
        id : 30,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_49.webp'),
        title : <span><NavLink to={"/blogs/usage-of-seo-friendly-urls"} className="href" target="_blank">Usage of SEO-friendly URLs</NavLink></span>,
        description : "The core of your website is its URL, and you may boost website traffic by optimizing it. A URL appears more credible, performs considerably better in a Google search, and is simple for your audience to share.",
        newPage : <span><NavLink to={"/blogs/usage-of-seo-friendly-urls"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Usage of SEO-friendly URLs",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>05<sup>th</sup> Mar 2024</span>
    },
    {
        id : 31,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_52.webp'),
        title : <span><NavLink to={"/blogs/how-to-not-write-a-meta-description"} className="href" target="_blank">How To Not Write A Meta Description</NavLink></span>,
        description : "A technical SEO component that is frequently overlooked is writing a meta description. However, for the majority of website owners and marketers, writing something is usually sufficient. Keyword stuffing within the content's body no longer aids in a higher search engine ranking, and meta descriptions are no longer used as ranking signals.",
        newPage : <span><NavLink to={"/blogs/how-to-not-write-a-meta-description"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "How To Not Write A Meta Description",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>08<sup>th</sup> Mar 2024</span>
    },
    {
        id : 32,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_54.webp'),
        title : <span><NavLink to={"/blogs/how-travel-fosters-meaningful-friendships"} className="href" target="_blank">How Travel Fosters Meaningful Friendships</NavLink></span>,
        description : "Getting out of your comfort zone and experiencing new things with others is a common part of traveling. People may become closer as a result of their shared experiences and sense of camaraderie.",
        newPage : <span><NavLink to={"/blogs/how-travel-fosters-meaningful-friendships"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "How Travel Fosters Meaningful Friendships",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>13<sup>th</sup> Mar 2024</span>
    },
    {
        id : 33,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_55.webp'),
        title : <span><NavLink to={"/blogs/how-can-instagram-stories-build-an-audience"} className="href" target="_blank">How can Instagram stories build an Audience</NavLink></span>,
        description : "Were you aware that you might continue to make your stories accessible to your audience beyond the 24-hour window? All you have to do is utilize the Add to Highlights button. You will be able to view your Stories under the Highlights section of your account. ",
        newPage : <span><NavLink to={"/blogs/how-can-instagram-stories-build-an-audience"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "How can Instagram stories build an Audience",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>14<sup>th</sup> Mar 2024</span>
    },
    {
        id : 34,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_56.webp'),
        title : <span><NavLink to={"/blogs/how-does-reading-help-in-staying-calm"} className="href" target="_blank">How does reading help in staying calm </NavLink></span>,
        description : "It requires deliberate effort to make the decision to incorporate reading into your daily routine. It entails swapping out time spent alone or an early bedtime for time spent with.. The relaxing effects, however, are a huge advantage.",
        newPage : <span><NavLink to={"/blogs/how-does-reading-help-in-staying-calm"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "How does reading help in staying calm ",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>15<sup>th</sup> Mar 2024</span>
    },
    {
        id : 35,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_58.webp'),
        title : <span><NavLink to={"/blogs/importance-of-hashtags"} className="href" target="_blank">Importance Of Hashtags</NavLink></span>,
        description : "As it happens, hashtags have evolved for use in the twenty-first century. Hashtags can now be used to tag material on social media. When other users click on the hashtag, they will be able to view a selection of similar content. All you have to do is precede the words you wish to utilize with the # symbol.",
        newPage : <span><NavLink to={"/blogs/importance-of-hashtags"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Importance Of Hashtags",
        author: "Jaishree D",
        category: "Digital Marketing",
        date: <span>18<sup>th</sup> Mar 2024</span>
    },
    {
        id : 36,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_59.webp'),
        title : <span><NavLink to={"/blogs/importance-of-maintaining-a-balanced-diet"} className="href" target="_blank">Importance of Maintaining a Balanced Diet</NavLink></span>,
        description : "A balanced diet is crucial in today's fast-paced society, where convenience frequently takes precedence over nutritional content. Eating a wide variety of meals that provide vital nutrients in the right amounts is the foundation of whole well-being, having a significant impact on mental, emotional, and physical health.",
        newPage : <span><NavLink to={"/blogs/importance-of-maintaining-a-balanced-diet"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Importance of Maintaining a Balanced Diet",
        author: "Jaishree D",
        category: "Lifestyle",
        date: <span>19<sup>th</sup> Mar 2024</span>
    },
    {
        id : 37,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_100.webp'),
        title : <span><NavLink to={"/blogs/why-is-it-necessary-to-hire-an-interior-designer"} className="href" target="_blank">Why is It Necessary to Hire an Interior Designer?</NavLink></span>,
        description : "Planning to give a new look to your space? Need help with whether to hire a professional interior designer or DIY. Here, I will explain why it is necessary to hire a professional interior designer to design your space. Interior Design is a diverse field, literally it is.",
        newPage : <span><NavLink to={"/blogs/why-is-it-necessary-to-hire-an-interior-designer"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Why is It Necessary to Hire an Interior Designer?",
        author: "Renu S",
        category: "Lifestyle",
        date: <span>28<sup>th</sup> May 2024</span>
    },
    {
        id : 39,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_101.webp'),
        title : <span><NavLink to={"/blogs/online-and-offline-tools-used-by-interior-designer"} className="href" target="_blank">Online and Offline Tools Used by Interior Designer</NavLink></span>,
        description : "High competence in these areas is required of interior designers. Even if you possess an innate sense of style and a talent for beautiful design, learning how to use interior design software is vital since it will be a critical component of the company.",
        newPage : <span><NavLink to={"/blogs/online-and-offline-tools-used-by-interior-designer"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Online and Offline Tools Used by Interior Designer",
        author: "Renu S",
        category: "Lifestyle",
        date: <span>28<sup>th</sup> May 2024</span>
    },
    {
        id : 40,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_102.webp'),
        title : <span><NavLink to={"/blogs/book-review-ikigai-japanese-secret-long-happy-life"} className="href" target="_blank">Book Review - IKIGAI: The Japanese secret to a long and happy life</NavLink></span>,
        description : "The book focuses on key ideas that I found relatable in my present environment. With the help of social media, these ideas are being spread. People following it or not is secondary, but their key ideas are still valued and implied.",
        newPage : <span><NavLink to={"/blogs/book-review-ikigai-japanese-secret-long-happy-life"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Book Review - IKIGAI: The Japanese secret to a long and happy life",
        author: "Renu S",
        category: "Book Review",
        date: <span>29<sup>th</sup> May 2024</span>
    },
    {
        id : 41,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_108.webp'),
        title : <span><NavLink to={"/blogs/solo-travel-queen-womens-guide-to-independent-exploration"} className="href" target="_blank">Solo Travel Queen: Women's Guide to Independent Exploration</NavLink></span>,
        description : "Have you ever planned or thought of a solo trip? Are you among the numerous individuals who have thought about going alone but have hesitated to do so? Gone are the days when people, especially women, were hesitant to do solo travel.",
        newPage : <span><NavLink to={"/blogs/solo-travel-queen-womens-guide-to-independent-exploration"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Solo Travel Queen: Women's Guide to Independent Exploration",
        author: "Preeti Kumari",
        category: "Travel",
        date: <span>26<sup>th</sup> June 2024</span>
    },
    {
        id : 42,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_109.webp'),
        title : <span><NavLink to={"/blogs/book-review-who-moved-my-cheese-dr-spencer-jhonson"} className="href" target="_blank">Book Review - "Who Moved My Cheese?" by Dr. Spencer Johnson</NavLink></span>,
        description : "Who Moved My Cheese? It is my latest read. It's a 93-page read, the story providing a real-life perspective. The story starts at a reunion party, and to extend the friend's meeting, a dinner is planned where the whole story takes place.",
        newPage : <span><NavLink to={"/blogs/book-review-who-moved-my-cheese-dr-spencer-jhonson"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: 'Book Review - "Who Moved My Cheese?" by Dr. Spencer Johnson',
        author: "Renu S",
        category: "Book Review",
        date: <span>27<sup>th</sup> June 2024</span>
    },
    {
        id : 43,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_110.webp'),
        title : <span><NavLink to={"/blogs/is-electric-vehicle-the-future"} className="href" target="_blank">Is EV (Electric vehicle) the future?</NavLink></span>,
        description : "Looking at the present scenario, it can be said that EV is the future. Some of the common reasons to establish EVs is reducing oil consumption and minimizing pollution in nature (all sorts of pollution). We have witnessed the market for EV is rising.",
        newPage : <span><NavLink to={"/blogs/is-electric-vehicle-the-future"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Is EV (Electric vehicle) the future?",
        author: "Renu S",
        category: "Travel",
        date: <span>28<sup>th</sup> June 2024</span>
    },
    {
        id : 44,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_111.webp'),
        title : <span><NavLink to={"/blogs/mastering-macrame-knots-techniques-and-faqs"} className="href" target="_blank">Mastering Macramé: Knots, Techniques, and FAQs</NavLink></span>,
        description : "Since ancient times, macramé has gained popularity as a technique for creating textiles and other decorations that employ a variety of knots to create a simple shape. It is a quick and inexpensive method to decorate your house and add flair.",
        newPage : <span><NavLink to={"/blogs/mastering-macrame-knots-techniques-and-faqs"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Mastering Macramé: Knots, Techniques, and FAQs",
        author: "Renu S",
        category: "Lifestyle",
        date: <span>01<sup>st</sup> July 2024</span>
    },
    {
        id : 45,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_112.webp'),
        title : <span><NavLink to={"/blogs/human-resources-metrics-and-analytics"} className="href" target="_blank">Human Resources Metrics and Analytics: Measuring Success for Organizational Effectiveness</NavLink></span>,
        description : "Are you eager to take your HR strategy to the next level? Yes, then this article is for you. By utilizing HR analytics and metrics to their full potential, you can obtain priceless knowledge to revolutionize how you promote organizational performance.",
        newPage : <span><NavLink to={"/blogs/human-resources-metrics-and-analytics"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Human Resources Metrics and Analytics: Measuring Success for Organizational Effectiveness",
        author: "Preeti Kumari",
        category: "HR",
        date: <span>06<sup>th</sup> July 2024</span>
    },
    {
        id : 46,
        blogImage : require('../../Image/Blogs/Blog_Page/Blogs/blogs_banner_image/Blog_Banner_113.webp'),
        title : <span><NavLink to={"/blogs/handling-workplace-conflicts-an-hr-guide"} className="href" target="_blank">Handling workplace conflicts: An HR guide</NavLink></span>,
        description : "Have you ever been caught in a workplace drama and wondered, 'Why can't we all get along?' Well, you're not alone. Let's discuss a topic that's probably more common in your workplace than free coffee: conflicts.",
        newPage : <span><NavLink to={"/blogs/handling-workplace-conflicts-an-hr-guide"} className="href" target="_blank">Read More</NavLink></span>,
        altTag: "Handling workplace conflicts: An HR guide",
        author: "Preeti Kumari",
        category: "HR",
        date: <span>22<sup>nd</sup> July 2024</span>
    },
];