
import { BsChevronDown, BsChevronUp, BsDashCircle, BsPlusCircle } from "react-icons/bs";

const Icon = ({ color }) => {
    return <BsDashCircle fill={color} />;
};

const DownIcon = ({ color }) => {
    return <BsChevronUp fill={color} />;
}

const OnCategoriesPage = [
    {
        id: 1,
        question: "What is Your Content Revision Policy and do you charge additionaly for revisons?",
        answer: "At Be My Wordsmith, if you don't like the content we give you, just contact the person you've been talking to about the project. We'll quickly make changes accordingly as per our terms and conditions. We provide one free round of revisions with each piece of writing. But if you want changes after that, there will be a charge for the revisions.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 2,
        question: "What is the Cost of Hiring BMWS Writer For Our Content Requirements?",
        answer: "Our prices are flexible and dependent on a number of variables like the deadline, volume, service. To get a free quote, please use the Get Quote form in the Contact Us page",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 3,
        question: "What Process does Be My Wordsmith follow? ",
        answer: "Our rigorous content creation methodology places a strong emphasis on in-depth research and a comprehensive understanding of your company. We then meticulously clarify and polish the content. We consider ourselves to be your communication agency in addition to being a content writing firm.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 4,
        question: "I have a huge writing project. Before entrusting the entire task to you, I'd like to evaluate your services through a test.",
        answer: "Indeed. We would be delighted to start collaborating with you on a portion of your project. You are welcome to assign us the full job if you are satisfied with the quality.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 5,
        question: "Do You Focus on SEO Content Writing?",
        answer: "For your articles to appear on the first and top pages of Google search results, it's crucial to make them SEO-friendly. This means focusing on specific keywords to help your content rank higher on Search Engine Result Pages.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 6,
        question: "How does Fresh Content help New websites?",
        answer: "Creating current and pertinent content is crucial for connecting with your customer base. We prioritize delivering content that is not only up to date but also resonates with your audience. Additionally, this approach aids search engines in establishing the credibility of your website, influencing its placement on the Search Engine Results Page (SERP).",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
];

export default OnCategoriesPage;