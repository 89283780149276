import React from 'react'
import { IndustriesData } from '../../Data/Landing_Page/IndustriesData'
import "../CSS/LandingPage.css"

const IndustriesWeCaterTo = () => {
    return (
        <>
            <section className="sixthSectionOfLandingPageStartHere">
                <div className="container sixthSectionOfLandingPageInnerDivStartHere">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 industriesWeCaterToHeading">
                            <p>Industries</p>
                            <h6>We cater to</h6>
                        </div>
                        {
                            IndustriesData.map((industries) => (
                                <div className="col-5 col-sm-4 col-md-3 col-lg-3 industriesWeCaterToCardDiv" key={industries
                                    .id}>
                                    {industries.cardImage}
                                    <h6>{industries.title}</h6>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndustriesWeCaterTo