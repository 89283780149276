import React from 'react';
import { Helmet } from 'react-helmet';
import OurCreatorsData from '../../Data/OurCreators/OurCreatorsData';
import "../CSS/OurCreators.css";
import "../CSS/TermServicesAndPrivacyPolicy.css";

const OurCreators = () => {
    return (
        <>
            <Helmet>
                <title>Our Creators | Be My Wordsmith</title>
                <meta name="description" content="Meet talented creators at BeMyWordsmith – Crafting words that captivate and inspire. Explore our team's unique storytelling expertise." />
                <link rel="canonical" href="https://www.bemywordsmith.com/our-creators" />
            </Helmet>
            <section className="our-creators-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-flex-start">
                        <div className="col-md-12 our-creators-heading-div">
                            <p>Our</p>
                            <h6>Creators</h6>
                        </div>
                        {
                            OurCreatorsData.map((items) => {
                                return (
                                    <div className="col-md-4 col-sm-6 col-6 our-creators-image-and-para-div" key={items.id}>
                                        <div className="our-creators-image-and-para-first-inner-div">
                                            <div className="our-creators-image-div">
                                                <img src={items.creatorsImg} alt="" className='img-fluid' />
                                            </div>
                                            <div className="our-creators-icon-div">
                                                {items.creatorsIcon}
                                            </div>
                                            <div className="our-creators-heading-and-para-div">
                                                <h3>{items.creatorName}</h3>
                                                <p>{items.creator}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>
            <section className="secondSectionOfTermsOfServicePageStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 termsOfServicesEmailDiv">
                            <p className='pleaseContactPara'>If you have any queries then please contact at </p>
                            <p className='emailPara'>bemywordsmith@gmail.com</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurCreators;