import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Component/JS/Footer';
import MoveToTop from './Component/JS/MoveToTop';
import Navbar from './Component/JS/Navbar';
import ScrollToTop from './Component/JS/ScrollToTop';
import HireANInteriorDesigner100 from './Pages/Blog_Page/JS/100HireANInteriorDesigner';
import OnlineANDOfflineToolsID101 from './Pages/Blog_Page/JS/101OnlineANDOfflineToolsID';
import BookReviewIKIGAI102 from './Pages/Blog_Page/JS/102BookReviewIKIGAI';
import CWHelpsFORBrandsAndBusiness103 from './Pages/Blog_Page/JS/103CWHelpsFORBrandsAndBusiness';
import PopularContentWritingTools104 from './Pages/Blog_Page/JS/104PopularContentWritingTools';
import FindGoodQualityWebsiteCWS105 from './Pages/Blog_Page/JS/105FindGoodQualityWebsiteCWS';
import WhatAREMetaDescriptions106 from './Pages/Blog_Page/JS/106WhatAREMetaDescriptions';
import ProductDescInBLR107 from './Pages/Blog_Page/JS/107ProductDescInBLR';
import SoloTravelQueen108 from './Pages/Blog_Page/JS/108SoloTravelQueen';
import WhoMovedMyCheese109 from './Pages/Blog_Page/JS/109WhoMovedMyCheese';
import KeywordAnalysisForSEO10 from './Pages/Blog_Page/JS/10KeywordAnalysisForSEO';
import ElecticVehicleEV110 from './Pages/Blog_Page/JS/110ElecticVehicleEV';
import MasteringMacrame111 from './Pages/Blog_Page/JS/111MasteringMacrame';
import HRMetricsANDAnalytics112 from './Pages/Blog_Page/JS/112HRMetricsANDAnalytics';
import WorkplaceConflictsHRGuide113 from './Pages/Blog_Page/JS/113WorkplaceConflictsHRGuide';
import TipsToBoostOrganicRanking11 from './Pages/Blog_Page/JS/11TipsToBoostOrganicRanking';
import BenefitsOfMeditation12 from './Pages/Blog_Page/JS/12BenefitsOfMeditation';
import TravelingChangesYourPerspectives13 from './Pages/Blog_Page/JS/13TravelingChangesYourPerspectives';
import KeepingAHealthyLifestyle14 from './Pages/Blog_Page/JS/14KeepingAHealthyLifestyle';
import WriteAnArticleTips15 from './Pages/Blog_Page/JS/15WriteAnArticleTips';
import WhatIsAProductDescription16 from './Pages/Blog_Page/JS/16WhatIsAProductDescription';
import ImportanceOfWebsiteContent17 from './Pages/Blog_Page/JS/17ImportanceOfWebsiteContent';
import BlogWritingAndItsAdvantages18 from './Pages/Blog_Page/JS/18BlogWritingAndItsAdvantages';
import DigitalDetox19 from './Pages/Blog_Page/JS/19DigitalDetox';
import ImpactOfUXDesignOnDM20 from './Pages/Blog_Page/JS/20ImpactOfUXDesignOnDM';
import SocialMediaMarketingBrandGrowth21 from './Pages/Blog_Page/JS/21SocialMediaMarketingBrandGrowth';
import PowerOfVisualContent22 from './Pages/Blog_Page/JS/22PowerOfVisualContent';
import StrongBrandIdentity23 from './Pages/Blog_Page/JS/23StrongBrandIdentity';
import TipsForEssayWriting24 from './Pages/Blog_Page/JS/24TipsForEssayWriting';
import CallToActionInDigitalMarketing25 from './Pages/Blog_Page/JS/25CallToActionInDigitalMarketing';
import RoleOfEmailMarketing26 from './Pages/Blog_Page/JS/26RoleOfEmailMarketing';
import DataDrivenDecisionInDM27 from './Pages/Blog_Page/JS/27DataDrivenDecisionInDM';
import BuildingPersonalBrandingInDM28 from './Pages/Blog_Page/JS/28BuildingPersonalBrandingInDM';
import HowSocialMediaAffectsSEO29 from './Pages/Blog_Page/JS/29HowSocialMediaAffectsSEO';
import SkillsForContentWriters30 from './Pages/Blog_Page/JS/30SkillsForContentWriters';
import WhatIsUserGeneratedContent31 from './Pages/Blog_Page/JS/31WhatIsUserGeneratedContent';
import BenefitsOfGoodBenefits32 from './Pages/Blog_Page/JS/32BenefitsOfGoodBenefits';
import SleepFitnessAndRecovery33 from './Pages/Blog_Page/JS/33SleepFitnessAndRecovery';
import DataAnalyticsInEmailMarketing34 from './Pages/Blog_Page/JS/34DataAnalyticsInEmailMarketing';
import TechniquesForWriters35 from './Pages/Blog_Page/JS/35TechniquesForWriters';
import ContentMarketingStrategies36 from './Pages/Blog_Page/JS/36ContentMarketingStrategies';
import TeamBuildingAndLeadership37 from './Pages/Blog_Page/JS/37TeamBuildingAndLeadership';
import ProductivityAndTimeManagement38 from './Pages/Blog_Page/JS/38ProductivityAndTimeManagement';
import BenefitsOFReading39 from './Pages/Blog_Page/JS/39BenefitsOFReading';
import BenefitsOFCycling40 from './Pages/Blog_Page/JS/40BenefitsOFCycling';
import BenefitsOFDrinkingWater41 from './Pages/Blog_Page/JS/41BenefitsOFDrinkingWater';
import SEOINECommerceWebsite42 from './Pages/Blog_Page/JS/42SEOINECommerceWebsite';
import SEOContentOptimization43 from './Pages/Blog_Page/JS/43SEOContentOptimization';
import TipsFORGrowthMindset44 from './Pages/Blog_Page/JS/44TipsFORGrowthMindset';
import MeditationANDCreativity45 from './Pages/Blog_Page/JS/45MeditationANDCreativity';
import ImportanceOFCMarketing46 from './Pages/Blog_Page/JS/46ImportanceOFCMarketing';
import CopywritingANDContentWriting47 from './Pages/Blog_Page/JS/47CopywritingANDContentWriting';
import ImproveWritingSKILLS48 from './Pages/Blog_Page/JS/48ImproveWritingSKILLS';
import SEOFriendlyURLs49 from './Pages/Blog_Page/JS/49SEOFriendlyURLs';
import WhatISBounceRATE50 from './Pages/Blog_Page/JS/50WhatISBounceRATE';
import QuoraFORMarketers51 from './Pages/Blog_Page/JS/51QuoraFORMarketers';
import NOTWriteAMetaDescription52 from './Pages/Blog_Page/JS/52NOTWriteAMetaDescription';
import WhatISCompetitorAnalysis53 from './Pages/Blog_Page/JS/53WhatISCompetitorAnalysis';
import TravelFostersFriendships54 from './Pages/Blog_Page/JS/54TravelFostersFriendships';
import INSTAGRAMStories55 from './Pages/Blog_Page/JS/55INSTAGRAMStories';
import ReadingHelpINStayingCALM56 from './Pages/Blog_Page/JS/56ReadingHelpINStayingCALM';
import WhatISECommerce57 from './Pages/Blog_Page/JS/57WhatISECommerce';
import ImportanceOFHashTAGS58 from './Pages/Blog_Page/JS/58ImportanceOFHashTAGS';
import MaintainingABalancedDiet59 from './Pages/Blog_Page/JS/59MaintainingABalancedDiet';
import BusinessBenefitsOfCM from './Pages/Blog_Page/JS/5BusinessBenefitsOfCM';
import KeywordResearchTools60 from './Pages/Blog_Page/JS/60KeywordResearchTools';
import SEOForLocalBusiness61 from './Pages/Blog_Page/JS/61SEOForLocalBusiness';
import WebSiteUsabilityISImportant62 from './Pages/Blog_Page/JS/62WebSiteUsabilityISImportant';
import TipsToWriteSEOFriendlyContent63 from './Pages/Blog_Page/JS/63TipsToWriteSEOFriendlyContent';
import MakeClickWorthyContentWriting64 from './Pages/Blog_Page/JS/64MakeClickWorthyContentWriting';
import FeaturedSnippets65 from './Pages/Blog_Page/JS/65FeaturedSnippets';
import HireExpertContentWriters66 from './Pages/Blog_Page/JS/66HireExpertContentWriters';
import ContentWritingAgency67 from './Pages/Blog_Page/JS/67ContentWritingAgency';
import BlogPostIdeas68 from './Pages/Blog_Page/JS/68BlogPostIdeas';
import InvestingINContentWritingServices69 from './Pages/Blog_Page/JS/69InvestingINContentWritingServices';
import WaysToIncreaseLeadConversion from './Pages/Blog_Page/JS/6WaysToIncreaseLeadConversion';
import IdealBLOGPostLength70 from './Pages/Blog_Page/JS/70IdealBLOGPostLength';
import ElevateYourBrandsStory71 from './Pages/Blog_Page/JS/71ElevateYourBrandsStory';
import GoogleTrendsSEO72 from './Pages/Blog_Page/JS/72GoogleTrendsSEO';
import ProductDescriptionWriter73 from './Pages/Blog_Page/JS/73ProductDescriptionWriter';
import NeedAPrivacyPolicy74 from './Pages/Blog_Page/JS/74NeedAPrivacyPolicy';
import BadHabitsForContentWriting75 from './Pages/Blog_Page/JS/75BadHabitsForContentWriting';
import ImportanceOFWebsiteDesign76 from './Pages/Blog_Page/JS/76ImportanceOFWebsiteDesign';
import ContentMarketingTips77 from './Pages/Blog_Page/JS/77ContentMarketingTips';
import WriteContentThatGetsClicks78 from './Pages/Blog_Page/JS/78WriteContentThatGetsClicks';
import SocialMediaMarketingBusiness79 from './Pages/Blog_Page/JS/79SocialMediaMarketingBusiness';
import BacklinksAndTheirPurposeInSEO7 from './Pages/Blog_Page/JS/7BacklinksAndTheirPurposeInSEO';
import SEORankingResults80 from './Pages/Blog_Page/JS/80SEORankingResults';
import WriteContentForPeople81 from './Pages/Blog_Page/JS/81WriteContentForPeople';
import ChooseAPerfectDomain82 from './Pages/Blog_Page/JS/82ChooseAPerfectDomain';
import BenefitsOFLocalSEO83 from './Pages/Blog_Page/JS/83BenefitsOFLocalSEO';
import ChooseBestContentWritingAgency84 from './Pages/Blog_Page/JS/84ChooseBestContentWritingAgency';
import ImpQualitiesOfAContentWriter85 from './Pages/Blog_Page/JS/85ImpQualitiesOfAContentWriter';
import StrongBrandIdentityOnline86 from './Pages/Blog_Page/JS/86StrongBrandIdentityOnline';
import OptimizingYourWebsiteForMobile87 from './Pages/Blog_Page/JS/87OptimizingYourWebsiteForMobile';
import PowerOFSocialMediaOptimising88 from './Pages/Blog_Page/JS/88PowerOFSocialMediaOptimising';
import TheRoleOFSEO89 from './Pages/Blog_Page/JS/89TheRoleOFSEO';
import FinancialGrowthUsingDM8 from './Pages/Blog_Page/JS/8FinancialGrowthUsingDM';
import WritingCompellingCopyFORAds90 from './Pages/Blog_Page/JS/90WritingCompellingCopyFORAds';
import EffectiveEMAILMarketingStrategy91 from './Pages/Blog_Page/JS/91EffectiveEMAILMarketingStrategy';
import SocialMediaContentCalendar92 from './Pages/Blog_Page/JS/92SocialMediaContentCalendar';
import WebsitesConversionRate93 from './Pages/Blog_Page/JS/93WebsitesConversionRate';
import WhatISSEOWriting94 from './Pages/Blog_Page/JS/94WhatISSEOWriting';
import SEOWritingVSContentWriting95 from './Pages/Blog_Page/JS/95SEOWritingVSContentWriting';
import WriteProductDescriptionsBuy96 from './Pages/Blog_Page/JS/96WriteProductDescriptionsBuy';
import SitemapINSEO97 from './Pages/Blog_Page/JS/97SitemapINSEO';
import CrawlBudgET98 from './Pages/Blog_Page/JS/98CrawlBudgET';
import ImportanceOFKDSWhenWC99 from './Pages/Blog_Page/JS/99ImportanceOFKDSWhenWC';
import WhySEOFriendlyContentImp9 from './Pages/Blog_Page/JS/9WhySEOFriendlyContentImp';
import GaneshChaturthi from './Pages/Blog_Page/JS/GaneshChaturthi';
import GigEconomyAndHRPractices from './Pages/Blog_Page/JS/GigEconomyAndHRPractices';
import HRDriveInitiatives from './Pages/Blog_Page/JS/HRDriveInitiatives';
import InnovativeRecruitment from './Pages/Blog_Page/JS/InnovativeRecruitment';
import BookReviewIndustry from './Pages/Blog_Page/Main_Blog_Page/Industry_Blogs/BookReviewIndustry';
import ContentWritingIndustry from './Pages/Blog_Page/Main_Blog_Page/Industry_Blogs/ContentWritingIndustry';
import DigitalMarketingIndustry from './Pages/Blog_Page/Main_Blog_Page/Industry_Blogs/DigitalMarketingIndustry';
import FestivalIndustry from './Pages/Blog_Page/Main_Blog_Page/Industry_Blogs/FestivalIndustry';
import FinanceIndustry from './Pages/Blog_Page/Main_Blog_Page/Industry_Blogs/FinanceIndustry';
import HumanResourceIndustry from './Pages/Blog_Page/Main_Blog_Page/Industry_Blogs/HumanResourceIndustry';
import LifestyleIndustry from './Pages/Blog_Page/Main_Blog_Page/Industry_Blogs/LifestyleIndustry';
import TravelIndustry from './Pages/Blog_Page/Main_Blog_Page/Industry_Blogs/TravelIndustry';
import BlogsPage from './Pages/Blog_Page/Main_Blog_Page/Main_File/BlogsPage';
import MarketingBlogs from './Pages/Blog_Page/Main_Blog_Page/Main_File/MarketingBlogs';
import BlogsAndArticles from './Pages/Categories/JS/BlogsAndArticles';
import ProductDescription from './Pages/Categories/JS/ProductDescription';
import WebsiteContentWriting from './Pages/Categories/JS/WebsiteContentWriting';
import BMWLandingPage from './Pages/JS/BMWLandingPage';
import CategoriesPage from './Pages/JS/CategoriesPage';
import CompanyPage from './Pages/JS/CompanyPage';
import ContactUsPage from './Pages/JS/ContactUsPage';
import FrequentlyAskedQuestions from './Pages/JS/FrequentlyAskedQuestions';
import LandingPage from './Pages/JS/LandingPage';
import OurCreators from './Pages/JS/OurCreators';
import PageNotFound404 from './Pages/JS/PageNotFound404';
import PrivacyPolicyPage from './Pages/JS/PrivacyPolicyPage';
import Sitemap from './Pages/JS/Sitemap';
import TermsOfServicesPage from './Pages/JS/TermsOfServicesPage';
import OurCreatorIpsita from './Pages/OurCreatorsPageComp/JS/OurCreatorIpsita';

function App() {
  return (
    <>
      <Router>
        <MoveToTop />
        <ScrollToTop />
        {/* <Helmet>
          <title>Best SEO Friendly Content Writing Services Agency in India | Be My Wordsmith</title>
          <meta name="description" content="Revamp your brand with best seo friendly content writing services agency in india that facilitate expansion and resonating with your audience and positioning your brand for success." />
          <link rel="canonical" href="https://www.bemywordsmith.com/" />
        </Helmet> */}
        <Navbar />
        <div>
          <Routes>
            <Route exact path={"/"} element={<LandingPage />} />
            <Route exact path={"/content-writing-services-in-bangalore"} element={<BMWLandingPage />} />
            <Route exact path={"/categories"} element={<CategoriesPage />} />
            <Route exact path={"/about-us"} element={<CompanyPage />} />
            <Route exact path={"/blogs"} element={<BlogsPage />} />
            <Route exact path={"/articles"} element={<MarketingBlogs />} />
            <Route exact path={"/our-creators"} element={<OurCreators />} />
            {/* Our Creator Individual Page Start Here */}
            <Route exact path={"/our-creators/our-creator-ipsita"} element={<OurCreatorIpsita />} />
            {/* Our Creator Individual Page End Here */}
            <Route exact path={"/contact-us"} element={<ContactUsPage />} />

            {/* Below Navigations are for Individual Blogs */}
            <Route exact path={"/blogs/ganesh-chaturthi"} element={<GaneshChaturthi />} />
            <Route exact path={"/blogs/innovative-recruitment-strategies"} element={<InnovativeRecruitment />} />
            <Route exact path={"/blogs/how-can-hr-drive-sustainability-initiatives-in-the-organization"} element={<HRDriveInitiatives />} />
            <Route exact path={"/blogs/the-gig-economy-and-its-impact-on-hr-practices"} element={<GigEconomyAndHRPractices />} />
            <Route exact path={"/blogs/business-benefits-of-content-marketing"} element={<BusinessBenefitsOfCM />} />
            <Route exact path={"/blogs/ways-to-increase-lead-conversion-through-content-marketing"} element={<WaysToIncreaseLeadConversion />} />
            <Route exact path={"/blogs/what-are-backlinks-and-what-are-their-purpose-in-seo"} element={<BacklinksAndTheirPurposeInSEO7 />} />
            <Route exact path={"/blogs/financial-growth-advancement-using-digital-marketing-in-financial-industry"} element={<FinancialGrowthUsingDM8 />} />
            <Route exact path={"/blogs/tips-to-boost-organic-ranking-and-traffic"} element={<TipsToBoostOrganicRanking11 />} />
            <Route exact path={"/blogs/benefits-of-meditation-and-mindfulness"} element={<BenefitsOfMeditation12 />} />
            <Route exact path={"/blogs/ways-traveling-changes-your-perspectives"} element={<TravelingChangesYourPerspectives13 />} />
            <Route exact path={"/blogs/blog-writing-and-its-advantages"} element={<BlogWritingAndItsAdvantages18 />} />
            <Route exact path={"/blogs/digital-detox-reconnecting-with-the-outside-world"} element={<DigitalDetox19 />} />
            <Route exact path={"/blogs/the-impact-of-ux-design-on-digital-marketing-success"} element={<ImpactOfUXDesignOnDM20 />} />
            <Route exact path={"/blogs/importance-of-strong-brand-identity"} element={<StrongBrandIdentity23 />} />
            <Route exact path={"/blogs/crafting-compelling-calls-to-action-in-digital-marketing"} element={<CallToActionInDigitalMarketing25 />} />
            <Route exact path={"/blogs/the-significance-of-data-driven-decision-in-digital-marketing"} element={<DataDrivenDecisionInDM27 />} />
            <Route exact path={"/blogs/the-significance-of-building-personal-branding-in-digital-marketing"} element={<BuildingPersonalBrandingInDM28 />} />
            <Route exact path={"/blogs/how-social-media-affects-seo"} element={<HowSocialMediaAffectsSEO29 />} />
            <Route exact path={"/blogs/top-10-benefits-of-good-habits"} element={<BenefitsOfGoodBenefits32 />} />
            <Route exact path={"/blogs/role-of-sleep-fitness-and-recovery"} element={<SleepFitnessAndRecovery33 />} />
            <Route exact path={"/blogs/strategies-for-effective-team-building-and-leadership"} element={<TeamBuildingAndLeadership37 />} />
            <Route exact path={"/blogs/tips-for-productivity-and-time-management"} element={<ProductivityAndTimeManagement38 />} />
            <Route exact path={"/blogs/benefits-of-reading"} element={<BenefitsOFReading39 />} />
            <Route exact path={"/blogs/7-benefits-of-cycling"} element={<BenefitsOFCycling40 />} />
            <Route exact path={"/blogs/benefits-of-drinking-water"} element={<BenefitsOFDrinkingWater41 />} />
            <Route exact path={"/blogs/tips-for-having-a-growth-mindset"} element={<TipsFORGrowthMindset44 />} />
            <Route exact path={"/blogs/meditation-and-creativity"} element={<MeditationANDCreativity45 />} />
            <Route exact path={"/blogs/importance-of-content-marketing"} element={<ImportanceOFCMarketing46 />} />
            <Route exact path={"/blogs/usage-of-seo-friendly-urls"} element={<SEOFriendlyURLs49 />} />
            <Route exact path={"/blogs/how-to-not-write-a-meta-description"} element={<NOTWriteAMetaDescription52 />} />
            <Route exact path={"/blogs/how-travel-fosters-meaningful-friendships"} element={<TravelFostersFriendships54 />} />
            <Route exact path={"/blogs/how-can-instagram-stories-build-an-audience"} element={<INSTAGRAMStories55 />} />
            <Route exact path={"/blogs/how-does-reading-help-in-staying-calm"} element={<ReadingHelpINStayingCALM56 />} />
            <Route exact path={"/blogs/importance-of-hashtags"} element={<ImportanceOFHashTAGS58 />} />
            <Route exact path={"/blogs/importance-of-maintaining-a-balanced-diet"} element={<MaintainingABalancedDiet59 />} />
            <Route exact path={"/blogs/why-is-it-necessary-to-hire-an-interior-designer"} element={<HireANInteriorDesigner100 />} />
            <Route exact path={"/blogs/online-and-offline-tools-used-by-interior-designer"} element={<OnlineANDOfflineToolsID101 />} />
            <Route exact path={"/blogs/book-review-ikigai-japanese-secret-long-happy-life"} element={<BookReviewIKIGAI102 />} />
            <Route exact path={"/blogs/solo-travel-queen-womens-guide-to-independent-exploration"} element={<SoloTravelQueen108 />} />
            <Route exact path={"/blogs/book-review-who-moved-my-cheese-dr-spencer-jhonson"} element={<WhoMovedMyCheese109 />} />
            <Route exact path={"/blogs/is-electric-vehicle-the-future"} element={<ElecticVehicleEV110 />} />
            <Route exact path={"/blogs/mastering-macrame-knots-techniques-and-faqs"} element={<MasteringMacrame111 />} />
            <Route exact path={"/blogs/human-resources-metrics-and-analytics"} element={<HRMetricsANDAnalytics112 />} />
            <Route exact path={"/blogs/handling-workplace-conflicts-an-hr-guide"} element={<WorkplaceConflictsHRGuide113 />} />

            {/* Below Navigations are for Individual Marketing Articles */}
            <Route exact path={"/articles/why-is-seo-friendly-content-important"} element={<WhySEOFriendlyContentImp9 />} />
            <Route exact path={"/articles/keywords-analysis-for-seo"} element={<KeywordAnalysisForSEO10 />} />
            <Route exact path={"/articles/importance-of-keeping-a-healthy-lifestyle"} element={<KeepingAHealthyLifestyle14 />} />
            <Route exact path={"/articles/how-to-write-an-article-tips"} element={<WriteAnArticleTips15 />} />
            <Route exact path={"/articles/what-is-a-product-description-and-how-is-it-important-for-a-business"} element={<WhatIsAProductDescription16 />} />
            <Route exact path={"/articles/importance-of-website-content"} element={<ImportanceOfWebsiteContent17 />} />
            <Route exact path={"/articles/social-media-marketing-harnessing-the-power-of-platforms-for-brand-growth"} element={<SocialMediaMarketingBrandGrowth21 />} />
            <Route exact path={"/articles/the-power-of-visual-content-integrating-images-and-graphics-in-your-writing"} element={<PowerOfVisualContent22 />} />
            <Route exact path={"/articles/tips-of-essay-writing"} element={<TipsForEssayWriting24 />} />
            <Route exact path={"/articles/the-role-of-email-marketing-in-overall-digital-strategy"} element={<RoleOfEmailMarketing26 />} />
            <Route exact path={"/articles/10-essential-skills-for-content-writers"} element={<SkillsForContentWriters30 />} />
            <Route exact path={"/articles/what-is-user-generated-content"} element={<WhatIsUserGeneratedContent31 />} />
            <Route exact path={"/articles/role-of-data-analytics-in-email-marketing"} element={<DataAnalyticsInEmailMarketing34 />} />
            <Route exact path={"/articles/effective-research-techniques-for-writers"} element={<TechniquesForWriters35 />} />
            <Route exact path={"/articles/top-4-important-content-marketing-strategies"} element={<ContentMarketingStrategies36 />} />
            <Route exact path={"/articles/seo-in-e-commerce-website"} element={<SEOINECommerceWebsite42 />} />
            <Route exact path={"/articles/seo-content-optimization"} element={<SEOContentOptimization43 />} />
            <Route exact path={"/articles/difference-between-copywriting-and-content-writing"} element={<CopywritingANDContentWriting47 />} />
            <Route exact path={"/articles/7-ways-to-improve-writing-skills"} element={<ImproveWritingSKILLS48 />} />
            <Route exact path={"/articles/what-is-bounce-rate"} element={<WhatISBounceRATE50 />} />
            <Route exact path={"/articles/importance-of-quora-for-marketers"} element={<QuoraFORMarketers51 />} />
            <Route exact path={"/articles/what-is-competitor-analysis"} element={<WhatISCompetitorAnalysis53 />} />
            <Route exact path={"/articles/what-is-e-commerce-and-its-importance?"} element={<WhatISECommerce57 />} />
            <Route exact path={"/articles/10-best-keywords-research-tools-for-seo"} element={<KeywordResearchTools60 />} />
            <Route exact path={"/articles/the-dos-and-donts-of-seo-for-local-business-to-boost-rankings"} element={<SEOForLocalBusiness61 />} />
            <Route exact path={"/articles/why-web-site-usability-is-important-for-a-company"} element={<WebSiteUsabilityISImportant62 />} />
            <Route exact path={"/articles/10-tips-to-help-you-write-seo-friendly-content"} element={<TipsToWriteSEOFriendlyContent63 />} />
            <Route exact path={"/articles/how-can-i-make-click-worthy-content-writing?"} element={<MakeClickWorthyContentWriting64 />} />
            <Route exact path={"/articles/why-featured-snippets-are-important-for-seo"} element={<FeaturedSnippets65 />} />
            <Route exact path={"/articles/top-8-best-platforms-to-hire-expert-content-writers-in-2024"} element={<HireExpertContentWriters66 />} />
            <Route exact path={"/articles/top-10-tips-to-choose-the-best-content-writing-agency"} element={<ContentWritingAgency67 />} />
            <Route exact path={"/articles/how-to-come-up-with-blog-post-ideas-consistently"} element={<BlogPostIdeas68 />} />
            <Route exact path={"/articles/why-investing-in-high-quality-content-writing-services-pays-off"} element={<InvestingINContentWritingServices69 />} />
            <Route exact path={"/articles/what-is-the-ideal-blog-post-length-in-2024"} element={<IdealBLOGPostLength70 />} />
            <Route exact path={"/articles/content-writing-services-in-bangalore-be-my-wordsmith"} element={<ElevateYourBrandsStory71 />} />
            <Route exact path={"/articles/google-trends-seo-and-keyword-research"} element={<GoogleTrendsSEO72 />} />
            <Route exact path={"/articles/effective-ecommerce-product-description-writer"} element={<ProductDescriptionWriter73 />} />
            <Route exact path={"/articles/7-key-reasons-you-need-a-privacy-policy"} element={<NeedAPrivacyPolicy74 />} />
            <Route exact path={"/articles/10-bad-habits-that-affect-your-content-writing"} element={<BadHabitsForContentWriting75 />} />
            <Route exact path={"/articles/importance-of-color-selection-in-website-design"} element={<ImportanceOFWebsiteDesign76 />} />
            <Route exact path={"/articles/simple-content-marketing-tips-for-startups"} element={<ContentMarketingTips77 />} />
            <Route exact path={"/articles/how-to-write-content-that-gets-clicks"} element={<WriteContentThatGetsClicks78 />} />
            <Route exact path={"/articles/why-social-media-marketing-might-be-right-for-the-business"} element={<SocialMediaMarketingBusiness79 />} />
            <Route exact path={"/articles/5-ways-social-media-improve-your-seo-ranking-results"} element={<SEORankingResults80 />} />
            <Route exact path={"/articles/write-content-for-people-and-not-for-search-engines"} element={<WriteContentForPeople81 />} />
            <Route exact path={"/articles/choose-a-perfect-domain-for-your-business"} element={<ChooseAPerfectDomain82 />} />
            <Route exact path={"/articles/top-9-benefits-of-local-seo-for-small-businesses"} element={<BenefitsOFLocalSEO83 />} />
            <Route exact path={"/articles/why-is-it-important-to-choose-the-best-content-writing-agency"} element={<ChooseBestContentWritingAgency84 />} />
            <Route exact path={"/articles/top-7-important-qualities-of-a-content-writer"} element={<ImpQualitiesOfAContentWriter85 />} />
            <Route exact path={"/articles/strategies-for-building-a-strong-brand-identity-online"} element={<StrongBrandIdentityOnline86 />} />
            <Route exact path={"/articles/best-practices-for-optimizing-your-website-for-mobile"} element={<OptimizingYourWebsiteForMobile87 />} />
            <Route exact path={"/articles/the-power-of-social-media-advertising-for-your-business"} element={<PowerOFSocialMediaOptimising88 />} />
            <Route exact path={"/articles/the-role-of-seo-in-driving-organic-traffic"} element={<TheRoleOFSEO89 />} />
            <Route exact path={"/articles/art-of-writing-compelling-copy-for-ads-and-landing-pages"} element={<WritingCompellingCopyFORAds90 />} />
            <Route exact path={"/articles/how-to-create-an-effective-email-marketing-strategy"} element={<EffectiveEMAILMarketingStrategy91 />} />
            <Route exact path={"/articles/creating-an-effective-social-media-content-calendar"} element={<SocialMediaContentCalendar92 />} />
            <Route exact path={"/articles/effective-strategies-for-increasing-your-websites-conversion-rate"} element={<WebsitesConversionRate93 />} />
            <Route exact path={"/articles/what-is-seo-writing-10-tips-to-write-seo-friendly-content"} element={<WhatISSEOWriting94 />} />
            <Route exact path={"/articles/seo-writing-vs-content-writing-whats-the-key-difference"} element={<SEOWritingVSContentWriting95 />} />
            <Route exact path={"/articles/how-do-i-write-product-descriptions-that-will-make-people-want-to-buy"} element={<WriteProductDescriptionsBuy96 />} />
            <Route exact path={"/articles/what-is-a-sitemap-in-seo"} element={<SitemapINSEO97 />} />
            <Route exact path={"/articles/crawl-budget-does-it-affect-seo"} element={<CrawlBudgET98 />} />
            <Route exact path={"/articles/the-importance-of-keywords-when-writing-content"} element={<ImportanceOFKDSWhenWC99 />} />
            <Route exact path={"/articles/10-tips-content-writing-helps-brands-and-businesses"} element={<CWHelpsFORBrandsAndBusiness103 />} />
            <Route exact path={"/articles/13-popular-tools-for-content-writing-domain"} element={<PopularContentWritingTools104 />} />
            <Route exact path={"/articles/how-do-i-find-good-quality-website-content-writing-services"} element={<FindGoodQualityWebsiteCWS105 />} />
            <Route exact path={"/articles/how-can-you-write-an-effective-meta-description-for-a-website"} element={<WhatAREMetaDescriptions106 />} />
            <Route exact path={"/articles/product-descriptions-writers-in-bangalore"} element={<ProductDescInBLR107 />} />

            {/* Below Navigations are for Category Blogs */}
            <Route exact path={"/blogs/human-resource"} element={<HumanResourceIndustry />} />
            <Route exact path={"/blogs/digital-marketing"} element={<DigitalMarketingIndustry />} />
            <Route exact path={"/blogs/finance"} element={<FinanceIndustry />} />
            <Route exact path={"/blogs/festival"} element={<FestivalIndustry />} />
            <Route exact path={"/blogs/lifestyle"} element={<LifestyleIndustry />} />
            <Route exact path={"/blogs/content-writing"} element={<ContentWritingIndustry />} />
            <Route exact path={"/blogs/travel"} element={<TravelIndustry />} />
            <Route exact path={"/blogs/books-review"} element={<BookReviewIndustry />} />

            {/* Below navigations are in Footer Page */}
            <Route exact path={"/categories/best-website-content-writing-services-in-bangalore"} element={<WebsiteContentWriting />} />
            <Route exact path={"/categories/best-blog-&-article-writing-services-in-india"} element={<BlogsAndArticles />} />
            <Route exact path={"/categories/product-description-content-writing-services-in-india"} element={<ProductDescription />} />
            <Route exact path={"/privacy-policy"} element={<PrivacyPolicyPage />} />
            <Route exact path={"/terms-of-service"} element={<TermsOfServicesPage />} />
            <Route exact path={"/sitemap"} element={<Sitemap />} />
            <Route exact path={"/faq"} element={<FrequentlyAskedQuestions />} />
            {/* 404 Page Not Found */}
            <Route exact path={"*"} element={<PageNotFound404 />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
