
import { BsChevronDown, BsChevronUp, BsDashCircle, BsPlusCircle } from "react-icons/bs";

const Icon = ({ color }) => {
    return <BsDashCircle fill={color} />;
};

const DownIcon = ({ color }) => {
    return <BsChevronUp fill={color} />;
}

const OnProductDescriptionPage = [
    {
        id: 1,
        question: "Why Product Descriptions Are Important?",
        answer: "A product description serves to demonstrate a product's value to potential customers visiting your website. When crafted effectively, it outlines the features and benefits, influencing potential customers to make a purchase. It's important for the product description to convey how the product can address a customer's problem. Often, customers are looking for solutions when they shop, making it an ideal opportunity to emphasize why your product is the best fit for them.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 2,
        question: "Why Product description also known As SEO eCommerce Writing?",
        answer: "In e-commerce, product descriptions are vital, yet they frequently don't receive the attention they merit. It's typical to either follow supplier requirements verbatim or incorporate boring details. Still, one should steer clear of this. Although it may need more time, we advise having carefully written product descriptions. They can greatly increase your company's revenue and sales.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 3,
        question: "How long should a Product Description be?",
        answer: "In brief, aim for 300-500 words as it's the industry average. Yet, feel free to go longer if you need to provide a thorough description of your product.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 4,
        question: "Do you gather information about the Industry before creating the content?",
        answer: "Yes, we delve into industry before crafting content. Understanding your sector allows us to tailor compelling narratives that resonate, inform, and engage your audience effectively, ensuring our content aligns seamlessly with your brand objectives. ",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 5,
        question: "Can you create Product Description from scratch?",
        answer: "Of course! Our writers will carry out the required research to produce the descriptions for you if you provide us with the product name and the URL of a product page with at least one image. Along with few keywords and the target audience.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
    {
        id: 6,
        question: "How does your Product Description ensure SEO friendly Content?",
        answer: "Lastly, it's critical to incorporate pertinent keywords into your product descriptions for search engine optimization. But exercise caution so as not to abuse them. Because Google appreciates a variety of information, make sure to use these keywords in titles, photos, and product descriptions for thorough optimization.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span> 
    },
];

export default OnProductDescriptionPage;