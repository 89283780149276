import React from 'react'
import { Helmet } from 'react-helmet'
import "../../Component/CSS/FAQOnAboutUs.css"
import FAQOnAboutUs from '../../Component/JS/FAQOnAboutUs'
import MainTestimonial from '../../Component/Testimonial/MainTestimonial'
import ContactUsBtn from '../Blog_Page/Main_Blog_Page/Blog_Page_Component/ContactUsBtn'
import "../CSS/CompanyPage.css"
import SelectServicesThatYouNeed from '../Categories_Pages_Component/SelectServicesThatYouNeed'
import AboutCompanyContent from '../Company_Page_Component/AboutCompanyContent'
import ContentDeliveryProcess from '../Landing_Page_Component/ContentDeliveryProcess'
import IndustriesWeCaterTo from '../Landing_Page_Component/IndustriesWeCaterTo'

const CompanyPage = () => {
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`{
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                            "@type": "Question",
                            "name": "Will the content writers have subject matter expertise?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "We make sure to provide a writer with extensive subject-matter knowledge when you ask us to do an assignment. For instance, we choose a writer who specializes in finance if the topic is finance. For the project to succeed, this matching procedure is crucial. Our team puts a lot of effort into matching you with a writer who is qualified for the task at hand."
                            }
                        },{
                            "@type": "Question",
                            "name": "Do you at Be My Wordsmith use any content writing tools?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes. We use Grammarly, SEMRush and other premium tools."
                            }
                        },{
                            "@type": "Question",
                            "name": "How do you ensure the quality of your content?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Since content is essential to converting prospects into real clients and generating natural traffic for websites, it is recognized as the bedrock of the digital world. in BMWS, best content writing agency in Bangalore, we're experts in providing our clients with timely, high-quality content."
                            }
                        },{
                            "@type": "Question",
                            "name": "How is it different from other content writing platforms?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A group of writers, dedicated project managers, proposal pitching, and quality assurance have been assembled by us. What distinguishes us? Our dedication to providing satisfaction to our consumers and our desire for revolutionizing the content sector."
                            }
                        },{
                            "@type": "Question",
                            "name": "Why haven’t you mentioned your writing rates on the website?",
                            "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Sure, here's a simpler version: We like to give each customer a special experience at Be My Wordsmith. Because every project is different, we talk to you to understand exactly what you need. Then, instead of showing you a page with fixed prices, we create a customized price just for you."
                            }
                        }]
                        }`}
                </script>
                <title>About us | Be My Wordsmith</title>
                <meta name="description" content="Venture into the captivating journey with BeMyWordsmith, the best content writing agency in Bangalore. We construct inspiring words that resonate, creating meaningful stories just for you. Explore excellence in content creation with us." />
                <link rel="canonical" href="https://www.bemywordsmith.com/about-us" />
            </Helmet>
            <AboutCompanyContent />
            <ContentDeliveryProcess />
            <SelectServicesThatYouNeed />
            <IndustriesWeCaterTo />
            <MainTestimonial />
            <section className="faq-on-about-us-page-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 faq-comp-heading-and-para-div">
                            <h2>Frequently asked questions</h2>
                            <p>Everything you need to know about the product and billing.</p>
                        </div>
                        <div className="col-md-9 faq-comp-main-div">
                            <FAQOnAboutUs />
                        </div>
                    </div>
                </div>
            </section>
            <ContactUsBtn />
            {/* <FoundingMembersComponent /> */}
            {/* <TeamMemberComponent /> */}
        </>
    )
}

export default CompanyPage;