import React from 'react'
import brainstorming from "../../Image/Landing_Page/Content_Delivery/brainstorming.png"
import delivery from "../../Image/Landing_Page/Content_Delivery/delivery.png"
import drafting from "../../Image/Landing_Page/Content_Delivery/drafting.png"
import research from "../../Image/Landing_Page/Content_Delivery/research.png"
import "../CSS/LandingPage.css"

const ContentDeliveryProcess = () => {
    return (
        <>
            <section className="fifthSectionOfLandingPageStartHere">
                <div className="container fifthSectionOfLandingPageInnerDivStartHere">
                    <div className="row justify-content-evenly">
                        <div className="col-md-12 ourDeliveryProcessHeadingDiv">
                            <p>Our</p>
                            <h6>Content delivery process</h6>
                        </div>
                        <div className="col-sm-6 col-md-3 ourDeliveryProcessImgHeadingAndParaDiv">
                            <div className='ourDeliveryProcessImgHeadingAndParaFirstInnerDiv'>
                                <img src={brainstorming} alt="Content Writing Services" className='img-fluid' />
                                <h6>Brainstorming</h6>
                                <p>To get clarity on your requirements</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 ourDeliveryProcessImgHeadingAndParaDiv">
                            <div className='ourDeliveryProcessImgHeadingAndParaSecondInnerDiv'>
                                <img src={research} alt="Content Writing Services" className='img-fluid' />
                                <h6>Research</h6>
                                <p>To ensure authenticity</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 ourDeliveryProcessImgHeadingAndParaDiv">
                            <div className='ourDeliveryProcessImgHeadingAndParaThirdInnerDiv'>
                                <img src={drafting} alt="Content Writing Services" className='img-fluid' />
                                <h6>Drafting</h6>
                                <p>To put the ideas into words</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3 ourDeliveryProcessImgHeadingAndParaDiv">
                            <div className='ourDeliveryProcessImgHeadingAndParaFourthInnerDiv'>
                                <img src={delivery} alt="Content Writing Services" className='img-fluid' />
                                <h6>Delivery</h6>
                                <p>To ensure you get it before the deadline</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="askForMoneyForOurWork">
            <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-md-12 askForMoneyPara">
                            <p>Somewhere in the between we take $$$</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContentDeliveryProcess;