import React from 'react'
import { NavLink } from 'react-router-dom'
import "../Main_File/BlogsPage.css"

const ContactUsBtn = () => {
  return (
    <>
    <section className="fourthSectionOfBlogPageStartHere">
        <div className="container fourthSectionOfBlogPageFirstInnerDiv">
          <div className="row justify-content-center">
            <div className="col-md-12 contactUsButtonDiv">
              <h3>Try our blog writing service!!</h3>
              <p>Experience the power of our blog writing service! Our <b>professional blog writers</b> craft engaging and informative blog posts tailored to your audience, driving traffic and boosting your online presence. Try it today!</p>
              <NavLink to={"/contact-us"} className="href"><button>Contact us</button></NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUsBtn;