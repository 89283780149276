import React, { useEffect, useState } from 'react'
import "../CSS/MoveToTop.css"
import MoveToTopIcon from "../../Image/Footer/Move_To_Top_Icon.png"

const MoveToTop = () => {
    const [visible, setVisible] = useState(false);
    const moveToTopPage = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    const listenToPageScroll = () => {
        const buttonVisibleHeight = 200;
        // const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        // if (winScroll > buttonVisibleHeight) {
        //     setVisible(true);
        // } else {
        //     setVisible(false);
        // }
        if (window.pageYOffset > buttonVisibleHeight) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", listenToPageScroll);
        return () => {
            window.removeEventListener("scroll", listenToPageScroll);
        }
    })
    return (
        <>
            {
                visible && (
                    <div className="moveToTopDiv">
                        <img src={MoveToTopIcon} alt="Icon" className='img-fluid' onClick={moveToTopPage} />
                    </div>
                )
            }
        </>
    )
}

export default MoveToTop;