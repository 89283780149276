import React from 'react'
import { default as Envelope, default as Messaging } from "../../Image/Categories_Page/Envelope.svg"
import User from "../../Image/Categories_Page/User.svg"
import "../CSS/CategoriesServicesForm.css"

const CategoriesServicesForm = () => {
    return (
        <>
            <form name='services-banner-form' method="post" enctype="multipart/form-data" netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true" onSubmit="submit">
            <input type="hidden" name='form-name' value="services-banner-form" />
                <div className="mb-4 categoriesServicesFormsInnerDiv">
                    <span><img src={User} alt="User" /></span><input type="text" className="form-control" id="services-banner-name" name='services-banner-name' placeholder="Name" required />
                </div>
                <div className="mb-4 categoriesServicesFormsInnerDiv">
                    <span><img src={Envelope} alt="Envelope" /></span><input type="email" className="form-control" id="services-banner-email" name='services-banner-email' placeholder='Email' required />
                </div>
                <div className="mb-4 categoriesServicesFormsTextareaDiv">
                    <span><img src={Messaging} alt="Messaging" /></span><textarea className="form-control" id="services-banner-message" rows="3" name='services-banner-message' placeholder='Your Message'></textarea>
                </div>
                <div className="categoriesServicesFormsButtonDiv">
                    <button type="submit">Send</button>
                </div>
            </form>
        </>
    )
}

export default CategoriesServicesForm;