
import { BsChevronDown, BsChevronUp, BsDashCircle, BsPlusCircle } from "react-icons/bs";

const Icon = ({ color }) => {
    return <BsDashCircle fill={color} />;
};

const DownIcon = ({ color }) => {
    return <BsChevronUp fill={color} />;
}
const BMWLandingPageFAQData = [
    {
        id: 1,
        question: "What makes the best content writing service in Bangalore?",
        answer: <span>- Our Bangalore based<b>content writing company</b> is skilled in creating attention-grabbing, convincing content that motivates readers to take action.<br />
        - Our delivery of <b>100% original content</b> makes us proud. Every work is written by our writers from scratch, guaranteeing originality and genuineness.<br />
        - In order to ensure that the material we provide is precise, dependable, and useful, our <b>SEO writing</b> staff undertakes thorough research to obtain pertinent information and insights.<br />
        - We take great care in our grammar and proofreading, making sure that every piece of writing is polished, and of the greatest caliber.</span>,
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 2,
        question: "Is your content SEO-friendly?",
        answer: "Make sure your articles and blog are optimized for search engines (SERPs) so that they appear on the first and second pages of Google search results. In order to increase your content's visibility on Search Engine Result Pages, you should focus on specific keywords.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 3,
        question: "How is it different from other content writing platforms?",
        answer: "A group of writers, dedicated project managers, proposal pitching, and quality assurance have been assembled by us. What distinguishes us? Our dedication to providing satisfaction to our consumers and our desire for revolutionizing the content sector.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 4,
        question: "How do you ensure the quality of your content?",
        answer: "Since content is essential to converting prospects into real clients and generating natural traffic for websites, it is recognized as the bedrock of the digital world. in BMWS, best content writing agency in Bangalore, we're experts in providing our clients with timely, high-quality content.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 5,
        question: "How does your professional content writing services work?",
        answer: "In order to deliver a thorough service, our professional content creation method is structured around 5 essential components. We start with the client onboarding process and work our way through the assignment phase, content creation, careful proofreading, and timely submission. Throughout the content development process, our methodical approach meets the unique needs of our clients while ensuring a seamless and efficient workflow.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 6,
        question: "How do I place an order?",
        answer: <span>Click on the Get Quote button on top of the page. Fill in the form. Provide as much detailed information as you can so that we can give a quote instantly. All the communication after the form is filled, happens through email (<b>bemywordsmith@gmail.com</b>). Hassle free, fast and smooth!</span>,
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
    {
        id: 7,
        question: "If you want to talk to our team please feel free to contant us.",
        answer: <span>If you have any questions about this Privacy Policy, You can contact us:<br />By Email: bemywordsmith@gmail.com<br />By visiting this page on our website: www.bemywordsmith.com<br />By Phone number: +91 9748 093320</span>,
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>  
    },
];

export default BMWLandingPageFAQData;