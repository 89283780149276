
export const WhyChooseUsData = [
    {
        id : 1,
        cardImage : require('../../Image/Landing_Page/Why_Choose_Us/creative.png'),
        title : "Creative",
        description : "Creativity is intelligence having fun.",
        author: "~ Albert Einstein",
        altTag: "Creative"
    },
    {
        id : 2,
        cardImage : require('../../Image/Landing_Page/Why_Choose_Us/experience.png'),
        title : "Experience",
        description : "The only source of knowledge is experience.",
        author: "~ Albert Einstein",
        altTag: "Experience"
    },
    {
        id : 3,
        cardImage : require('../../Image/Landing_Page/Why_Choose_Us/researched.png'),
        title : "Well Researched",
        description : "Research is creating new knowledge.",
        author: "~ Neil Armstrong",
        altTag: "Well researched"
    },
    {
        id : 4,
        cardImage : require('../../Image/Landing_Page/Why_Choose_Us/ideation.png'),
        title : "Ideation",
        description : "The best way to have a good idea is to have a lot of ideas.",
        author: "~ Linus Pauling",
        altTag: "Ideation"
    },
    {
        id : 5,
        cardImage : require('../../Image/Landing_Page/Why_Choose_Us/marketing-friendly.png'),
        title : "SEO Friendly",
        description : "Content is the atomic particle of all digital marketing.",
        author: "~ Rebecca Lieb",
        altTag: "Digital Marketing Friendly"
    },
    {
        id : 6,
        cardImage : require('../../Image/Landing_Page/Why_Choose_Us/quality.png'),
        title : "Quality",
        description : "Quality is not an act, it is a habit.",
        author: "~ Aristotle",
        altTag: "Quality"
    },
    {
        id : 7,
        cardImage : require('../../Image/Landing_Page/Why_Choose_Us/original.png'),
        title : "Original",
        description : "It is better to fail in originality than to succeed in imitation.",
        author: "~ Herman Melville",
        altTag: "Original"
    },
    {
        id : 8,
        cardImage : require('../../Image/Landing_Page/Why_Choose_Us/punctual.png'),
        title : "Punctual",
        description : "Punctuality is the thief of time.",
        author: "~ Oscar Wilde",
        altTag: "Punctual"
    }
];