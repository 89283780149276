
import { BsChevronDown, BsChevronUp, BsDashCircle, BsPlusCircle } from "react-icons/bs";

const Icon = ({ color }) => {
    return <BsDashCircle fill={color} />;
};

const DownIcon = ({ color }) => {
    return <BsChevronUp fill={color} />;
}

const OnBlogsAndArticlesPage = [
    {
        id: 1,
        question: "Will Blogs and articles have SEO-optimised content and how does the keywords play a role here?",
        answer: "Absolutely! Our blogs and articles are designed to be SEO-optimized. This means we carefully incorporate specific keywords and links enhance the likelihood of higher rankings in search engine results. By doing so, we aim to boost the visibility of your content, making it easily discoverable and engaging for online users. If you have particular keywords or topics you'd like to target for SEO purposes, we recommend sharing them with us, and we'll integrate them thoughtfully into your content.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>
    },
    {
        id: 2,
        question: "Are your blog posts SEO friendly?",
        answer: "Without a doubt, providing you with SEO content that works is our primary objective. We'll ask you for the keywords you wish to target and any further SEO instructions you may have when you place your order. Our writers will take the time to comprehend the purpose of the search and the keywords in order to create a piece for you that fulfills that purpose.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>
    },
    {
        id: 3,
        question: "What are the features of a good blog post?",
        answer: "A good blog post should have a catchy title, a clear introduction, a well structured body and a strong conclusion. It should also have relevant keywords, images, links, and calls to actions. It should also be informative, engaging, original and error free. We take care of all these steps so that your blogs are loved by search engine and target readers alike. ",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>
    },
    {
        id: 4,
        question: "How does your professional content writing services work?",
        answer: "In order to deliver a thorough service, our professional content creation method is structured around 5 essential components. We start with the client onboarding process and work our way through the assignment phase, content creation, careful proofreading, and timely submission. Throughout the content development process, our methodical approach meets the unique needs of our clients while ensuring a seamless and efficient workflow.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>
    },
    {
        id: 5,
        question: "How do I know if my blogs will be completed on time?",
        answer: "We respect your time and deadlines. When you hire us to curate your material, you can be sure that your posts will be delivered on time. We prioritize your work and deliver the best content as soon as we can.",
        plusDashCircle: <span><BsPlusCircle /></span>,
        minusDashCircle: <span><Icon color="#E1A213" /></span>,
        angleUpIcon: <span><DownIcon color="#E1A213" /></span>,
        angleDownIcon: <span><BsChevronDown /></span>
    }
];

export default OnBlogsAndArticlesPage;