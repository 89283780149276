import { NavLink } from 'react-router-dom';
import HRIcon from "../../Image/Blogs/Blog_Page/Industries/HR.png";
import AgricultureIcon from "../../Image/Blogs/Blog_Page/Industries/agriculture.png";
import BusinessIcon from "../../Image/Blogs/Blog_Page/Industries/business.png";
import DigitalMarketingIcon from "../../Image/Blogs/Blog_Page/Industries/digital_marketing.png";
import EnglishIcon from "../../Image/Blogs/Blog_Page/Industries/english.png";
import FestivalIcon from "../../Image/Blogs/Blog_Page/Industries/festival.png";
import FinanceIcon from "../../Image/Blogs/Blog_Page/Industries/finance.png";
import HistoryIcon from "../../Image/Blogs/Blog_Page/Industries/history.png";
import LifestyleIcon from "../../Image/Blogs/Blog_Page/Industries/lifestyle.png";
import ArtIcon from "../../Image/Blogs/Blog_Page/Industries/literature.png";
import MoreIcon from "../../Image/Blogs/Blog_Page/Industries/more.png";
import TravelIcon from "../../Image/Blogs/Blog_Page/Industries/travel.png";

export const ChooseIndustryData = [
    {
        id : 1,
        cardImage : <NavLink to={"/blogs/content-writing"}><img src={ArtIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/content-writing"} className="href">Content Writing</NavLink></span>,
    },
    {
        id : 2,
        cardImage : <NavLink to={"/blogs/human-resource"}><img src={HRIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/human-resource"} className="href">Human Resource</NavLink></span>
    },
    {
        id : 3,
        cardImage : <NavLink to={"/blogs/digital-marketing"}><img src={DigitalMarketingIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/digital-marketing"} className="href">Digital Marketing</NavLink></span>
    },
    {
        id : 4,
        cardImage : <NavLink to={"/blogs/finance"}><img src={FinanceIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/finance"} className="href">Finance</NavLink></span>
    },
    {
        id : 5,
        cardImage : <NavLink to={"/blogs/festival"}><img src={FestivalIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/festival"} className="href">Festival</NavLink></span>
    },
    {
        id : 6,
        cardImage : <NavLink to={"/blogs/lifestyle"}><img src={LifestyleIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/lifestyle"} className="href">Lifestyle</NavLink></span>
    },
    {
        id : 7,
        cardImage : <NavLink to={"/blogs/travel"}><img src={TravelIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/travel"} className="href">Travel</NavLink></span>
    },
    {
        id : 8,
        cardImage : <NavLink to={"/blogs/books-review"}><img src={EnglishIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/blogs/books-review"} className="href">Books Review</NavLink></span>
    },
    {
        id : 9,
        cardImage : <NavLink to={"/"}><img src={HistoryIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/"} className="href">History</NavLink></span>   
    },
    {
        id : 10,
        cardImage : <NavLink to={"/"}><img src={BusinessIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/"} className="href">Business</NavLink></span>
    },
    {
        id : 11,
        cardImage : <NavLink to={"/"}><img src={AgricultureIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/"} className="href">Agriculture</NavLink></span>,
    },
    {
        id : 12,
        cardImage : <NavLink to={"/"}><img src={MoreIcon} alt="" className='img-fluid' /></NavLink>,
        title : <span><NavLink to={"/"} className="href">More</NavLink></span>
    }
];