import React from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import "../CSS/Sitemap.css"

const Sitemap = () => {
    return (
        <>
            <Helmet>
                <title>Sitemap | Be My Wordsmith</title>
                <meta name="description" content="Our process and your desired content locations throughout the website." />
                <link rel="canonical" href="https://www.bemywordsmith.com/sitemap" />
            </Helmet>
            <section className="sitemap-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-flex-start">
                        <div className="col-md-12 sitemap-main-div">
                            <ul>
                                <li><NavLink to={"/"} className="listItem">Home</NavLink></li>
                                <li><NavLink to={"/about-us"} className="listItem">About us</NavLink></li>
                                <li><NavLink to={"/categories"} className="listItem">Categories</NavLink></li>
                                <ul>
                                    <li><NavLink to={"/categories/best-website-content-writing-services-in-bangalore"} className="listItem">Website content</NavLink></li>
                                    <li><NavLink to={"/categories/best-blog-&-article-writing-services-in-india"} className="listItem">Blogs & articles</NavLink></li>
                                    <li><NavLink to={"/categories/product-description-content-writing-services-in-india"} className="listItem">Product description</NavLink></li>
                                </ul>
                                <li><NavLink to={"/blogs"} className="listItem">Blogs</NavLink></li>
                                <li><NavLink to={"/our-creators"} className="listItem">Our Creators</NavLink></li>
                                <li><NavLink to={"/contact-us"} className="listItem">Contact us</NavLink></li>
                                <li><NavLink to={"/faq"} className="listItem">FAQ</NavLink></li>
                                <li><NavLink to={"/terms-of-service"} className="listItem">Terms of Service</NavLink></li>
                                <li><NavLink to={"/privacy-policy"} className="listItem">Privacy Policy</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sitemap