import React from 'react';
import { Helmet } from 'react-helmet';
import "../CSS/TermServicesAndPrivacyPolicy.css";

const PrivacyPolicyPage = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | Be My Wordsmith</title>
                <meta name="description" content="Understand our privacy policy that outlines the types of personal information that is received and collected by BeMyWordsmith and how it is used." />
                <link rel="canonical" href="https://www.bemywordsmith.com/privacy-policy" />
            </Helmet>
            <section className="firstSectionOfTermsOfServicePageStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 termsOfServicesHeadingDiv">
                            <p>Our</p>
                            <h6>Privacy policy</h6>
                        </div>
                        <div className="col-md-12 termsOfServicesMainContentDiv">
                            <p>Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                            <h3>Purpose of the website</h3>
                            <p>Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                            <h3>Placing order and access to the website</h3>
                            <p>Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.</p>
                            <h3>Rejecting order</h3>
                            <p>Forem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="secondSectionOfTermsOfServicePageStartHere">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 termsOfServicesEmailDiv">
                            <p className='contactInformationPara'>Contact information</p>
                            <p className='pleaseContactPara'>If you have any queries then please contact at </p>
                            <p className='emailPara'>bemywordsmith@gmail.com</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicyPage;